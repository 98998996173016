
export const TARIF_FETCH = 'TARIF_FETCH';
export const TARIF_ADD_NEGO = 'TARIF_ADD_NEGO';
export const TARIF_ADD_GLOBAL = 'TARIF_ADD_GLOBAL';

export function tarifFetch(params){
    return {
        params:params,
        type:TARIF_FETCH
    };
}

export function tarifAddNego(params){
    return {
        params:params,
        type:TARIF_ADD_NEGO
    };
}

export function tarifAddGlobal(params){
    return {
        params:params,
        type:TARIF_ADD_GLOBAL
    };
}