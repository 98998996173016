import React from "react";
// reactstrap components
import { Container, Row} from "reactstrap";
// import { FormattedMessage} from 'react-intl'

import Login from "../views/Login.jsx";

const logo = require('../assets/img/brand/neolegal.png');

class AuthLayout extends React.Component {
  constructor(props) {
    super(props);

    this.state={lint:'value to please lintjs'}
  }

  componentDidMount() {
    document.body.classList.add("bg-default");
  }

  componentWillUnmount() {
    document.body.classList.remove("bg-default");
  }

  render() {
    return (
      <>
         <div className="main-content login">
            <div className="header bg-gradient-info py-5 py-lg-5">
              <Container>
                {/* <div className="top-right">
                  <Badge id="lang-switch" color="warning" onClick={(e) => this.props.changeLang(e)}>
                    <FormattedMessage id={'login.other-lang'} defaultMessage={'en'}></FormattedMessage>
                  </Badge>
                </div> */}
                <div className="header-body text-center mb-7">
                  <img src={logo} width="300px;" alt="Neolegal" className="neologo"></img>
                </div>
              </Container>
              {/* component for the svg thing?? */}
              <div className="separator separator-bottom separator-skew zindex-100">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  preserveAspectRatio="none"
                  version="1.1"
                  viewBox="0 0 2560 100"
                  x="0"
                  y="0"
                >
                  <polygon
                    className="fill-default"
                    points="2560 0 2560 100 0 100"
                  />
                </svg>
              </div>
            </div>
            <Container className="mt--8 pb-5">
              <Row className="justify-content-center">
                <Login/>
              </Row>
            </Container>
          </div>
      </>
    );
  }
}

export default AuthLayout;