import React, { Component } from "react";
import { 
    Button,
    Form,
    FormGroup, 
    Input,
    Label,
    Row,
    Col,
} from "reactstrap";

import AlertNotification from '../utils/Alert'
import Select from "react-select";

import Table from '../utils/Table'
import _ from "lodash";
import { adminAxios as axios } from "../../utils/axios";
import rootsActions from '../../_actions/root-actions'

import moment from 'moment'

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

import './datepicker.css';
import {SingleDatePicker} from 'react-dates';
import  NeoLabel  from "../utils/NeoLabel";
import { connect } from "react-redux";
const list = "list";

const elawyer = 'elawyer'
// const manage = 'manage'
const DATE_FORMAT = 'YYYY-MM-DD';
const CAD = 'CAD';
const INFO = 'info';
// const PRIMARY = 'primary';
const DANGER = 'danger';

class ByUser extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userList:[],
      user: '',
      specialities: [],
      group: 0,
      tarif: list,
      amount:'',
      globalFee:''
    };

    this.onChange = this.onChange.bind(this);

    this.handleSubmit = this.handleSubmit.bind(this);
    
    // this.lawyerCallback = this.lawyerCallback.bind(this);
  }

  onChange(e) {
    if(this.props.negotiated){
    };

    this.setState({
      user: e,
    });
  }

  handleSubmit(e) {
    let state = this.state;
   let uID = this.props["userId"];

    if(!uID){
        let token = window.parseJwt(localStorage.getItem('token'));
        uID=token.sub
    }
    axios
      .post("tarif", {
        auth_id: this.props.userId||uID,
        type_id: state.type,
        currency:CAD,
        amount:state.amount,
        user_id:state.user.value,
      })
      .then(
        (response) => {
          this.setState({
            alertVisible:true,
            alertColor:INFO,
            alertMessage:'Le tarif a été défini pour ce groupe'
          })
          
          if(response.data && response.data.data && response.data.data.tarif){

            let tarif = response.data.data.tarif;

            let user = _.find(this.props.userList,{auth_id:tarif.user_id.toString()});
            let type = _.find(this.props.types,{id:parseInt(tarif.type_id)});

            tarif = {
              ...tarif,
              type:type? type.title:'',
              user:user? user.firstname +' '+user.lastname:''
            }


            this.props.tarifAddNego(tarif)
          }
        },
        (err) => {
          
          this.setState({
            alertVisible:true,
            alertColor:DANGER,
            alertMessage:'Échec dans la procédure:'+err.message
          })
          console.log(err);
        }
      );
  }

  componentDidUpdate(prevProps){

    let obj = {};
    if(this.props.specialities&&JSON.stringify(prevProps.specialities)!==JSON.stringify(this.props.specialities)){
      obj.specialities = this.props.specialities
    }

    if(this.props.userList&&JSON.stringify(prevProps.userList)!==JSON.stringify(this.props.userList)){
      obj.userList = this.props.userList
    }

    if(this.props.types&&JSON.stringify(prevProps.types)!==JSON.stringify(this.props.types)){
      obj.types = this.props.types
      
    }

    if(_.keys(obj).length)
      this.setState(obj);
  }

  render() {
    return (
      <Form>
        <FormGroup>
          Tarifs négocié à la pièce
          <FormGroup>
            Avocat externe
            <Select
              onChange={this.onChange}
              options={
                _.map(
                  _.filter(this.state.userList,{type:elawyer}),
                   (u) => {
                      return {
                        label: u.firstname + " " + u.lastname[0],
                        value: u.auth_id,
                        name: "user",
                      };
                  }
                )
              }
              value={this.state.user}
              name="Lawyers"
              isClearable
              isSearchable
            />
          </FormGroup>
          
          <FormGroup>
            Type de rendez-vous
            <Select
              onChange={(e) => {
                let speciality = _.find(this.state.specialities, { id: e.speciality });
                
                let type = _.find(this.state.types, { id: parseInt(e.value) });
                let globalFee = _.find(this.props.global,{type_id:type.id.toString()});
                
                if(globalFee)
                  globalFee = globalFee.amount;
                else
                  globalFee = 0;

                this.setState({
                  speciality: speciality.id,
                  type: e.value,
                  globalFee:globalFee
                });
            }}
            options={
              _.map(
                _.filter(
                  this.state.types,
                  (q) => (q.specialities&&q.specialities[0]&&q.specialities[0].id)?true:false
                  ), (s) => {
                  return {
                  label: s.title,
                  value: s.id,
                  speciality: s.specialities[0].id,
                  };
              })
            }
            
            name="license"
            isClearable
            isSearchable
          />
        </FormGroup>

        <FormGroup>
          {'Tarif négocié'}
          <Input 
            name="fees"
             type={"number"}
             value={this.state.amount}
             onChange={(e) => this.setState({amount:e.target.value})}
          />
        </FormGroup>
        <FormGroup>
          {'Tarif du reste du groupe'}
          <Input name="fees" type={"number"} disabled value={this.state.globalFee} />
        </FormGroup>
            
        <FormGroup>
          <AlertNotification
            visible={this.state.alertVisible}
            color={this.state.alertColor}
            message={this.state.alertMessage}
          />
        </FormGroup>
          <Button
            type={'button'}
            color={"primary"}
            onClick={this.handleSubmit}
            size="lg"
            className={"full-width m-t-10"}
          >
            Enregistrer
          </Button>
        </FormGroup>
      </Form>
    );
  }
}

class ByGroup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userList:[],
      users: [],
      types:[],
      specialities: [],
      group: 0,
      tarif: list,
      amount:''
    };

    this.onChange = this.onChange.bind(this);

    this.handleSubmit = this.handleSubmit.bind(this);
    
    // this.lawyerCallback = this.lawyerCallback.bind(this);
  }

  onChange(e) {
    this.setState({
      users: e,
    });
  }

  handleSubmit(e) {
    let state = this.state;
    let uID = this.props["userId"];

    if(!uID){
        let token = window.parseJwt(localStorage.getItem('token'));
        uID=token.sub
    }
    axios
    .post("tarif", {
      auth_id: this.props.userId||uID,
      type_id: state.type,
      currency:CAD,
      amount:state.amount,
    })
    .then(
      (response) => {
        this.setState({
          alertVisible:true,
          alertColor:INFO,
          alertMessage:'Le tarif a été défini pour ce groupe'
        })

        if(response.data && response.data.data && response.data.data.tarif){
            
          // duration: 4
          //   id: 25
          //   limit: null
          //   specialities:   0: {id: 10}
          //   length: 1
          //   __proto__: Array(0)
          //   title: "1er RDV Rédaction contrat de services"
          //   type: 0

            let tarif = response.data.data.tarif;
            
            let currentTarif = _.find(this.props.global,{type_id:tarif.type_id.toString()});
            // debugger;
            if(currentTarif){
              console.log('ne devrait pas passer ici!!!')
            }
            else{
              let title = _.find(this.props.types,{id:parseInt(tarif.type_id)});
              
              currentTarif={
                ...tarif,
                type:title.title,
                type_id:title.id.toString()
              };
            }

            tarif = {
              ...currentTarif,
              amount:state.amount,
            }
            this.props.tarifAddGlobal(tarif)
        }
      },
      (err) => {
        
        this.setState({
          alertVisible:true,
          alertColor:DANGER,
          alertMessage:'Échec dans la procédure:'+err.message
        })
        console.log(err);
      }
    );
    }
  componentDidUpdate(prevProps){
    let obj = {};
    if(this.props.specialities&&JSON.stringify(prevProps.specialities)!==JSON.stringify(this.props.specialities)){
      obj.specialities = this.props.specialities
    }

    if(this.props.userList&&JSON.stringify(prevProps.userList)!==JSON.stringify(this.props.userList)){
      obj.userList = this.props.userList
    }

    if(this.props.types&&JSON.stringify(prevProps.types)!==JSON.stringify(this.props.types)){
      obj.types = this.props.types
    }
    if(_.keys(obj).length)
      this.setState(obj);
  }

  // lawyerCallback(response) {
  //   if (response && response.data && response.data.data[0]) {
  //     localStorage.setItem("lawyerData", JSON.stringify(response));

  //     this.setState({
  //       userList: [
  //         { auth_id: -1, firstname: "Tous", lastname: " " },
  //         ...response.data.data[0].lawyers,
  //       ],
  //       types: [...response.data.data[0].types],
  //       specialities: [...response.data.data[0].specialities],
  //       durations: [...response.data.data[0].durations],
  //     });
  //   }
  // }

  render() {
    return (
      <Form>
        <FormGroup>
        Tarifs de base pour TOUS les avocats externes
          <Select
              onChange={(e) => {
                let speciality = _.find(this.state.specialities, { id: e.speciality });

                let arr = _.filter(this.state.userList, (elt) => {
                  if(!speciality)
                    return [];
                  return speciality.users.find((e) => {
                      return e === parseInt(elt.auth_id) && elt.type === elawyer;
                  });
                });

                arr = _.map(arr, (e) => {
                  return {
                      value: e.auth_id,
                      label: e.firstname + "." + e.lastname[0],
                      name: "user",
                  };
                });

                this.setState({
                  speciality: speciality?speciality.id:-1,
                  type: e.value,
                  users: arr,
                });
            }}
            options={
              _.map(
                _.filter(
                  this.state.types,
                  (q) => (q.specialities&&q.specialities[0]&&q.specialities[0].id)?true:false
                  ), (s) => {
                  return {
                  label: s.title,
                  value: s.id,
                  speciality: s.specialities[0].id,
                  };
              })
            }
            name="license"
            isClearable
            isSearchable
          />
        </FormGroup>

        <FormGroup>
          {'Tarif à l\'acte'}
          <Input 
            name="fees" 
            type={"number"} 
            value={this.state.amount}
            onChange={(e) => this.setState({amount:e.target.value})}
          />
        </FormGroup>
        <FormGroup>
          membres
          <Select
            onChange={this.onChange}
            options={_.map(this.state.userList, (u) => {
              return {
                label: u.firstname + " " + u.lastname[0],
                value: u.auth_id,
                name: "user",
              };
            })}
            value={this.state.users}
            name="Lawyers"
            isClearable
            isMulti
            isSearchable
            isDisabled
          />
        </FormGroup>
        <FormGroup>
          <AlertNotification
            visible={this.state.alertVisible}
            color={this.state.alertColor}
            message={this.state.alertMessage}
          />
        </FormGroup>
        <FormGroup>
          <Button
            color={"primary"}
            onClick={this.handleSubmit}
            type="button"
            size="lg"
            className={"full-width m-t-10"}
          >
            Enregistrer
          </Button>
        </FormGroup>
      </Form>
    );
  }
}


// amount: "22.00"
// created: "2020-08-13 00:00:00"
// currency: "CAD"
// id: "26"
// inforced_date: "2020-08-01"
// type_id: "3"

const columns = [
  {
      Header: '#',
      accessor: 'id',
  },
  {
      Header: 'Type de RDV',
      accessor: 'type',
  },
  {
      Header: 'Tarif',
      accessor: 'amount',
  },
  // {
  //     Header: 'Prix vente',
  //     accessor: 'price',
  // }
];

const columnsNego = [
  {
      Header: '#',
      accessor: 'id',
  },
  {
      Header: 'Avocat externe',
      accessor: 'user',
  },{
    Header: 'Produit',
    accessor: 'type',
  },
  {
      Header: 'Prix négocié',
      accessor: 'amount',
  },{
    Header: 'Prix du groupe',
    accessor: 'groupAmount',
  },
  // {
  //     Header: 'édition',
  //     accessor: 'edit',
  // }
];


class FTable extends Component{
    constructor(props){
      super(props)

      this.state = {
        day:moment().startOf('month'),
        ...props
      }

      this.onChange = this.onChange.bind(this);
      this.fetch = this.fetch.bind(this);
    }

    onChange(e) {

      if(!e)
        e='';

      this.setState({
        user: e,

      },this.fetch);
    }

    componentDidUpdate(prevProps){
      let obj = {};

      if(JSON.stringify(prevProps.specialities)!==JSON.stringify(this.props.specialities)){
          obj.specialities = this.props.specialities
      }

      if(JSON.stringify(prevProps.types)!==JSON.stringify(this.props.types)){
          obj.types = [{title:'Tous',value:0,specialities:[{id:0}]},...this.props.types]
      }

      if(JSON.stringify(prevProps.durations)!==JSON.stringify(this.props.durations)){
          obj.durations = this.props.durations
      }

      if(JSON.stringify(prevProps.userList)!==JSON.stringify(this.props.userList)){
          obj.userList = [{auth_id: '',firstname:"Tous",lastname:' ',type:elawyer},...this.props.userList];
      }

      if(_.keys(obj).length)
          this.setState(obj);
    }

    edit(e){
      console.log(e)
      // this.props

    }

    fetch(){
      let state = this.state;
      this.props.toggleLoading();
      axios.get(
        '/tarifs',
        {params:{
            auth_id:localStorage.getItem('user_id'),
            user_id:state.user?state.user.value:'',
            type_id:state.type||'',
            inforced_date:this.state.inforced_date||moment().startOf('month').format(DATE_FORMAT)
        }}
    )
    .then(
        (response) =>{
            // console.log(response.data)
            if(response.data && response.data.data){

                let global = response.data.data.global;
                let typeName = '';
                let types = this.props.types;
                // console.log(_.find(this.props.types,{id:4}))
                global = _.map(global, (e) => {
                    typeName = _.find(types,{id:parseInt(e.type_id)});
                    // debugger;
                    if(typeName)
                        typeName = typeName.title;
                    else 
                        typeName = '';
                    
                    return {
                        ...e,
                        type:typeName
                    }
                });

                let username = '';
                
                let userList = this.props.userList;
                let negotiated = response.data.data.negotiated;
                // debugger;
                // debugger;
                let groupAmount = '';
                negotiated = _.map(negotiated, (e) => {
                  // console.log('---------------')
                    typeName = _.find(types,{id:parseInt(e.type_id)});
                    // debugger;
                    // console.log('typename -> ', typeName)
                    if(typeName){
                      typeName = typeName.title;
                    }
                    else{ 
                        typeName = '';
                    }

                    groupAmount = _.find(global,{type_id:e.type_id.toString()})
                    
                    if(!groupAmount)
                      groupAmount='inconnu';

                    username = _.find(userList,{auth_id:e.user_id.toString()});
                    if(username)
                        username = username.firstname + ' ' + username.lastname ;
                    else 
                        username = '';
                        
                    return {
                        ...e,
                        // amount:e.amount
                        type:typeName,
                        user:username,
                        groupAmount:groupAmount.amount||'inconnu'
                    }
                });

                // console.log(negotiated)
                // debugger;

                this.props.tarifFetch({global:global,negotiated:negotiated});
                this.props.toggleLoading(); 
            }
        },
        () =>{
                this.props.toggleLoading();
            console.error('tarif error')
        }
    )
    }

    render(){

      // console.log(_.map(
      //   _.filter(
      //     this.state.types,
      //     (q) => (q.specialities&&q.specialities[0]&&q.specialities[0].id)?true:false
      //     ), (s) => {
      //     return {
      //     label: s.title,
      //     value: s.id,
      //     speciality: s.specialities[0].id,
      //     };
      // }))

        return(
          <>
           <NeoLabel>Liste des tarifs négociés</NeoLabel>
              <Row>
                <Col md={3}>
                  <FormGroup> 
                    <Label>
                      Type de rendez-vous
                    </Label>
                    <Select
                      onChange={(e) => {
                        if(!e || e.speciality === 0){
                          return this.setState({
                            speciality: 0,
                            type: 0,
                            selectType:0
                          },this.fetch);
                        }

                        let speciality = _.find(this.state.specialities, { id: e.speciality });


                        this.setState({
                          speciality: speciality.id,
                          type: e.value,
                          selectType:e
                        },this.fetch);
                      }}
                      options={
                        _.map(
                          _.filter(
                            this.state.types,
                            (q) => (q.specialities&&q.specialities[0]&&q.specialities[0].id)?true:false
                            ), (s) => {
                            return {
                            label: s.title,
                            value: s.id,
                            speciality: s.specialities[0].id,
                            };
                        })
                      }
                      value={this.state.selectType}
                      name="license"
                      isClearable
                      isSearchable
                    />
                  </FormGroup>
                </Col>
                <Col md={3}>
                  <FormGroup>
                    <Label>
                      Avocat
                    </Label>
                    <Select
                      onChange={this.onChange}
                      options={_.map(_.filter(this.state.userList,{type:elawyer}), (u) => {
                          return {
                            label: u.firstname + " " + u.lastname[0],
                            value: u.auth_id,
                            name: "user",
                          };
                      })}
                      value={this.state.user}
                      name="Lawyers"
                      isClearable
                      isSearchable
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label>
                      Date d'application
                    </Label>
                    <SingleDatePicker
                      onDateChange={(day) => { 
                        let datestring = moment(day).format(DATE_FORMAT);
                        
                            this.setState({ 
                              inforced_date:datestring,
                              day:day
                            },this.fetch)
                        // }
                      }}
                      focused={this.state.focused} // PropTypes.bool
                      onFocusChange={({ focused }) => this.setState({ focused })} // PropTypes.func.isRequired
                      numberOfMonths={1}
                      date={this.state.day||moment()}
                      isOutsideRange={() => false}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Table 
                data={this.props.negotiated}
                  // _.filter(this.props.negotiated, (d) => {
                    // return {...d,edit:<TableIcon callback={() => this.edit(d)} />};


                  // if(this.state.user && this.state.type){
                  //   if(this.state.user === d.userId && this.state.speciality === d.typeId)
                  //     return true;
                  // }
                  // else if(this.state.user){
                  //   if(this.state.user === d.userId)
                  //     return true
                  // }
                  // else if(this.state.type){
                  //   if(this.state.type === d.typeId)
                  //     return true
                  // }
                  // else if(!this.state.user && !this.state.type){
                  //   return true
                  // }
                  // else{ 
                  //   return false
                  // }
                // })}
                columns={columnsNego}
              />
            <NeoLabel>Liste des tarifs de groupe</NeoLabel>
            <Table 
              data={this.props.global}
              columns={columns}
              />
          </>
        )
    }
} 

const mapStateToProps = (state) => ({
  userList:state.users.userList,
  specialities:state.users.specialities,
  types:state.users.types,
  durations:state.users.durations,
  global:state.tarif.global,
  negotiated:state.tarif.negotiated,
});

const mapActionsToProps = {
  tarifFetch:rootsActions.tarifActions.tarifFetch,
  toggleLoading:rootsActions.loadingActions.toggleLoading,
  tarifAddNego:rootsActions.tarifActions.tarifAddNego,
  tarifAddGlobal:rootsActions.tarifActions.tarifAddGlobal,
}
export const FeesByUserForm = connect(mapStateToProps,mapActionsToProps)(ByUser);
export const FeesByGroupForm = connect(mapStateToProps,mapActionsToProps)(ByGroup);
export const FeesTable = connect(mapStateToProps,mapActionsToProps)(FTable);