import React, { useEffect, useState} from 'react';
import {
    Alert,
    Row,
    Col,
    Card,
    CardBody,
    CardTitle
} from 'reactstrap';

import BookingClient from './BookingClient';
import { connect } from 'react-redux';
// const LAWYER = "lawyer";
// const ELAWYER = "elawyer";
// const SALES = "sales";
const PRIMARY = 'primary';
const NONE = 'none';
const BLOCK = 'block';

const logo = require('../assets/img/brand/neolegal.png');

function Take(props) {
    const [alertColor] = useState(PRIMARY);
    const [alert] = useState('');
    const [lang, setLang] = useState("fr");
    
    useEffect(() => {
        const decodedToken = window.parseJwt(localStorage.getItem('token-client'))||{scope:{}}
        const lang = decodedToken?.scope?.language ?? "fr"
        setLang(lang)
    }, [])


    const genLang = (text, language = lang) =>  {
        if (typeof text === 'string') {
            return text;
        }

        if (typeof text === 'object') {
            if (lang && text[language]) {
                return text[language];
            } else {
                console.error('lang missing');
                return ''
            }
        }

        return '';
    }

  return (
    <div className="main-content login" key="take-key" >
    <div className="header bg-gradient-info py-5 py-lg-5" style={{height:'100vh',overflow:'scroll'}}>
      {/* <Container > */}

        <div className="header-body text-center " style={{paddingBottom:'30px'}}>
            <img src={logo} width="300px;" alt="Neolegal"></img>
        </div>
        <Row>
            <Col
                lg={{ size: 4, offset: 4 }}
                md={{ size: 6, offset: 3 }}
                // sm={{ size: 12 }}
            >
                <Card>
                    <CardBody>
                        <div style={{display:!!alert?BLOCK:NONE}}>
                            <Alert color={alertColor}>
                                {alert}
                            </Alert>
                        </div>

                        <CardTitle style={{textAlign:'center'}}>
                            <h2>{genLang({fr: 'Prise de rendez-vous', en: 'Book an appointment'})}</h2>
                        </CardTitle>
                        <BookingClient lang={lang}/>
                    </CardBody>
                </Card>
            </Col>
        </Row>

      {/* </Container> */}
      </div>
      </div>
  )
}

const mapStateToProps = (state) => ({
    lang: state.users.result.language
});

export default connect(mapStateToProps)(Take);
