import React, { useState,useEffect} from 'react';

const A = ({
    base='/',
    params='',
    target='_blank',
    anchor='',
    children
}) => {
    const [url,setUrl] = useState(base+params);

    useEffect( () =>{  
        setUrl(base+params);
    },[base,params]);

    return (
       <a href={url} target={target} anchor={anchor}>
           {children}
       </a>
    )
}


export default A;