

import React from 'react';
import ValidationIcon from './ValidationIcon';

import {
    FormGroup,
    Label,
    Input
} from 'reactstrap';

import {
    none,
    block
} from '../../_const';

export default class SelectInput extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            text:'',
            display:(!this.props.mandatory)?none:block,
            mandatory:this.props.mandatory,
            extraForms:[]
        }

    }

    componentDidUpdate(prevProps){
        if(this.props.allvisible !== prevProps.allvisible && !this.state.mandatory) {
            this.setState({display:(this.props.allvisible)?none:block})
        }
    }

    render(){
        return (<FormGroup neokey={this.props.neokey}>
            <Label
                onClick={(e) => this.setState({display:(!this.state.mandatory&&this.state.display===block)?none:block})}
            >{this.props.title}<ValidationIcon valid={!!this.state.text} /></Label>
            <span style={{display:(this.state.display===none)?block:none}}>{this.state.text}</span>
            <Input
                style={{display:this.state.display}}
                onChange={(e) => 
                    this.setState({
                        mandatory:false,
                        text:e.target.value
                    },() =>{
                            this.props.self.setForm(this.props.title,this.state.text,this.props.zendesk);
                        }
                )}
                zendesk={this.props.zendesk||''}
                type={this.props.type}
                value={this.state.text}
                id={this.props.id}
                invalid={this.state.mandatory}
                >
                {this.props.list}
            </Input>
        </FormGroup>)
    }
}