import React from 'react';
import ValidationIcon from './ValidationIcon';

import InputMask from 'react-input-mask';

import {
    FormGroup,
    Label,
} from 'reactstrap';

import {
    none,
    block
} from '../../_const';

export default class PhoneInput extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            text:'',
            display:(!this.props.mandatory)?none:block,
            mandatory:this.props.mandatory
        }
    }

    componentDidUpdate(prevProps){
        if(this.props.allVisible !== prevProps.allVisible && !this.state.mandatory) {
            this.setState({display:(this.props.allVisible)?none:block})
        }
    }

    render() {
      return <FormGroup key={this.props.phoneKey}>
                <Label
                    onClick={(e) => this.setState({display:(!this.state.mandatory&&this.state.display===block)?none:block})}
                >{this.props.title}<ValidationIcon valid={!!this.state.text} /></Label>
                <span style={{display:(this.state.display===none)?block:none}}>{this.state.text}</span>
                <InputMask 
                    className={"form-control"}
                    style={{display:this.state.display}}
                    value={this.state.text}
                    id={this.props.id}
                    mask={this.props.mask||"+1(999) 999-9999"} maskChar=" " 
                    onChange={(e) => 
                        this.setState({
                            mandatory:false,
                            text:e.target.value
                        },() =>{
                                this.props.self.setForm(this.props.title,this.state.text,this.props.zendesk);
                            }
                )} />
              </FormGroup >;
    }
}
