import React from "react";
import { Route, Redirect } from "react-router-dom";
function isAuthenticated(){
  return true
    // return !!window.store.getState().users.status
}

export const ProtectedRoute = ({
  component: Component,
  ...rest
}) => {
  
  return (
    <Route
      {...rest}
      render={(props) => {
        // let props
        if (isAuthenticated()) {
          let comp = <Component {...props} /> 
          return comp;
        } else {
          return (
            <Redirect
              to={{
                pathname: "/login",
                search: window.location.search+"&return="+props.location.pathname,
                // state: { referrer: props.location.pathname }
              }}
            />
          );
        }
      }}
    />
  );
};



