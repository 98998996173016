import React, { Component } from "react";
import { 
    Button,
    Row,
    Col
} from "reactstrap";

import {
  Redirect
} from "react-router-dom";

import moment  from 'moment-timezone';

import Table from '../utils/Table';

import TableIcon from '../utils/TableIcon';
import _ from "lodash";
import {
   adminAxios as axios, 
   privateAxios
} from "../../utils/axios";

import  NeoLabel  from "../utils/NeoLabel";
import { connect } from "react-redux";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faTimesCircle, faClock, faSave } from '@fortawesome/free-solid-svg-icons';
import rootsActions from '../../_actions/root-actions'
import ExternalDashboard from "../ExternDashboard";

import AlertNotification from '../utils/Alert'

const STRING = 'string';
const OBJECT = 'object';

const Check = <FontAwesomeIcon icon={faCheckCircle} color={'green'}/>;
const Cross = <FontAwesomeIcon icon={faTimesCircle} color={'red'}/>;
const DANGER = 'danger';

const elawyer = 'elawyer'
// const manage = 'manage
const BLOCK = 'block';
const NONE = 'none';
// const CAD = 'CAD';


const YELLOW_PENDING = '#E6D200';
const GREEN_APPROVED = 'green';
const RED_DENIED = 'red';
const APPROVED = 1;
// const PENDING = 0;
const REFUSED = -1;

const columns = [
  {
      Header: {fr:'#Facture',en:'Bill#'},
      accessor: 'invoiceId',
  },
  {
      Header: {fr:'#Généré le',en:'Generated the'},
      accessor: 'date',
  },
  {
      Header: {fr:'Approuvé',en:'Approved'},
      accessor: 'approvedIcon',
  },
  // {
  //   Header: 'Complété',
  //   accessor: 'doneIcon',
  // },   
  {
    Header: {fr:'Télécharger',en:'Download'},
    accessor: 'download',
  },
  {
    Header: {fr:'Validate',en:'Edit'},
    accessor: 'edit',
  },  
];

class FactureTable extends Component{
    constructor(props){
      super(props)

      this.state = {
        ...props,
        amount:'',
        description:'',
        ticket:'',
        new:true
      }

      
      this.init = this.init.bind(this);
      this.onChange = this.onChange.bind(this);
      // this.addLign = this.addLign.bind(this);
      // this.handleSubmit = this.handleSubmit.bind(this);
      this.createInvoice = this.createInvoice.bind(this);
      this.lawyerCallback = this.lawyerCallback.bind(this);
      this.genLang = this.genLang.bind(this);
    }


    init(){
      return {
        amount:'',
        description:'',
        ticket:'',
        // speciality:0,
        // type:0
      }
    }

    onChange(e) {

      this.setState({
        user: e,
      });
    }

    // addLign(e){
    //   let state = this.state;
    //   this.props.invoiceAddManual(
    //     {
    //       id:state.ticket,
    //       description:state.description,
    //       amount:state.amount,
    //       approved:Cross,
    //       type:MANUAL
    //     },
    //   )
      
    // }

    componentDidMount() {

      let uID = this.props["userId"];

      if(!uID){
        let token = window.parseJwt(localStorage.getItem('token'));
        
        uID=token.sub;
        
      }

      this.props.toggleLoading();

      if (!localStorage.getItem("lawyerData")) {
        privateAxios
          .get("lawyers", {
            params: { auth_id: uID },
          })
          .then(this.lawyerCallback);
      } else {
        this.lawyerCallback(JSON.parse(localStorage.getItem("lawyerData")));
      }
    }

  createInvoice(){
    return this.setState({
      redirect:"/facture/new"
    });
  }
      
  genLang(text,lang){
    if(!lang)
        lang = this.props.lang;

    if(typeof text === STRING){
        return text;
    }

    if(typeof text === OBJECT){
        if(lang && text[lang]){ 
            return text[lang];
        }
        else{
            console.error('lang missing');
            return ''
        }
    }

    return '';
}

  lawyerCallback(response) {
    if(response.redirect){
      return  this.setState({redirect:response.to})  
    }

    if (response && response.data && response.data.data[0]) {
      let uID = this.props["userId"];

      if(!uID){
        let token = window.parseJwt(localStorage.getItem('token'));
        
        uID=token.sub;
        
      }

      localStorage.setItem("lawyerData", JSON.stringify(response));
      
      let res = response;


      let props = this.props;
      axios.get(
        'invoicesbyuser',{
          params:{
            auth_id:uID,
            user_id:uID,
            // start_date:"2020-01-01",
            // end_date:"2020-12-31",
            // format:'json'
          }
      })
      .then(
        (response) => {
          this.props.toggleLoading();
          if(response && response.data && response.data.data){
            
            let TableEdit = '';
            let invoiceList = _.map(response.data.data.invoice_list, (e) => {
              
              TableEdit = <TableIcon 
                callback={() => {
                  let id = "/facture/"+e.id;
                  
                  this.setState({redirect:id})
                }}
              />
              let approved;
              
              let icon = faClock;
              let color = YELLOW_PENDING;
              
              if(parseInt(e.approved)===APPROVED){
                color = GREEN_APPROVED;
                icon = faCheckCircle;
              }
              else if(parseInt(e.approved)===REFUSED){
                color = RED_DENIED;
                icon = faTimesCircle;
              }

              approved = <TableIcon 
              icon={icon}
              color={color}
              callback={() => {
                // let id = "/facture/1"
                
                // this.setState({redirect:id})
              }}
            />;
            
            let download = <TableIcon
            icon={faSave}
            color={'black'}
            callback={() => {
              
              axios
                .get('/invoices/'+e.id+'?auth_id='+this.props.userId+'&format=pdf')
                .then( (response) => {
                  if(response && response.data && response.data.data && response.data.data.pdf){
                    
                    let pdf = response.data.data.pdf
                    let  byte,blob;
                    byte = atob(pdf.replace(/^data:application\/pdf;base64,/,''));
                    let buffer = new ArrayBuffer(byte.length);
                    let view = new Uint8Array(buffer);
                    for (var i = 0; i < byte.length; i++) {
                        view[i] = byte.charCodeAt(i);
                    }
                    
                    blob = URL.createObjectURL(new Blob([view] , {type:'application/pdf'}));

                    this.setState({
                      pdf:blob
                    }, () => document.getElementById('downloadAnchor').click())
                  }
                  else {
                    this.setState({
                      alertColor:DANGER,
                      alertVisible:true,
                      alertMessage:'Votre facture ne contient aucune ligne approuvée. Le téléchargement est annulé'
                    }, () => document.getElementById('downloadAnchor').click())

                    console.error('no pdf')
                  }
                },() => {
                  console.error('erreur pdf....')
                })
            }}
          />

              let done = e.done==='1'?Check:Cross;
              return {
                invoiceId:e.invoice_number,
                approvedIcon:approved,
                doneIcon:done,
                date:e.creation_date,
                edit:TableEdit,
                download:download,
                ...e
              }
            });
            // approved: "1"
            // comment: null
            // creation_date: "2020-08-25 00:00:00"
            // done: "0"
            // id: "2"
            // invoice_date: null
            // invoice_number: "430001"
            // user_id: "43"
            props.invoiceListFetch({invoiceList});

            this.setState({
              userList: [
                { auth_id: -1, firstname: "Tous", lastname: " " },
                ...res.data.data[0].lawyers,
              ],
              types: [...res.data.data[0].types],
              specialities: [...res.data.data[0].specialities],
              durations: [...res.data.data[0].durations],
              username:response.data.data.username
              // data:[...data]
            });
          }

        },
        (error) => {
          
        this.props.toggleLoading();
        
        }
      )
    }
  }

    componentDidUpdate(prevProps){
      let obj = {};
      
      if(JSON.stringify(prevProps.specialities)!==JSON.stringify(this.props.specialities)){
          obj.specialities = this.props.specialities
      }

      if(JSON.stringify(prevProps.types)!==JSON.stringify(this.props.types)){
          obj.types = [{title:'Tous',value:0,specialities:[{id:0}]},...this.props.types]
      }

      if(JSON.stringify(prevProps.durations)!==JSON.stringify(this.props.durations)){
          obj.durations = this.props.durations
      }

      if(JSON.stringify(prevProps.userList)!==JSON.stringify(this.props.userList)){
          obj.userList = [{auth_id: -1,firstname:"Tous",lastname:' ',type:elawyer},...this.props.userList];
      }

      if(_.keys(obj).length)
          this.setState(obj);
    }

    render(){
      let state = this.state;

      if(state.redirect)
        return (<Redirect push to={{pathname:state.redirect}} />);


      let m = moment().startOf('month');
      let displayNew = !this.props.list.length||!_.find(
        this.props.list,(e) => {
            return m.isBefore(e.date)
        }
      );
      return(
        <>
          <Row>
            <Col>
              <AlertNotification
                color={state.alertColor}
                visible={state.alertVisible}
                message={state.alertMessage}
              />
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Button 
                id="PDF-preview" 
                style={{position:'absolute',color:'white',right:0,top:-5,display:(displayNew)?BLOCK:NONE}}
                type="button"
                color="info"
                onClick={this.createInvoice }
                size='md'>
                <i className="fas fa-file-invoice-dollar"></i>
              </Button>
                <NeoLabel>{this.state.username||" "}</NeoLabel>
                <Table 
                  data={this.props.list}
                  columns={_.map(columns,(e) => {
                    return {
                      ...e,
                      'Header':this.genLang(e.Header,'en')
                    }
                  })}
                />
            </Col>
          </Row>
          <a 
          id='downloadAnchor' 
          style={{display:NONE}} 
          href={this.state.blob}
           download >empty</a>
        </>
      )
    }
} 

const mapStateToProps = (state) => ({
  userList:state.users.userList,
  specialities:state.users.specialities,
  types:state.users.types,
  durations:state.users.durations,
  manual:state.invoices.manual,
  automatic:state.invoices.automatic,
  list:state.invoices.list,
  admin:state.users.result.admin,
  userId:state.users.result.userId,
  lang:state.users.result.language,
});


const mapActionsToProps = {
  toggleLoading:rootsActions.loadingActions.toggleLoading,
  invoiceListFetch:rootsActions.invoiceActions.invoiceListFetch,
  invoiceFetch:rootsActions.invoiceActions.invoiceFetch,
  invoiceAddManual:rootsActions.invoiceActions.invoiceAddManual,
}

// export default connect(mapStateToProps)(FactureTable)
export const FacturationListTable = connect(mapStateToProps,mapActionsToProps)(FactureTable);
export class FacturationList extends Component{
  
  render(){
    return(
     <ExternalDashboard>
        <FacturationListTable {...this.props}/>
      </ExternalDashboard>)
  }
}
// export const FeesByGroupForm = connect(mapStateToProps)(ByGroup);
// export const FeesTable = connect(mapStateToProps)(FTable);
