export const GEN_PDF_PREVIEW   = 'PDF:get';
export const PDF_CHANGED = 'PDF:changed';
export const ZENDESK_TICKET = 'ticket:get';
export const ZENDESK_TICKET_INIT = 'ticket:init';
export const ZENDESK_PDF_REQUEST = 'PDF:request';
export const PDF_QUEUE_PUSH = 'PDF:QUEUE_PUSH';

export function queuePDF({type,data,status}){
    
    return {
        type:PDF_QUEUE_PUSH,
        params:{
            type:type,
            ticket:data.ticket,
            data:data,
            status:status
        }
    }
}

export function requestPDF(){
    return {
        type:ZENDESK_PDF_REQUEST
    }
}

export function genPDF({params}){
    return {
        type:GEN_PDF_PREVIEW,
        params:params
    }
}

export function PDFChanged({params}){
    return {
        type:PDF_CHANGED,
        params:params
    }
}

export function setTicket({params}){
    return {
        type:ZENDESK_TICKET,
        params:params
    }
}

export function init(){
    return {
        type:ZENDESK_TICKET_INIT
    };
}