import React, {Component} from 'react';
import {
    Button,
    Form,
    FormGroup,
    Label,
    Container,
    Input,
    FormFeedback
} from 'reactstrap';
import Select from 'react-select';

import InputMask from 'react-input-mask';
import rootsActions from '../../_actions/root-actions';
import _ from 'lodash';
import {adminAxios as axios} from '../../utils/axios';
import Alert from '../utils/Alert'
import { connect } from 'react-redux';
const list = 'list';
// const manage = 'manage'

const ELAWYER = 'elawyer';
const LAWYER = 'lawyer';
const SALES = 'sales';

const BLOCK = 'block';
const NONE = 'none';
const PRIMARY = 'primary';
const DANGER = 'danger';
const SUCCESS = 'La liste a été modifié avec succès';
const ERROR = 'Une erreur est survenue, la liste n\'a pas été mise à jour';
const ERRORCANTEDIT = 'La spécialité «autre» cible tout le monde; impossible de la sélectionner';

class AddUser extends Component{
    constructor(props){
        super(props);

        this.state = {
            alert:false,
            alertColor:PRIMARY,
            alertMessage:'',
            user:'',
            specialities:[],
            group:0,
            tarif:list,
            password:'',
            deactivate:'false',
            lang:'fr',
            zendesk:'',
            province:''
        }

        this.onChange = this.onChange.bind(this);
        
        this.handleSubmit = this.handleSubmit.bind(this);
        
    }

    onChange(e){
        this.setState({
            users:e
        })
    }

    componentDidUpdate(prevProps){
        let obj = {};

        if(JSON.stringify(prevProps.specialities)!==JSON.stringify(this.props.specialities)){
            obj.specialities = this.props.specialities
        }

        if(JSON.stringify(prevProps.types)!==JSON.stringify(this.props.types)){
            obj.types = this.props.types
        }

        if(JSON.stringify(prevProps.userList)!==JSON.stringify(this.props.userList)){
            obj.userList = [{auth_id: -1,firstname:"Tous",lastname:' '},...this.props.userList];
        }

        if(_.keys(obj).length)
            this.setState(obj);
    }
    
    handleSubmit(e){

        if(this.state.password&&this.state.password.length<6){
            return false;
        }
        let state = this.state;
        
        this.props.toggleLoading();

        let uID;

        if(!uID){
          let token = window.parseJwt(localStorage.getItem('token'));
          
          uID=token.sub  
        }    

        let cp = this.state.cp.toUpperCase();
        if(!cp.match(/^[ABCEGHJKLMNPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ ]?\d[ABCEGHJ-NPRSTV-Z]\d/) && this.state.cp)
            return this.setState({                        
                alert:true,
                alertColor:DANGER,
                alertMessage:'code postal invalide (format H0H 0H0)'
            })

        axios
        .post("/users/user", {
            auth_id:uID,
            user_id:this.state.user,
            firstname:this.state.firstname,
            lastname:this.state.lastname,
            cell:this.state.cell,
            email:this.state.email,
            password:this.state.password,
            type:this.state.role,
            language:this.state.lang,
            active:this.state.deactivate==='true'?0:1,
            id_zendesk:this.state.zendesk,
            email:this.state.email,
            address:this.state.address,
            city:this.state.city,
            cp:this.state.cp,
            province:this.state.province,
        })
        .then(
            (status) =>{
                if( status.status === 200){
                    

                    // let index = _.findIndex(this.props.specialities,{id:state.speciality});
                    // let arr = [...this.props.specialities];

                    // arr[index].users = _.map(state.users,(u) => parseInt(u.value));

                    // this.props.init({
                    //     params:{
                    //         specialities:arr
                    //     }
                    // })


                    this.setState({
                        alert:true,
                        alertColor:PRIMARY,
                        alertMessage:SUCCESS,
                        user:undefined,
                        firstname:'',
                        lastname:'',
                        lang:'fr',
                        cell:'',
                        email:'',
                        password:'',
                        role:1,
                        deactivated:'false',
                        zendesk:'',
                        email:'',
                        address:'',
                        province:'',
                        city:'',
                        cp:'',
                    })
                }
                else{
                    
                    this.setState({
                        alert:true,
                        alertColor:DANGER,
                        alertMessage:ERROR,
                    },() => console.log(ERROR,this.state))
                }
                this.props.toggleLoading()
                // if(status.statusCode===200){
                // }
            },
            (err) =>{
                this.props.toggleLoading()
                console.log('err',err);
                this.setState({
                    alert:true,
                    alertColor:DANGER,
                    alertMessage:ERROR,
                },() => console.log(ERROR,this.state))
            },
        )
    }

    // componentDidMount(){

    //     if(!localStorage.getItem('lawyerData')){ 
    //         axios.get(
    //             'lawyers',
    //             {params:{auth_id:localStorage.getItem('user_id')}}
    //         )
    //         .then(this.lawyerCallback);
    //     }
    //     else{
    //         this.lawyerCallback(JSON.parse(localStorage.getItem('lawyerData')))
    //     }
    // }

    // lawyerCallback(response){
    //     if( response && response.data && response.data.data[0]){

    //         localStorage.setItem('lawyerData',JSON.stringify(response));
            
    //         this.setState({
    //             userList:[{auth_id: -1,firstname:"Tous",lastname:' '},...response.data.data[0].lawyers],
    //             types:[...response.data.data[0].types],
    //             specialities:[...response.data.data[0].specialities],
    //             durations:[...response.data.data[0].durations]
    //         });
    //     }
    // }
    
    render(){
        
        return (

            <Container >
            <div style={{display:!!this.state.alert?BLOCK:NONE}}>
                <Alert 
                    visible={this.state.alert}
                    message={this.state.alertMessage||''}
                    color={this.state.alertColor}
                />
            </div>
            <Form>
                {/* <div style={{'display':this.state.user?'block':'none'}}> */}
                    <FormGroup>
                        <Label for="firstname">Prénom</Label>
                        <Input type="text" value={this.state.firstname} name="firstname" id="firstname"  onChange={ (e) => this.setState({firstname:e.target.value})} />
                    </FormGroup>
                    <FormGroup>
                        <Label for="lastname">Nom</Label>
                        <Input type="text" value={this.state.lastname} name="lastname" id="lastname" onChange={ (e) => this.setState({lastname:e.target.value})}  />
                    </FormGroup>
                    <FormGroup>
                        <Label for="lang">Langue</Label>
                        <Input type="select" 
                            name="lang"
                            id="lang" 
                            value={this.state.lang} 
                            onChange={ (e) => this.setState({lang:e.target.value}) } >
                            <option value={'fr'} >français</option>
                            <option value={'en'} >anglais</option>
                        </Input>
                    </FormGroup>
                    <FormGroup>
                        <Label>Téléphone</Label>
                        <InputMask 
                            className={"form-control"}
                            value={this.state.cell}
                            id="cell"
                            mask={"(999) 999-9999"} 
                            maskChar=" " 
                            onChange={(e) => this.setState({cell:e.target.value})} 
                        />
                    </FormGroup>

                    <FormGroup>
                        <Label for="email">Email</Label>
                        <Input autoComplete="new-username" type="email" value={this.state.email} name="email" id="email" onChange={ (e) => this.setState({email:e.target.value})}  />
                    </FormGroup>
                    
                    <FormGroup>
                        <Label for="address">Adresse</Label>
                        <Input autoComplete="new-username" type="address" value={this.state.address} name="address" id="address" onChange={ (e) => this.setState({address:e.target.value})}  />
                    </FormGroup>
                    
                    <FormGroup>
                        <Label for="city">Ville</Label>
                        <Input autoComplete="new-username" type="city" value={this.state.city} name="city" id="city" onChange={ (e) => this.setState({city:e.target.value})}  />
                    </FormGroup>
                    
                    <FormGroup>
                        <Label for="cp">code postal</Label>
                        <InputMask 
                            className={"form-control"}
                            value={this.state.cp}
                            id="cell"
                            mask={"a9a 9a9"} 
                            maskChar=" " 
                            onChange={(e) => this.setState({cp:e.target.value})} 
                        />
                    </FormGroup>
                    
                    <FormGroup>
                        <Label for="province">Province</Label>
                        <Input
                            type="select"
                            value={this.state.province}
                            name="province"
                            id="province"
                            onChange={ (e) => this.setState({province:e.target.value})}>
                                <option value="QC" default>Québec</option>
                                <option value="ON" >Ontario</option>
                            </Input>
                    </FormGroup>

                    <FormGroup>
                        <Label for="zendesk">id zendesk </Label>
                        <Input autoComplete="new-username" type="number" value={this.state.zendesk} name="zendesk" id="zendesk" onChange={ (e) => this.setState({zendesk:e.target.value})}  />
                    </FormGroup>
                    <FormGroup>
                        <Label for="password">Mot de passe (si vide, ce sera celui par défaut)6 caractères minimum</Label>
                        <Input 
                            autoComplete="new-password"
                            type="password"
                            value={this.state.password}
                            name="password"
                            id="password"
                            onChange={ (e) => this.setState({password:e.target.value})} 
                            valid={this.state.password.length>=6}
                            invalid={this.state.password.length<6}
                            placeholder={"ne pas me modifier si on a pas besoin de changer de mdp :)"}
                        />
                        {this.state.password.length<6?<span style={{color:"red"}}>Le mot de passe ne contient pas assez de caractères. Il en faut minimum 6!!</span>:''}
                    </FormGroup>
                    <FormGroup>
                        <Label for="type">Rôle</Label>
                        <Input type="select" name="type" id="type" value={this.state.role} onChange={ (e) => this.setState({role:e.target.value}) } >
                            <option value={LAWYER} >avocat</option>
                            <option value={ELAWYER} >avocat externe</option>
                            <option value={SALES} >ventes</option>
                        </Input>
                    </FormGroup>
                    <FormGroup check>
                    
                        <Input
                            id="deactivate"
                            name="deactivate"
                            value={this.state.deactivate==='true'} 
                            type={'checkbox'}
                            onChange={(e) => this.setState({deactivate:e.currentTarget.checked.toString()})} 
                        />
                        
                        <Label for="deactivate">
                            Désactiver cet utilisateur (réversible dans le BE)
                        </Label>

                    </FormGroup>
                    <FormGroup>
                        <Button 
                            className={'full-width m-t-10'}
                            size="lg"
                            color={"primary"} 
                            type={"button"} 
                            onClick={ 
                                this.handleSubmit
                            }>Modifier</Button>
                    </FormGroup>
                {/* </div> */}
            </Form>
          </Container>)
    }
}


const mapActionsToProps = {
    setLawyers: rootsActions.usersActions.setLawyers,
    init:rootsActions.usersActions.initUsersData,
    toggleLoading:rootsActions.loadingActions.toggleLoading,
}

const mapStateToProps = state => ({
    userList:state.users.userList,
    specialities:state.users.specialities,
    types:state.users.types
});

export default connect(mapStateToProps,mapActionsToProps)(AddUser)