import * as usersActions from './users-actions';
import * as clientActions from './client-actions';
import * as timeActions from './time-actions';
import * as loadingActions from './loading-actions';
import * as tarifActions from './tarif-actions';
import * as invoiceActions from './invoice-actions';

import * as formsActions from './forms-actions';

export default {
    invoiceActions,
    formsActions,
    tarifActions, 
    timeActions,
    usersActions,
    clientActions,
    loadingActions
}