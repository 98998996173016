import React from 'react';
import {Facturation} from './adminDashboard/Facturation';

import ExternalDashboard from "./ExternDashboard";

function ExternalFacuration() {

  return (
    <ExternalDashboard>
        <Facturation />
    </ExternalDashboard>
    )
}

export default ExternalFacuration;