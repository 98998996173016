function convertArgs(args){
    let a = Array.prototype.slice.call(args);
    if(a.length === 1)
        return a[0];
    
    return a;
        
}
function log(){

    if(!canLog())
        return;

    let a = convertArgs(arguments)

    console.log(a)

    return a;
}

function arrow(){

    if(!canLog())
        return;

    let a = convertArgs(arguments)
    console.log('--------->',a)

    return a;
}

function frontier(){
    if(!canLog())
        return;

    let a = convertArgs(arguments)
    console.log('/*/*/*/*/*/*/*/*Début/*/*/*/*/*/*/*/*/*/*/*')
    console.log(a)
    console.log('/*/*/*/*/*/*/*/*fin/*/*/*/*/*/*/*/*/*/*/*/*')

    return a;
}

function error({...obj}){
    if(!canLog())
        return;
    
    let a = convertArgs(arguments)
    console.error(a)

    return a;
}

function canLog(){
    return (process.env.REACT_APP_ALLOW_LOG&&process.env.REACT_APP_ALLOW_LOG!=='false')
}
const c = {
    log:log,
    arrow:arrow,
    frontier:frontier,
    error:error,
}

export default c;