import React, { Component } from "react";

import { Link } from "react-router-dom";
import chroma from "chroma-js";
import Calendar from "@toast-ui/react-calendar";
import "tui-calendar/dist/tui-calendar.css";

// If you use the default popups, use this.
// import 'tui-date-picker/dist/tui-date-picker.css';
// import 'tui-time-picker/dist/tui-time-picker.css';
import LeaveModal from "./LeaveModal";

import { connect } from "react-redux";

import rootsActions from "../_actions/root-actions";

import _ from "lodash";
import { ButtonGroup, Button } from "reactstrap";

import { privateAxios as axios } from "../utils/axios";
import Axios from 'axios';
import moment from "moment-timezone";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Redirect } from 'react-router-dom';
import { faCalendar, faMoneyBill, faCogs } from "@fortawesome/free-solid-svg-icons";

// const CancelToken = Axios.CancelToken;
// const source = CancelToken.source();

const ELAWYER = 'elawyer';
const LAWYER = 'lawyer';

const dot = (color = "#ccc") => ({
  alignItems: "center",
  display: "flex",
  paddingLeft: "5px",
  ":before": {
    backgroundColor: color,
    borderRadius: 10,
    content: '" "',
    display: "block",
    marginRight: 8,
    height: 10,
    width: 10
  }
});

const colourStyles = {
  control: styles => ({ ...styles, backgroundColor: "white" }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected
          ? data.color
          : isFocused
            ? color.alpha(0.5).css()
            : null,
      color: isDisabled
        ? "#ccc"
        : isSelected
          ? chroma.contrast(color, "white") > 2
            ? "white"
            : "black"
          : "#333",
      cursor: isDisabled ? "not-allowed" : "default",

      ":active": {
        ...styles[":active"],
        backgroundColor:
          !isDisabled && (isSelected ? data.color : color.alpha(0.8).css())
      }
    };
  },
  multiValue: (styles, { data }) => {
    //   const color = chroma(data.color);
    return {
      ...styles,
      ...dot(data.color),
      color: "#333 !important"
      // backgroundColor: color.alpha(0.4).css(),
    };
  },
  multiValueLabel: (styles, { data }) => {
    // const color = chroma(data.color);
    return {
      ...styles,
      color: "#333 !important",
      // backgroundColor: color.alpha(0.1).css(),
      paddingLeft: "5px"
    };
  },
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: data.color,
    ":hover": {
      backgroundColor: data.color,
      color: "white"
    }
  })
};

const START_DATE = "startDate";
const END_DATE = "endDate";

class Schedule extends Component {
  constructor(props) {
    super(props);

    let ok = this.defaultVisible();
    // console.log(ok)
    this.state = {
      visible: ok,
      focusedInput: props.autoFocusEndDate ? END_DATE : START_DATE,
      calendarUsers: [],
      modal: false,
      startDate: new Date(),
      endDate: new Date(),
      startTime: "",
      endTime: "",
      editedLawyer: 0,
      leaveExplaination: "",
      leaveReason: 0,
      unmount: false,
      view: 'week'
    };

    // this.takenList() = _.map(this.props.work, e => {
    //   return { id: e.id, type: e.type };
    // });

    this.handleWeek = this.handleWeek.bind(this);
    this.convertTakenIntoCalendar = this.convertTakenIntoCalendar.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.onDatesChange = this.onDatesChange.bind(this);
    this.onFocusChange = this.onFocusChange.bind(this);
    this.addVacation = this.addVacation.bind(this);
    this.generateCalendarUsers = this.generateCalendarUsers.bind(this);

  }

  calendarRef = React.createRef();

  componentDidUpdate(prevProps) {

    if (!_.isEqual(this.props.visible.sort(), prevProps.visible.sort())) {

      this.setState({
        schedule: this.convertTakenIntoCalendar(
          this.state.appointments,
          this.takenList()
        )
      });
    }

    if (prevProps.neolegalExclusive !== this.props.neolegalExclusive) {

      this.setState({
        schedule: this.convertTakenIntoCalendar(
          this.state.appointments,
          this.takenList()
        )
      });
    }

  }

  // componentWillUnmount(){
  //   source.cancel('schedule navigation');
  // }

  takenList() {
    let neoEx;
    let test = _.map(this.props.work, e => {
      neoEx = e.neolegalExclusive;
      if (!neoEx)
        neoEx = false;

      return { id: e.id, type: e.type, neolegalExclusive: neoEx };
    });

    return test;
  }

  componentDidMount() {

    let uID = this.props["userId"];

    if (!uID) {
      let token = window.parseJwt(localStorage.getItem('token'));

      uID = token.sub

    }

    let params = {
      auth_id: uID
    };

    Axios
      .all([
        axios.get("lawyers", {
          params: params
        }),
        axios.get("availabilities", {
          params: {
            default: false,
            week: this.props.weekId,
            year: this.props.year,
            auth_id: uID
          }
        })
      ])
      .then(response => {

        let res0 = response[0];
        if (res0.redirect) {
          return this.setState({ redirect: res0.to })
        }

        response = _.flatMap(response, (e) => {
          if (e && e.data && e.data.data)
            return e.data.data
        });

        response = _.reduce(response, function (memo, current) {
          return _.assign(memo, current)
        }, {})

        if (!_.isEmpty(response)) {
          localStorage.setItem("lawyerData", JSON.stringify(res0));


          // debugger;
          let arr2 = [];
          if (true || this.props.lang === 'en') {
            for (let t in response.types) {
              arr2.push({
                ...response.types[t],
                title: response.types[t].title_en
              })
            }
          } else
            arr2 = response.types;

          this.props.setLawyers({
            params: {
              lawyers: [
                {
                  auth_id: -1,
                  firstname: "Tous",
                  lastname: " ",
                  color: "#545454"
                },
                ...response.lawyers
              ],
              work: arr2
            }
          });

          // if (!e || !e.length || !e[0]) {
          let e = this.defaultVisible();
          // }

          this.props.setVisible({
            params: {
              visible: e
            }
          });

          let arr = [],
            av = response.taken;

          for (let a in av) {
            for (let t in av[a].taken) {

              if (av[a].taken[t] && av[a].taken[t].service_id !== undefined) {
                arr.push({
                  ...av[a].taken[t],
                  user: av[a].user
                });
              }
            }
          }

          this.setState({
            wData: av,
            loading: false,
            availabilities: response.availabilities,
            appointments: arr,
            error: false,
            deleteAppointment: false,
            calendarUsers: this.generateCalendarUsers(response.lawyers),
            schedule: this.convertTakenIntoCalendar(
              arr,
              this.takenList()
            )
          }, () => {
            const calendarInstance = this.calendarRef.current.getInstance();
            calendarInstance.setDate(
              this.props.weekMonday
                .clone()
                .add(1, "d")
                .utc()._d
            );
          });
        } else {

          this.setState({
            deleteAppointment: false,
            loading: false,
            availabilities: [],
            error: (
              <>
                <div>
                  Erreur, faîtes la tâche manuellement,<br></br>{" "}
                  puis avisez un IT
                </div>
              </>
            )
          });
        }
      });
  }

  generateCalendarUsers(lawyers) {

    let schedule1 = _.map(lawyers, function (e) {
      return {
        id: e.auth_id,
        name: e.firstname + " " + e.lastname,
        bgColor: e.color,
        borderColor: e.color
      };
    });
    // return schedule1
    let self = this;
    let schedule2 = _.map(lawyers, function (e) {
      return {
        id: "clientRDV_" + e.auth_id,
        name: e.firstname + " " + e.lastname,
        bgColor: self.darker(e.color),
        borderColor: e.color
      };
    });
    return schedule1.concat(schedule1, schedule2);
    // .unshift({id:"0",name:'tous',color:""});
  }

  darker(color) {
    color = color.substr(1);

    let colors = color.match(/.{1,2}/g);

    for (let c in colors) {
      colors[c] = parseInt(colors[c], 16);
      colors[c] -= 60;
      if (colors[c] < 0) colors[c] = 0;
      colors[c] = Number(colors[c]).toString(16);
      if (colors[c].length === 1) colors[c] = "0" + colors[c];
    }

    return "#" + colors.join("");
  }

  defaultVisible() {
    let uID = this.props["userId"],
      admin = this.props.admin;

    if (!uID) {
      let token = window.parseJwt(localStorage.getItem('token'));
      uID = token.sub;
      // debugger;
      admin = token.scope.admin
    }

    return admin === true
      ? [{ value: -1, label: "Tous" }]
      : [
        {
          value: uID,
          label: "Moi"
        }
      ];
  }

  convertTakenIntoCalendar(takens, takenList) {

    // if(localStorage.getItem('admin')=== 'false'){
    //     takens = _.filter(takens,{'user':parseInt(localStorage.getItem('user_id'))});
    // }

    if (this.props.visible && this.props.visible[0].value !== -1) {

      takens = _.filter(takens, f => {
        return _.find(this.props.visible, { value: f.user + "" });
      });
    }

    var self = this;

    let arr = _.map(takens, function (e) {
      let body = "",
        user = _.find(self.props.userList, { auth_id: e.user + "" });

      // if(!user||!user.firstname){
      //     debugger;
      // }
      // body += user.firstname +' '+user.lastname+'<br>'
      // body += '<i class="fa fa-coffee"></i>';
      body +=
        e.client && e.client.details
          ? '<i class="fas fa-tags"></i> <b>' + e.client.details + "</b><br>"
          : "";
      body +=
        e.client && e.client.name
          ? '<i class="fas fa-user"></i> ' + e.client.name + "<br>"
          : "";
      // body += e.service+"<br>";
      body += (e.app_description && e.app_description.trim()) ? "<i class=\"fas fa-comment\"></i> " + e.app_description + "" : "";

      body += "<hr>";
      body += e.ticket_id
        ? '<a href="https://neodesk.app/folder/' +
        e.ticket_id +
        '" target="_blank" class="ticket-link"><i class="fas fa-link" title="Aller sur le ticket"></i> ' +
        e.ticket_id +
        "</a>"
        : "";

      let start = moment(e.start),
        end = moment(e.end);
      let isAllDay = end.diff(start, "hours") > 12;
      if (isAllDay) {
        body += "<div>" + e.app_description + "</div>";
      }

      let str = "";

      str += user
        ? user.firstname + " " + user.lastname
        : "Utilisateur inconnu";

      str += e.service ? ", " + e.service : ", Action inconnue";
      str += e.ticket_id ? " #" + e.ticket_id : "";

      // if(!e.service)
      //   debugger;

      let withCustomer = false;

      let found = _.find(takenList, { id: parseInt(e.service_id) });
      // debugger;


      // if(found)
      //   console.log(found.neolegalExclusive,'<<===­­>>??', self.props.neolegalExclusive)
      // console.log('-=-=-=-==-=-=-=-=-=-=-)))>>>',self.props.neolegalExclusive,found)
      if (found && self.props.neolegalExclusive !== 'ALL' && found.neolegalExclusive !== self.props.neolegalExclusive) {

        return false;

      }
      // else if(found){
      //   console.log(found.neolegalExclusive,'diff??', self.props.neolegalExclusive)
      // }

      // if(!found)
      //   console.log(e.service_id);

      if (
        e.service_id < 999 &&
        takenList &&
        found &&
        found.type === 0
      )
        withCustomer = true;

      // if(found)
      //   console.log('=-=-=-=-=-=-=-=-=-=-=->>>',found,found.neolegalExclusive)

      if (found && (found.neolegalExclusive === false || !found.neolegalExclusive)) {
        str = "💼 " + str;
      }

      return {
        id: e.id,
        calendarId: withCustomer ? "clientRDV_" + e.user : e.user,
        title: str,
        start: e.start,
        end: e.end,
        category: "time",
        body: body,
        isAllDay: isAllDay,
        neolegalExclusive: (found && found.neolegalExclusive === true) ? found.neolegalExclusive : false,
        onupdateSchedule: e => {
          // console.log("are??", e);
        }
      };
    });

    // arr.push({
    //     id:'000000',
    //     calendarId:"38",
    //     title:'jay',
    //     start:"2019-11-27T13:00:00+00:00",
    //     end:"2019-11-27T20:00:00+00:00",
    //     isAllDay:true,
    //     category:'allday',
    //     // body:'<strong>DAMELEON</strong>',
    //     isReadOnly:true
    // });

    return arr.filter(Boolean);
  }

  handleSelect(e) {

    if (!e || !e.length || !e[0]) {
      e = this.defaultVisible();
    }

    if (_.find(e, { value: ELAWYER })) {
      e = [
        ...e,
        ..._.map(
          _.filter(
            this.props.userList, { type: ELAWYER }
          ),
          (v) => {
            return {
              value: v.auth_id,
              label: v.firstname,
              color: '#040404'
            }
          }
        )
      ]
    }

    if (_.find(e, { value: LAWYER })) {
      e = [
        ...e,
        ..._.map(
          _.filter(
            this.props.userList, { type: LAWYER }
          ),
          (v) => {
            return {
              value: v.auth_id,
              label: v.firstname,
              color: '#040404'
            }
          }
        )
      ]
    }
    this.props.setVisible({
      params: {
        visible: e
      }
    });
  }

  handleWeek(e) {
    const calendarInstance = this.calendarRef.current.getInstance();
    // enlever le 1er if si non necessaire
    // sunday = (difference>0)?
    let m = moment(e.start);
    if (e.next) {
      m = this.props.weekMonday.clone().add(1, "w");
      this.props.setWeek({
        week: m.isoWeek(),
        year: m.isoWeekYear(),
        monday: m,
        isDST: m.isDST()
      });
    } else if (e.previous) {
      m = this.props.weekMonday.clone().subtract(1, "w");
      this.props.setWeek({
        week: m.isoWeek(),
        year: m.isoWeekYear(),
        monday: m,
        isDST: m.isDST()
      });
    } else if (e) {
      this.props.setWeek({
        week: e.value,
        year: e.year,
        monday: m,
        isDST: m.isDST()
      });
    }

    let uID = this.props["userId"];

    if (!uID) {
      let token = window.parseJwt(localStorage.getItem('token'));

      uID = token.sub

    }

    axios
      .get("/availabilities", {
        params: {
          default: false,
          week: e.value || m.isoWeek(),
          year: e.year || m.isoWeekYear(),
          user: this.state.editedLawyer,
          auth_id: uID
        }
        // ,
        // cancelToken: source.token
      })
      .then(response => {
        if (response && response.data) {
          let arr = [],
            av = response.data.data.taken;

          for (let a in av) {
            for (let t in av[a].taken) {
              arr.push({
                ...av[a].taken[t],
                user: av[a].user
              });
            }
          }

          this.setState(
            {
              wData: av,
              loading: false,
              schedule: this.convertTakenIntoCalendar(arr, this.takenList()),
              availabilities: response.data.data.availabilities,
              appointments: arr,
              error: false,
              deleteAppointment: false,
              view: 'week'
            },
            () => {
              calendarInstance.setDate(
                this.props.weekMonday
                  .clone()
                  .add(1, "d")
                  .utc()._d
              );
            }
          );
        }
      });
  }

  onDatesChange({ startDate, endDate }) {
    this.setState({ startDate, endDate });
  }

  onFocusChange(focusedInput) {
    this.setState({ focusedInput: !focusedInput ? START_DATE : focusedInput });
  }

  renderLawyers(list) {
    // console.log('are?')
    let arr = list.map(e => {
      return {
        value: e.auth_id,
        label: e.firstname + "." + e.lastname,
        color: e.color || "#ffffff"
      };
    });

    arr.push({
      value: ELAWYER,
      label: 'externes',
      color: "#040404"
    });

    arr.push({
      value: LAWYER,
      label: 'Avocats',
      color: "#FFD700"
    });

    return arr;
  }

  addVacation() {
    // dispatch({
    //     type:rootsActions.usersActions.TOGGLE
    // });
  }

  render() {

    var theme = {
      "common.border": "1px solid #e5e5e5",
      "common.backgroundColor": "white",
      "common.holiday.color": "#ff4040",
      "common.saturday.color": "#333",
      "common.dayname.color": "#333",
      "common.today.color": "#333"
    };

    if (this.state.redirect)
      return (<Redirect to={this.state.redirect} />);

    let currentUser = _.find(this.props.userList, { auth_id: this.props.userId });

    return (
      <>
        <LeaveModal />
        <h1>
          #{this.props.weekId},{" "}
          {moment(this.props.weekMonday._d)
            .add(3, "days")
            .format("MMMM YYYY")}{" "}
        </h1>
        <ButtonGroup>
          <Button
            color="neo-blue"
            onClick={() => this.handleWeek({ previous: true })}
          >
            «
          </Button>
          <Select
            id="weekSearch"
            className={"button-select"}
            onChange={e => this.handleWeek(e)}
            options={this.props.weekList}
            defaultValue="Cette semaine"
            name="week"
            isSearchable
            placeholder={"cette semaine"}
          />
          <Button
            color="neo-blue"
            onClick={() => this.handleWeek({ next: true })}
          >
            »
          </Button>
          <div>
            <Select
              id="viewType"
              style={{ paddingTop: '2px' }}
              className={"button-select"}
              onChange={(e) => {

                const calendarInstance = this.calendarRef.current.getInstance();
                if (e.value === 'day') {
                  // debugger;
                  if (this.props.weekMonday.isSame(moment(), 'week')) {
                    calendarInstance.setDate(
                      moment().utc()._d
                    );
                  } else {
                    let m = moment();
                    let uID = this.props["userId"];

                    if (!uID) {
                      let token = window.parseJwt(localStorage.getItem('token'));

                      uID = token.sub

                    }
                    axios
                      .get("/availabilities", {
                        params: {
                          default: false,
                          week: m.isoWeek(),
                          year: m.isoWeekYear(),
                          user: this.state.editedLawyer,
                          auth_id: uID
                        }
                        // ,
                        // cancelToken: source.token
                      })
                      .then(response => {
                        if (response && response.data) {
                          let arr = [],
                            av = response.data.data.taken;

                          for (let a in av) {
                            for (let t in av[a].taken) {
                              arr.push({
                                ...av[a].taken[t],
                                user: av[a].user
                              });
                            }
                          }

                          this.setState(
                            {
                              wData: av,
                              loading: false,
                              schedule: this.convertTakenIntoCalendar(arr, this.takenList()),
                              availabilities: response.data.data.availabilities,
                              appointments: arr,
                              error: false,
                              deleteAppointment: false
                            },
                            () => {
                              calendarInstance.setDate(
                                moment()
                                  .utc()._d
                              );
                            }
                          );
                        }
                      });
                  }

                }

                this.setState({
                  view: e.value
                })
              }}
              options={
                [
                  {
                    id: 'week',
                    value: 'week',
                    label: 'Semaine'
                  },
                  // {
                  //   id:'month',
                  //   value:'month',
                  //   label:'Mois'
                  // },

                  {
                    id: 'day',
                    value: 'day',
                    label: 'aujourd\'hui'
                  }
                ]

              }
              defaultValue="week"
              name="viewType"
              // isSearchable
              placeholder={"Semaine"}
            />
          </div>
          {this.props.admin ? (
            <Button color="neo-orange" onClick={() => this.props.toggleModal()}>
              Ajouter un congé
            </Button>
          ) : (
            ""
          )}
          {this.props.admin ? (
            <Select
              id="filterByType"
              className={"button-select"}
              onChange={(e) => {
                this.props.setVisibleNeolegalExclusive({ params: { neolegalExclusive: e.value } })
              }}
              options={
                [
                  {
                    id: 'ALL',
                    value: 'ALL',
                    label: 'Aucun filtre'
                  },
                  {
                    id: 'true',
                    value: true,
                    label: 'Exclusif à Neolegal'
                  },

                  {
                    id: 'false',
                    value: false,
                    label: 'Services aux partenaires (B2B)'
                  }
                ]

              }
              defaultValue="ALL"
              name="filterByType"
              // isSearchable
              placeholder={"Aucun filtre"}
            />
          ) : (
            ""
          )}

          {/* <Button color={"neo-blue"} title={"Calendrier"}> */}
          <Link
            className="btn btn-neo-blue"
            to={"/calendar"}
            title={"Calendrier"}
            style={{ color: '#FFF' }}
          >
            <FontAwesomeIcon icon={faCalendar} />
          </Link>
          {currentUser && currentUser.type !== LAWYER ? <Link
            to={"/factures"}
            style={{ color: '#FFF' }}
            title="factures"
            className="btn btn-neo-blue"
          >
            <FontAwesomeIcon icon={faMoneyBill} />
          </Link> : ''}
          {this.props.admin ? <Link
            to={"/groups"}
            style={{ color: "#FFF" }}
            title="factures"
            className="btn btn-neo-blue"
          >
            <FontAwesomeIcon icon={faCogs} />
          </Link> : ''}

          {/* </Button> */}
        </ButtonGroup>
        <div>
          <Select
            id="lawyerAppointmentDisplay"
            onChange={e => this.handleSelect(e)}
            options={this.renderLawyers(
              this.props.userList
            )}
            styles={colourStyles}
            name="avocats"
            isClearable
            isSearchable
            isMulti
            placeholder={
              !this.props.admin ? "Moi" : "Tous"
            }
          />
        </div>
        <Calendar
          ref={this.calendarRef}
          useDetailPopup={true}
          onupdateSchedule={e => {
            // console.log('there will be blood --->',e);
          }}
          taskView={false}
          isReadOnly={this.props.admin ? false : true}
          defaultView="week"
          theme={theme}

          week={{
            startDayOfWeek: 1,
            // workweek: true,
            hourStart: 0,
            hourEnd: 24
          }}
          timezones={[
            {
              timezoneOffset: this.props.isDST ? 4 * 60 * -1 : 5 * 60 * -1,
              displayLabel: "joie",
              tooltip: "Ebola"
            }
          ]}
          calendars={this.state.calendarUsers}
          disableDblClick={true}
          disableClick={true}
          schedules={this.state.schedule}
          scheduleView
          time
          view={this.state.view}
          template={{
            allday(schedule) {
              return `${schedule.title}<i class="fa fa-refresh"></i>`;
            },
            alldayTitle() {
              return '<div class="journalierParent"><strong class="journalier" >Journalier</strong></div>';
            }
          }}
          onBeforeDeleteSchedule={({ schedule }) => {
            const calendarInstance = this.calendarRef.current.getInstance();

            calendarInstance.deleteSchedule(schedule.id, schedule.calendarId);

            axios
              .delete("availabilities", {
                params: {
                  id: schedule.id,
                  auth_id: this.props.userId
                }
                // ,
                // cancelToken: source.token
              })
              .then(e => {
                // console.log(e, "omedetou gozaimasu");
              });
          }}
          // on={{
          //     beforeDeleteSchedule:() =>{
          //         console.log('hikari are')
          //         }
          //     }
          // }
        />
      </>
    );
  }
}

const mapStateToProps = state => ({
  toggle: state.users.toggle,
  userList: state.users.userList,
  work: state.users.work,
  weekList: state.time.weekList,
  weekId: state.time.currentWeek,
  year: state.time.currentYear,
  weekMonday: state.time.currentMonday,
  visible: state.users.visible,
  isDST: state.time.isDST,
  admin: state.users.result.admin,
  userId: state.users.result.userId,
  neolegalExclusive: state.users.neolegalExclusive
});

const mapActionsToProps = {
  setWeek: rootsActions.timeActions.setWeek,
  setLawyers: rootsActions.usersActions.setLawyers,
  setVisible: rootsActions.usersActions.setVisible,
  setVisibleNeolegalExclusive: rootsActions.usersActions.setVisibleNeolegalExclusive,
  toggleModal: rootsActions.usersActions.toggleModal
};

export default connect(mapStateToProps, mapActionsToProps)(Schedule);
