import React from 'react';

export default function SubMenu({
    link,
    name,
    options,
    icon}) {

    let arr = [];

    for(let o in options){
        arr.push(
            <li key={'o_'+o}>
                <a href={`${process.env.REACT_APP_PUBLIC_URL}${options[o].url}`}>{options[o].name}</a>
            </li>
        )
    }


    return (
        <li className={options?"has-sub-menu active":"selected"}>
            <a href={link}>
                <div className="icon-w">
                    <i className={icon}></i>
                </div>
                <span>{name}</span>
            </a>
            <div className="sub-menu-w active">
                <div className="sub-menu-header">
                    {name}
                </div>
                <div className="sub-menu-icon">
                    <i className={icon}></i>
                </div>
                <div className="sub-menu-i">
                    <ul className="sub-menu">
                        {arr}
                    </ul>
                </div>
            </div>
        </li>
    );
}