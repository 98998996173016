import React, { Component } from "react";
import { 
    Button,
    FormGroup, 
    Input,
    Label,
    Row,
    Col
} from "reactstrap";

import {
  Redirect
} from "react-router-dom";

import moment  from 'moment-timezone';
import Select from "react-select";

import Table from '../utils/Table';

import TableIcon from '../utils/TableIcon';
import _ from "lodash";
import { adminAxios as axios } from "../../utils/axios";
import { privateAxios } from "../../utils/axios";

import  NeoLabel  from "../utils/NeoLabel";
import { connect } from "react-redux";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faTimesCircle,faClock,faSave } from '@fortawesome/free-solid-svg-icons';

import rootsActions from '../../_actions/root-actions'
import ExternalDashboard from "../ExternDashboard";
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

import '../adminDashboard/datepicker.css';
import {SingleDatePicker} from 'react-dates';
import AlertNotification from '../utils/Alert'

// import { Redirect } from 'react-router-dom';

const ELAWYER = 'elawyer';
const DATE_FORMAT = "YYYY-MM-DD";

const Check   = <FontAwesomeIcon icon={faCheckCircle} color={'green'}/>;
const Cross   = <FontAwesomeIcon icon={faTimesCircle} color={'red'}/>;
// const Pending = <FontAwesomeIcon icon={faClock} color={'yellow'}/>;


const STRING = 'string';
const OBJECT = 'object';
const elawyer = 'elawyer'
// const manage = 'manage
// const BLOCK = 'block';
const NONE = 'none';
// const CAD = 'CAD';

const columns = [
  {
      Header: {fr:'#Facture',en:'Bill#'},
      accessor: 'invoiceId',
  },
  {
      Header: {fr:'#Généré le',en:'Generated the'},
      accessor: 'date',
  },
  {
    Header: {fr:'Avocat',en:'Lawyer'},
    accessor: 'username',
  },
  {
      Header: {fr:'Approuvé',en:'Approved'},
      accessor: 'approvedIcon',
  },
  // {
  //   Header: 'Complété',
  //   accessor: 'doneIcon',
  // },  
  {
    Header: {fr:'Télécharger',en:'Download'},
    accessor: 'download',
  },
  {
    Header: {fr:'Validate',en:'Edit'},
    accessor: 'edit',
  },  
];

const YELLOW_PENDING = '#E6D200';
const GREEN_APPROVED = 'green';
const RED_DENIED = 'red';

const INFO = 'info';
const DANGER = 'danger';

const APPROVED = 1;

const PENDING = 0;
const REFUSED = -1;
const ALL = 'ALL'
// const fakeData = [
//   {
//     invoiceId:'12',
//     date:'2011-02-11',
//     approvedIcon:<TableIcon 
//       icon={faClock}
//       color={YELLOW_PENDING}
//       callback={() => {
//         // let id = "/facture/1"
        
//         // this.setState({redirect:id})
//       }}
//     />,
//     doneIcon:Check,
//     edit: <TableIcon 
//     callback={() => {
//       let id = "/facture/1"
      
//       this.setState({redirect:id})
//     }}
//   />
//   }
// ];

class FactureTable extends Component{
    constructor(props){
      super(props)

      this.state = {
        ...props,
        amount:'',
        description:'',
        ticket:'',
        new:true,
        user:{auth_id: 0,firstname:"Tous",lastname:' ',type:ELAWYER},
        day:moment().startOf('month').subtract(1,'months').subtract(1,'days'),
        start_date:moment().startOf('month').subtract(1,'months').subtract(1,'days').format(DATE_FORMAT),
        endDay:moment().endOf('month'),
        end_date:moment().endOf('month').format(DATE_FORMAT),
        status:ALL,
        alertColor:INFO,
        alertVisible:false,
        alertMessage:''
      }
      // console.log(this.state.day)
      // debugger;
      
      this.init = this.init.bind(this);
      this.onChange = this.onChange.bind(this);
      // this.addLign = this.addLign.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
      this.Callback = this.Callback.bind(this);
      
      this.fetchFilter = this.fetchFilter.bind(this);
      this.createInvoice = this.createInvoice.bind(this);
      
      this.genLang = this.genLang.bind(this);
    }


    init(){
      return {
        amount:'',
        description:'',
        ticket:'',
        // speciality:0,
        // type:0
      }
    }

    genLang(text,lang){
      if(!lang)
          lang = this.props.lang;
  
      if(typeof text === STRING){
          return text;
      }
  
      if(typeof text === OBJECT){
          if(lang && text[lang]){ 
              return text[lang];
          }
          else{
              console.error('lang missing');
              return ''
          }
      }
  
      return '';
  }

    onChange(e) {

      this.setState({
        user: e,
      },this.fetchFilter);
    }

    dateChange(obj) {
      this.setState(obj,this.fetchFilter)
    }


    componentDidMount() {
      let uID = this.props["userId"];

      if(!uID){
        let token = window.parseJwt(localStorage.getItem('token'));
        
        uID=token.sub
        
      }
      
      if (!localStorage.getItem("Data")) {
        privateAxios
          .get("lawyers", {
            params: { auth_id:uID},
          })
          .then(this.Callback);
      } else {
        this.Callback(JSON.parse(localStorage.getItem("Data")));
      }
    }

  Callback(response) {
    if(response.redirect){
      return  this.setState({redirect:response.to})  
    }

    if (response && response.data && response.data.data[0]) {
      localStorage.setItem("Data", JSON.stringify(response));
      
      let res = response;

      this.props.init({params:{
        userList:[{auth_id: 0,firstname:"Tous",lastname:' ',type:ELAWYER},...response.data.data[0].lawyers],
        types:[...response.data.data[0].types],
        specialities:[...response.data.data[0].specialities],
        durations:[...response.data.data[0].durations]
      }});

      this.setState({
        userList: [
          {auth_id: 0,firstname:"Tous",lastname:' ',type:ELAWYER},
          ...res.data.data[0].lawyers,
        ],
        types: [...res.data.data[0].types],
        specialities: [...res.data.data[0].specialities],
        durations: [...res.data.data[0].durations],
        // data:[...data]
      }, () => console.log('===========>',this.state));

      this.fetchFilter()

    }
  }

  fetchFilter(){
   
    let uID = this.props["userId"];

    if(!uID){
      let token = window.parseJwt(localStorage.getItem('token'));
      
      uID=token.sub
      
    }
    
    this.props.toggleLoading();
    axios.get(
      'invoicesbyfilter',{
        params:{
          auth_id:uID,
          user_id:this.state.user.value,
          start_date:this.state.start_date,
          end_date:this.state.end_date,
          status:this.state.status,
          // format:'json'
        }
    })
    .then(
      (response) => {

 
        if(response && response.data && response.data.data){

          
          this.props.toggleLoading();
          // let TableEdit = '';
          let invoiceList = this.tableMap(response.data.data.invoice_list);

          this.props.invoiceListFetch({invoiceList});


        }
        else{
          this.setState({
            alertColor:DANGER,
            alertVisible:true,
            alertMessage:'Erreur'
          })
          this.props.toggleLoading();
        }

      },
      (error) => {
        this.props.toggleLoading();
        this.setState({
          alertColor:DANGER,
          alertVisible:true,
          alertMessage:'Erreur'
        })
      }
    )
  }
  tableMap(data){
    let TableEdit = '';
    

    return _.map(data, (e) => {
      
      TableEdit = <TableIcon 
        callback={() => {
          let id = "/facture/"+e.id;
          
          this.setState({redirect:id})
        }}
      />
      let done = e.done==='1'?Check:Cross;
      
      let icon = faClock;
      let color = YELLOW_PENDING;
      
      if(parseInt(e.approved)===APPROVED){
        color = GREEN_APPROVED;
        icon = faCheckCircle;
      }
      else if(parseInt(e.approved)===REFUSED){
        color = RED_DENIED;
        icon = faTimesCircle;
      }

      let user = _.find(this.props.userList,{
        'auth_id':e.user_id.toString()
      });

      let download = <TableIcon
        icon={faSave}
        color={'black'}
        callback={() => {
          axios
            .get('/invoices/'+e.id+'?auth_id='+this.props.userId+'&format=pdf')
            .then( (response) => {
              if(response && response.data && response.data.data && response.data.data.pdf){

                let pdf = response.data.data.pdf
                let  byte,blob;
                    
                byte = atob(pdf.replace(/^data:application\/pdf;base64,/,''))
                let buffer = new ArrayBuffer(byte.length);
                let view = new Uint8Array(buffer);
                for (var i = 0; i < byte.length; i++) {
                    view[i] = byte.charCodeAt(i);
                }
                
                blob = URL.createObjectURL(new Blob([view] , {type:'application/pdf'}));

                this.setState({
                  pdf:blob
                }, () => document.getElementById('downloadAnchor').click())
              }
              else {


                console.error('no pdf')
              }
            },() => {
              console.error('erreur pdf....')
            })
        }}
      />

      let name = (user && user.lastname && user.lastname[0])?user.firstname+'.'+user.lastname[0].toUpperCase():'Utilisateur méconnu'
      return {
        invoiceId:e.invoice_number,
        approvedIcon:<TableIcon 
          icon={icon}
          color={color}
          callback={() => {
            this.props.invoiceTogglePending({
              collection:'list',
              id:e.id
            })
            // let id = "/facture/1"
            
            // this.setState({redirect:id})
          }}
        />,
        username:name,
        doneIcon:done,
        date:e.creation_date,
        edit:TableEdit,
        download:download,
        ...e
      }
    });
  }
    componentDidUpdate(prevProps){
      let obj = {};
      
      if(prevProps.specialities&&!prevProps.specialities.isEqual(this.props.specialities)){
        obj.specialities = this.props.specialities
      }

      if(prevProps.types&&!prevProps.types.isEqual(this.props.types)){
          obj.types = [{title:'Tous',value:0,specialities:[{id:0}]},...this.props.types]
      }

      if(prevProps.durations&&!prevProps.durations.isEqual(this.props.durations)){
          obj.durations = this.props.durations
      }

      if(prevProps.userList&&!prevProps.userList.isEqual(this.props.userList)){
          obj.userList = [{auth_id: -1,firstname:"Tous",lastname:' ',type:ELAWYER},...this.props.userList];
      }

      if(_.keys(obj).length)
          this.setState(obj);
    }

    handleSubmit(){

      let arr = [];
      arr= _.map(this.props.list,(elt) => {
        return {
          id:elt.id,
          status:elt.approved
        }
      });
      
      this.props.toggleLoading();
      
      let uId;

      if(!uId){
        let token = window.parseJwt(localStorage.getItem('token'));
        
        uId=token.sub;
        
      }
      axios
        .post('invoices/status',{
          auth_id:uId,
          data:arr
        })
        .then((response) => {
          
          if(response && response.data && response.data.data){

            this.props.toggleLoading();
            this.setState({
              alertColor:INFO,
              alertVisible:true,
              alertMessage:'Les factures sont modifiées'
            })
          }
          else{
            this.setState({
              alertColor:DANGER,
              alertVisible:true,
              alertMessage:'Erreur'
            })
          }
        },
        (error) => {
          this.setState({
            alertColor:DANGER,
            alertVisible:true,
            alertMessage:'erreur'
          })
          this.props.toggleLoading();
        }
      );
    }

    func(fun){
      return fun();
    }

    createInvoice(){
      let user = this.state.user;
      if(!user)
        user={value:12170};

      return this.setState({
        redirect:"/facture/new/?user_id="+user.value
      });
    }

    render(){

      let state = this.state;

      if(state.redirect)
        return (<Redirect push to={{pathname:state.redirect}} />);

      return(<>
          <Row>
            <Col md={2}>
              <FormGroup>
                <Label>{this.genLang({fr:'Statut',en:'Status'})} </Label>
                <Input
                  type={"select"}
                  id={"status"}
                  onChange={(e) => {
                    this.setState({
                      status:e.target.value
                    },this.fetchFilter)
                  }}
                >
                  
                  <option value={ALL}>{this.genLang({fr:'Toutes',en:'All'})}</option>
                  <option value={PENDING}>{this.genLang({fr:'En attente',en:'Pending'})}</option>
                  <option value={REFUSED}>{this.genLang({fr:'Refusées',en:'Rejected'})}</option>
                  <option value={APPROVED}>{this.genLang({fr:'Approuvées',en:'Approved'})}</option>
                </Input>
              </FormGroup>
            </Col>
            <Col md={3}>
              <FormGroup>    
                <Label>
                {this.genLang({fr:'Avocat',en:'Lawyer'})}
                </Label>
                <Select
                  onChange={this.onChange}
                  value={state.user||{value:0,auth_id: 0,firstname:this.genLang({fr:'Tous',en:'all'}),lastname:' ',type:ELAWYER}}
                  options={
                     _.map(_.filter(this.state.userList,{type:ELAWYER}),(u) => {
                          return {
                              label:u.firstname +' '+ u.lastname[0],
                              value:u.auth_id,
                              name:"user",
                              type:u.type,
                          }
                      })

                      // return arr.unShift(
                      //   {
                      //     label:'Tous',
                      //     value:0,
                      //     name:"Tous",
                      //     type:0,
                      // }
                      // )
                    }
                  name=""
                  isSearchable
                  placeholder={
                      this.func(() => {
                        
                          let found = _.find(this.props.userList,{auth_id:state.user+''});
                          if(found)
                              return found.firstname+' '+found.lastname[0];

                          return 'Tous';
                      })
                  }
                />
              </FormGroup>
            </Col>
            <Col md={2}>
              <FormGroup>
                <Label>
                {this.genLang({fr:'Date de création',en:'Created date'})}
                </Label>
                <SingleDatePicker
                  
                  id={"date_0"}
                  // display={'block'}
                  onDateChange={(day) => { 
                    let datestring = moment(day).format(DATE_FORMAT);
                    
                        this.setState({ 
                          start_date:datestring,
                          day:day
                        },this.fetchFilter)
                    // }
                  }}
                  focused={state.focused} // PropTypes.bool
                  onFocusChange={({ focused }) => this.setState({ focused })} // PropTypes.func.isRequired
                  numberOfMonths={1}
                  date={state.day||moment().startOf('month').subtract(1,'months')}
                  isOutsideRange={() => false}
                />
              </FormGroup>
            </Col>
            <Col md={2}>
              <FormGroup>
                <Label>
                  {this.genLang({fr:'Date de fin',en:'End date'})}
                </Label>
                <SingleDatePicker
                  id={"date_1"}
                  // display={'block'}
                  onDateChange={(endDay) => { 
                    let datestring = moment(endDay).format(DATE_FORMAT);
                    
                        this.setState({ 
                          end_date:datestring,
                          endDay:endDay
                        },this.fetchFilter)
                    // }
                  }}
                  focused={state.endFocused} // PropTypes.bool
                  onFocusChange={({ focused }) => this.setState({ endFocused:focused })} // PropTypes.func.isRequired
                  numberOfMonths={1}
                  date={state.endDay||moment()}
                  isOutsideRange={() => false}
                />
              </FormGroup>
            </Col>
            <Col md={3}>
              <Button 
                disabled={!this.state.user||!this.state.user.value}
                // id="PDF-preview" 
                style={{position:'absolute',color:'white',right:0,top:5}}
                type="button"
                color="info"
                onClick={this.createInvoice }
                // size='md'
                >
                <i className="fas fa-file-invoice-dollar"></i>
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <AlertNotification
                color={state.alertColor}
                visible={state.alertVisible}
                message={state.alertMessage}
              />
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <NeoLabel><>{this.genLang({fr:'Liste des factures',en:'Bill list'})}</></NeoLabel>
                <Table 
                  data={_.map(this.props.list,(e) => {
                    return {
                      ...e,
                      
                    }
                  })}
                  columns={_.map(columns,(e) => {
                    return {
                      ...e,
                      'Header':this.genLang(e.Header)
                    }
                  })}
                />
            </Col>
          </Row>
          <Row>
            <Col>
              <p style={{"textAlign":"center"}}>
              <Button
                color={"success"}
                onClick={this.handleSubmit}
                type="button"
                size="md"
                className={"m-t-10"}
              >
                Mettre à jour
              </Button>
              </p>
            </Col>
          </Row>
          <a id='downloadAnchor' style={{display:NONE}} href={this.state.pdf} download >empty</a>
        </>
      )
    }
} 

const mapStateToProps = (state) => ({
  userList:state.users.userList,
  specialities:state.users.specialities,
  types:state.users.types,
  durations:state.users.durations,
  manual:state.invoices.manual,
  automatic:state.invoices.automatic,
  list:state.invoices.list,
  admin:state.users.result.admin,
  userId:state.users.result.userId,
  lang:state.users.result.language,
});


const mapActionsToProps = {
  
  invoiceListFetch:rootsActions.invoiceActions.invoiceListFetch,
  invoiceFetch:rootsActions.invoiceActions.invoiceFetch,
  invoiceAddManual:rootsActions.invoiceActions.invoiceAddManual,
  init:rootsActions.usersActions.initUsersData,
  invoiceTogglePending:rootsActions.invoiceActions.invoiceTogglePending,
  toggleLoading:rootsActions.loadingActions.toggleLoading,
}

// export default connect(mapStateToProps)(FactureTable)
export const FacturationListTable = connect(mapStateToProps,mapActionsToProps)(FactureTable);

export class FacturationListAdmin extends Component{
  
  render(){
    
    return(
     <ExternalDashboard>
        <FacturationListTable {...this.props}/>
      </ExternalDashboard>)
  }
}
// export const FeesByGroupForm = connect(mapStateToProps)(ByGroup);
// export const FeesTable = connect(mapStateToProps)(FTable);
