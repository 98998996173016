// import auth from '../component/auth';

// import { withRouter } from 'react-router-dom'
export  const LOGIN = 'LOGIN';
export  const LOGOUT = 'LOGOUT';

export const TOGGLE = 'TOGGLE_MODAL';
export const SET_VISIBLE = 'SET_VISIBLE';
export const LAWYER_LIST = 'LAWYER_LIST';

export const CHANGE_FIELD = 'CHANGE_FIELD';
export const INIT_DATA = 'INIT_DATA';
export const SET_VISIBLE_BY_NEOLEGAL_EXCLUSIVE = 'SET_VISIBLE_BY_NEOLEGAL_EXCLUSIVE';
export function changeValue(params){
    return {type:CHANGE_FIELD,params:params};
}

window.addEventListener('user.change', function (e) {
    changeValue(e);
});
  


export function initUsersData({params,status}){
    return {type:INIT_DATA,params:params};
}

export function setLawyers({params,status}){
    return {type:LAWYER_LIST,params:params};
}

export function toggleModal(){
    return {type:TOGGLE};
}

export function onLogin({params,status}){

    if(!params)
        return {
           type:'ddddd' 
        };

    const p = {};
    
    p["email"] = params.data.email;
    p["token"] = params.data.token;
    // if login v1
    p["id_users"] = (params.data.profile)?params.data.profile.id_users:0;
    p["status"] = status;
    p["userName"] = params.data.profile.firstname;
    p["admin"] = params.data.profile.admin
    p["id_zendesk"] = params.data.profile.id_zendesk;
    p["type"] = params.data.profile.user_type;
    p['ampm'] = params.data.profile.ampm;
    p['dispo'] = params.data.profile.dispo;

    return {
        type:LOGIN,
        params:p
    }
}

export function setVisible({params}){
    
    return {
        type:SET_VISIBLE,
        params:params
    }
}

export function setVisibleNeolegalExclusive({params}){
    console.log('params ==========>',params)
    return {
        type:SET_VISIBLE_BY_NEOLEGAL_EXCLUSIVE,
        params:params
    }
}

export function onLogout(){
    return {
        type:LOGOUT
    }
}
