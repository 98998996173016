import React, {Component} from 'react';
import {
    Button,
    Form,
    FormGroup,
    Input
} from 'reactstrap';
import Select from 'react-select';

import _ from 'lodash';
// import {privateAxios as axios} from '../../utils/axios';
import {adminAxios as axios} from '../../utils/axios';
import { connect } from 'react-redux';
import Alert from "../utils/Alert"
const list = 'list';

const NONE = 'none';
const BLOCK = 'block';
const DANGER = 'danger';
const PRIMARY = 'primary';
const FOLLOW_UP = 'follow_up';
class Ticket extends Component{
    constructor(props){
        super(props);

        this.state = {
            ticketOld:'',
            ticketNew:'',
            alertColor:PRIMARY,
            alert:''
        }
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    handleSubmit(e){
        let uID;

        if(!uID){
          let token = window.parseJwt(localStorage.getItem('token'));
          
          uID=token.sub  
        }    

        // let state = this.state;
        
        axios
        .post("/ticket", {
            auth_id:uID,
            // action:FOLLOW_UP,
            ticketOld:this.state.ticketOld,
            ticketNew:this.state.ticketNew
        })
        .then(
            (status) =>{
                console.log(status)
                let data = status.data.data;
                this.setState({
                    ticketOld:'',
                    ticketNew:'',
                    alertColor:data.success?PRIMARY:DANGER,
                    alert:data.message
                })
            },
            (err) =>{
                console.log('xyz',err)
                this.setState({
                    alertColor:DANGER,
                    alert:err.message||'une erreur est survenue'
                })
            },
        )
    }

    // componentDidMount(){
    //     let uID;

    //     if(!uID){
    //       let token = window.parseJwt(localStorage.getItem('token'));
          
    //       uID=token.sub  
    //     }    

    //     if(!localStorage.getItem('lawyerData')){ 
    //         axios.get(
    //             'lawyers',
    //             {params:{auth_id:uID}}
    //         )
    //         .then(this.lawyerCallback);
    //     }
    //     else{
    //         this.lawyerCallback(JSON.parse(localStorage.getItem('lawyerData')))
    //     }
    // }

    // }
    
    render(){
        return (
            <Form>
                <FormGroup>
                    Ticket <span style={{color:'red'}}>CLOS</span>
                    <Input
                        required
                        value={this.state.ticketOld} 
                        type={'text'}
                        onChange={(e) => this.setState({ticketOld:e.currentTarget.value})} 
                    />
                </FormGroup>
                <FormGroup>
                    Ticket de <span style={{color:'red'}}>SUIVI</span>
                    <Input
                        required
                        value={this.state.ticketNew} 
                        type={'text'}
                        onChange={(e) => this.setState({ticketNew:e.currentTarget.value})} 
                    />
                </FormGroup>
                
                <Alert 
                    visible={!!this.state.alert}
                    color={this.state.alertColor}
                    message={this.state.alert}
                />
                
                <FormGroup className={"text-center"}>
                    <Button color={"primary"} type={"button"} onClick={ this.handleSubmit } >soumettre</Button>
                </FormGroup>
            </Form>)
    }
}

const mapStateToProps = state => ({
    userList:state.users.lawyers,
});

export default connect(mapStateToProps)(Ticket)