import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
// import './main.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

// import ReactGlobalize from "react-globalize";
// import Globalize from 'globalize';
// import globalizeLocalizer from 'react-widgets-globalize';

import 'bootstrap/dist/css/bootstrap.min.css';

// Globalize.locale('en')

// globalizeLocalizer()

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
