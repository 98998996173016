import React, { Component } from "react";
import { Link } from "react-router-dom";

import {
  ButtonGroup,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  FormGroup,
  UncontrolledTooltip,
  Input,
} from "reactstrap";

import Select from "react-select";

import _ from "lodash";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCogs,
  faMoneyBill,
  faBusinessTime,
  faSave,
  faTrash,
  faObjectUngroup,
} from "@fortawesome/free-solid-svg-icons";

// const LAWYERS = 1;
const SALES = 2;
const ELAWYER = "elawyer";
const ONT_ELAWYER = "ont_elawer"
const LAWYER = "lawyer";
const STRING = 'string';
const OBJECT = 'object';

export default class Menu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userList: [{ auth_id: 0, firstname: this.genLang({fr:"Tous",en:'All'},this.props.lang||'fr'), lastname: " " }],
      modal: false,
      lang:this.props.lang||'fr'
    };
    this.toggle = this.toggle.bind(this);
    this.genLang = this.genLang.bind(this);
    this.warningToggle = this.warningToggle.bind(this);
  }
    
  genLang(text,lang){
    // lang = 'en'
      if(!lang)
          lang = this.state.lang;

      if(typeof text === STRING){
          return text;
      }

      if(typeof text === OBJECT){
          if(lang && text[lang]){ 
              return text[lang];
          }
          else{
              console.error('lang missing');
              return ''
          }
      }

      return '';
  }

  componentDidUpdate(prevProps){
    let props = this.props;
    if(prevProps.lang !== props.lang){
      this.setState({lang:props.lang||'fr'})
    }
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }

  warningToggle() {
    this.setState((prevState) => ({
      warningModal: !prevState.warningModal,
    }));
  }

  renderLawyers(list) {
    // console.log('are?')
    let arr = list.map((e) => {
      return {
        province:e.province||'QC',
        value: e.auth_id,
        label: e.firstname + "." + e.lastname,
        color: e.color || "#ffffff",
      };
    });

    arr.push({
      province:'QC',
      value: ELAWYER,
      label: this.genLang({fr:"externes",en:"elawyers"}),
      color: "#040404",
    });

    arr.push({
      province:'QC',
      value: LAWYER,
      label: this.genLang({fr:"Avocats",en:"lawyers"}),
      color: "#FFD700",
    });

    arr.push({
      province:'on',
      value: ONT_ELAWYER,
      label: this.genLang({fr:"externes de l\'ontario",en:"lawyers ontario"}),
      color: "#FFD700",
    });
    
    return arr;
  }

  render() {
    
    let lawyers = [];
    let str = "";
    for (let o in this.props.userList) {
      str = this.props.userList[o].firstname + " ";
      if (this.props.userList[o].lastname)
        str += this.props.userList[o].lastname;

      lawyers.push(
        <option
          key={o + "-" + this.props.userList[o].auth_id}
          value={this.props.userList[o].auth_id}
        >
          {str}
        </option>
      );
    }

    return (
      <>
        <Modal
          isOpen={this.state.warningModal}
          toggle={this.warningToggle}
          autoFocus={false}
        >
          <ModalHeader toggle={this.warningToggle}>Permanent</ModalHeader>
          <ModalBody>
            <p style={{ textAlign: "center" }}>
              Êtes-vous certain de vouloir sauvegarder la semaine de façon{" "}
              <strong> permanente ?</strong>
            </p>
          </ModalBody>
          <ModalFooter>
            <Button autoFocus color="secondary" onClick={this.warningToggle}>
              Non merci
            </Button>
            <Button
              color="success"
              onClick={() => {
                this.props.saveCalendar(false);
                this.warningToggle();
              }}
            >
              {" "}
              Oui{" "}
            </Button>
          </ModalFooter>
        </Modal>
        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>Vider</ModalHeader>
          <ModalBody>
            Si vous désirez vider le calendrier au complet laissez la valeur à
            Tous Sinon, sélectionnez un avocat afin de réinitialiser ses heures.
            <strong>
              En ce moment:{" "}
              {this.props.initials(
                _.find(this.props.userList, {
                  auth_id: this.props.editedLawyer,
                })
              )}{" "}
              est ciblé par cette mesure.
            </strong>
            <FormGroup>
              <Label for="addLawyer">Select</Label>
              <Input
                type="select"
                name="addLawyer"
                id="addLawyer"
                ref="addLawyer"
                value={this.props.editedLawyer}
                onChange={(e) => {
                  this.props.setEditedLawyer(e);
                }}
              >
                {lawyers}
              </Input>
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button color="warning" onClick={() => this.props.emptyCalendar()}>
              {" "}
              Retirer
            </Button>{" "}
            {/* <Button color="danger" onDoubleClick={() => this.props.()}> Annihiler </Button>{' '} */}
            <Button color="secondary" onClick={this.toggle}>
              Non merci
            </Button>
          </ModalFooter>
        </Modal>
        <h1>
          #{this.props.weekId},{" "}
          {this.props.weekMonday.clone().add(3, "days").format("MMMM YYYY")}{" "}
          {this.state.calendarType === SALES
            ? " - Calendrier des ventes"
            : this.genLang({fr:" - Calendrier des avocats",en:'- Calendar -'})
          }
        </h1>
        <ButtonGroup>
          <Button
            color="neo-blue"
            onClick={() => this.props.handleWeek({ previous: true })}
          >
            «
          </Button>
          <Select
            id="weekSearch"
            className={"button-select"}
            onChange={(e) => this.props.handleWeek(e)}
            options={this.props.weekList}
            defaultValue="Cette semaine"
            name="week"
            isSearchable
            placeholder={this.genLang({fr:"cette semaine",en:'This week'})}
          />
          <Button
            color="neo-blue"
            onClick={() => this.props.handleWeek({ next: true })}
          >
            »
          </Button>
          {/* <Button color={"neo-blue"} title={"mon horaire"} >                                 */}
          {this.props.dispo ? (
            <Select
                id="category"
                className={"button-select"}
                onChange={(e) => this.props.handleCategory(e)}
                options={_.map(this.props.categories,(e) => {
                        return {
                            value:e.id,
                            label:e.name
                        }
                    })
                }
                defaultValue="Default"
                name="category"
                isSearchable
                placeholder={this.genLang({fr:"Défaut",en:'Default'})}
            />
            ) : (
            ""
          )}
          
          <Link
            to={"/schedule"}
            style={{ color: "#FFF" }}
            title="mon horaire"
            className="btn btn-neo-blue"
          >
            <FontAwesomeIcon icon={faBusinessTime} />
          </Link>
          {this.props.admin ? (
            <Link
              to={"/factures"}
              style={{ color: "#FFF" }}
              title="factures"
              className="btn btn-neo-blue"
            >
              <FontAwesomeIcon icon={faMoneyBill} />
            </Link>
          ) : (
            ""
          )}
          {this.props.admin ? (
            <Link
              to={"/groups"}
              style={{ color: "#FFF" }}
              title="factures"
              className="btn btn-neo-blue"
            >
              <FontAwesomeIcon icon={faCogs} />
            </Link>
          ) : (
            ""
          )}
          {/* </Button> */}
          {/* <Button color="warning" onClick={() => this.props.switchCalendar() }> Changer </Button> */}
          {this.props.dispo ? (
            <Button id="vider" color="danger" onClick={() => this.toggle()}>
              <UncontrolledTooltip placement={"top"} target={"vider"}>
                {"Vider"}
              </UncontrolledTooltip>
              <FontAwesomeIcon icon={faTrash} />
            </Button>
          ) : (
            ""
          )}
          {this.props.dispo ? (
            <Button
              id="temporaire"
              color="neo-orange"
              onClick={() => this.props.saveCalendar(true)}
            >
              <UncontrolledTooltip placement={"top"} target={"temporaire"}>
                {" Sauvegarde temporaire"}
              </UncontrolledTooltip>
              <FontAwesomeIcon icon={faSave} />
            </Button>
          ) : (
            ""
          )}
          {this.props.dispo ? (
            <Button
              id="permanent"
              color="info"
              onClick={() => this.warningToggle()}
            >
              <UncontrolledTooltip placement={"top"} target={"permanent"}>
                {" Sauvegarde permanente"}
              </UncontrolledTooltip>
              <FontAwesomeIcon icon={faObjectUngroup} />
            </Button>
          ) : (
            ""
          )}


        </ButtonGroup>
        <div>
          <FormGroup id="lawyerList">
            <Input
              disabled={this.props.dispo ? false : true}
              type="select"
              name="addLawyer"
              id="addLawyer"
              ref="addLawyer"
              value={this.state.editedLawyer}
              onChange={(e) => {
                this.props.setEditedLawyer(e);
              }}
            >
              {lawyers}
            </Input>
          </FormGroup>

          <Select
            id="lawyerListDisplay"
            onChange={(e) => this.props.handleSelect(e)}
            options={this.renderLawyers(this.props.userList)}
            // defaultValue={{value:-1,label:'Tous'}}
            name="avocats"
            isClearable
            isSearchable
            isMulti
            placeholder={this.props.userList[0].firstname}
          />
        </div>
      </>
    );
  }
}
