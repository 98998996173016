import React, {Component} from 'react';
import {
    Col,
    Card,
    CardHeader,
    CardBody,
    Nav, 
    NavItem,
    NavbarBrand,
    NavLink,
    Collapse
} from 'reactstrap';

import _ from 'lodash';

// class CollapseContent extends Component(){
//     constructor(props){
//         super(props);
//     }
    
//     render(){
//         return(<NavItem>
//             <NavLink 
//                 style={{marginLeft:'15px'}}
//                 active={this.state.active===current.target?true:false}
//                 tag={'span'}
//                 color={'primary'} 
//                 data-target={current.target}
//                 onClick={(e) => this.handleNav(e.currentTarget.attributes['data-target'].value)}
//             >
//                 {current.name}
//             </NavLink>
//         </NavItem>)
//     }
// }

class DashboardElement extends Component{

    constructor(props){
        super(props);
        let state= {};
        // {name:'tarif',target:manage}

        state = {
            active:props.defaultActive
        };

        // let collapse = '';
        // if(props.collapse && props.collapse.length){
        //     collapse = [];
        //     let current = '';
        //     for(let c in props.collapse){
        //         current = props.collapse;
        //         collapse.push(<Collapse isOpen={this.state.active === current.target}>
        //             {current.component}
        //         </Collapse>)
        //     }  
        
        //     this.collapse = collapse;
        // }
        
        this.state = state;
        this.handleNav = this.handleNav.bind(this);
    }

    handleNav(active){
        this.setState({'active':active})
    }

    render(){
        let props = this.props
        let nav = '';
        if(props.nav && props.nav.length){
            nav = [ ];
        
            nav = <Nav pills>{
                [<NavItem
                className="brand" 
                key={'test'}
                ><NavbarBrand tag={'span'} 
                    // style={{'verticalAlign':'text-top'}}
                >
                {props.title}</NavbarBrand>
                </NavItem>,
                ..._.map(this.props.nav, (e) => {
                return <NavItem key={'nav-'+e.target}>
                    <NavLink 
                        className={'m-l-5 p-t-5 p-b-5'}
                        active={e.target === this.state.active}
                        tag={'span'}
                        color={'primary'} 
                        data-target={e.target}
                        onClick={(f) => {
                            this.handleNav(f.target.getAttribute("data-target"))
                        }}
                    >
                        {e.name}
                    </NavLink>
                </NavItem>
            })
            ]}</Nav>;
        }

        let collapse
        if(props.collapse && props.collapse.length){
            collapse = [];
            collapse = _.map(this.props.collapse, (g) => {
            return <Collapse  key={'collapse-'+g.target} isOpen={g.target === this.state.active}>{g.component}</Collapse>
            });
        }

        let header = nav||this.props.title||'titre manquant';
        return (
            <Col md={this.props.col||6} style={{
                height:this.props.height||'initial',
                maxHeight:this.props.maxHeight||'initial',
                overflow:'hidden'
                }}>
                <Card className={(this.props.className)?"neo-card "+this.props.className:"neo-card"}>
                    <CardHeader tag="h3"  id={this.props.toggler} className={'cardheader padding-10'}>
                        {header}
                    </CardHeader>
                    <CardBody className={'cardbody'}>
                        {collapse?collapse:this.props.children}
                    </CardBody>
                </Card>
            </Col>)
    }
}

export default DashboardElement;