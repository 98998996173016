import React, {Component} from 'react';
import {
    Button,
    Form,
    FormGroup,
} from 'reactstrap';
import Select from 'react-select';

import rootsActions from '../../_actions/root-actions';
import _ from 'lodash';
import {adminAxios as axios} from '../../utils/axios';
import Alert from '../utils/Alert'
import { connect } from 'react-redux';
const list = 'list';
// const manage = 'manage'

const PRIMARY = 'primary';
const DANGER = 'danger';
const SUCCESS = 'La liste a été modifié avec succès';
const ERROR = 'Une erreur est survenue, la liste n\'a pas été mise à jour';
const ERRORCANTEDIT = 'La spécialité «autre» cible tout le monde; impossible de la sélectionner';

class Specialities extends Component{
    constructor(props){
        super(props);

        this.state = {
            alert:false,
            alertColor:PRIMARY,
            alertMessage:'',
            users:[],
            specialities:[],
            group:0,
            tarif:list
        }

        this.onChange = this.onChange.bind(this);
        
        this.handleSubmit = this.handleSubmit.bind(this);
        
    }

    onChange(e){
        this.setState({
            users:e
        })
    }

    componentDidUpdate(prevProps){
        let obj = {};

        if(JSON.stringify(prevProps.specialities)!==JSON.stringify(this.props.specialities)){
            obj.specialities = this.props.specialities
        }

        if(JSON.stringify(prevProps.types)!==JSON.stringify(this.props.types)){
            obj.types = this.props.types
        }

        if(JSON.stringify(prevProps.userList)!==JSON.stringify(this.props.userList)){
            obj.userList = [{auth_id: -1,firstname:"Tous",lastname:' '},...this.props.userList];
        }

        if(_.keys(obj).length)
            this.setState(obj);
    }
    
    handleSubmit(e){

        let state = this.state;
        
        this.props.toggleLoading();

        let uID;

        if(!uID){
          let token = window.parseJwt(localStorage.getItem('token'));
          
          uID=token.sub  
        }    

        axios
        .post("/specialities", {
            auth_id:uID,
            type:state.type,
            speciality:state.speciality,
            members:_.map(state.users,(u) => parseInt(u.value))
        })
        .then(
            (status) =>{
                if( status.status === 200){
                    
                    let index = _.findIndex(this.props.specialities,{id:state.speciality});
                    let arr = [...this.props.specialities];

                    arr[index].users = _.map(state.users,(u) => parseInt(u.value));

                    this.props.init({
                        params:{
                            specialities:arr
                        }
                    })
                    this.setState({
                        alert:true,
                        alertColor:PRIMARY,
                        alertMessage:SUCCESS,
                    })
                }
                else{
                    
                    console.log('err',status.status);
                    this.setState({
                        alert:true,
                        alertColor:DANGER,
                        alertMessage:ERROR,
                    },() => console.log(ERROR,this.state))
                }
                this.props.toggleLoading()
                // if(status.statusCode===200){
                // }
            },
            (err) =>{
                this.props.toggleLoading()
                console.log('err',err);
                this.setState({
                    alert:true,
                    alertColor:DANGER,
                    alertMessage:ERROR,
                },() => console.log(ERROR,this.state))
            },
        )
    }

    // componentDidMount(){

    //     if(!localStorage.getItem('lawyerData')){ 
    //         axios.get(
    //             'lawyers',
    //             {params:{auth_id:localStorage.getItem('user_id')}}
    //         )
    //         .then(this.lawyerCallback);
    //     }
    //     else{
    //         this.lawyerCallback(JSON.parse(localStorage.getItem('lawyerData')))
    //     }
    // }

    // lawyerCallback(response){
    //     if( response && response.data && response.data.data[0]){

    //         localStorage.setItem('lawyerData',JSON.stringify(response));
            
    //         this.setState({
    //             userList:[{auth_id: -1,firstname:"Tous",lastname:' '},...response.data.data[0].lawyers],
    //             types:[...response.data.data[0].types],
    //             specialities:[...response.data.data[0].specialities],
    //             durations:[...response.data.data[0].durations]
    //         });
    //     }
    // }
    
    render(){
        
        return (
            <Form>
                <FormGroup>
                    Services 
                    <Select
                    onChange={(e) => {
                        
                        if(!e)
                            console.le.log(e,' has no specialities')
                        let speciality = _.find(this.state.specialities, { id: e.speciality });

                        if(!speciality){
                            return this.setState({
                                alert:true,
                                alertColor:DANGER,
                                alertMessage:ERRORCANTEDIT,
                            });
                        }
                        let arr = _.filter(this.state.userList, (elt) => {
                            return speciality.users.find((e) => {
                                return e === parseInt(elt.auth_id);
                            });
                        });

                        arr = _.map(arr, (e) => {
                        return {
                            value: e.auth_id,
                            label: e.firstname + "." + e.lastname,
                            name: "user",
                        };
                        });

                        this.setState({
                            speciality: speciality.id,
                            type: e.value,
                            users: arr,
                        });
                    }}
                    options={_.map(this.state.types, (s) => {
                        return {
                        label: s.title,
                        value: s.id,
                        speciality: s.specialities[0].id,
                        };
                    })}
                    name="Groups"
                    isClearable
                    isSearchable
                    />
                </FormGroup>
                <FormGroup>
                    membres
                    <Select
                    onChange={this.onChange}
                    options={_.map(this.state.userList, (u) => {
                        return {
                        label: u.firstname + "." + u.lastname,
                        value: u.auth_id,
                        name: "user",
                        };
                    })}
                    value={this.state.users}
                    name="Lawyers"
                    isClearable
                    isMulti
                    isSearchable
                    />
                </FormGroup>
                <FormGroup>
                    <Button
                    color={"primary"}
                    onClick={this.handleSubmit}
                    type="button"
                    size="lg"
                    className={"full-width m-t-10"}
                    >
                    Enregistrer
                    </Button>
                </FormGroup>
                <Alert 
                    visible={this.state.alert}
                    color={this.state.alertColor}
                    message={this.state.alertMessage}
                />

                
            </Form>)
    }
}

const mapActionsToProps = {
    setLawyers: rootsActions.usersActions.setLawyers,
    init:rootsActions.usersActions.initUsersData,
    toggleLoading:rootsActions.loadingActions.toggleLoading,
}

const mapStateToProps = state => ({
    userList:state.users.userList,
    specialities:state.users.specialities,
    types:state.users.types
});

export default connect(mapStateToProps,mapActionsToProps)(Specialities)