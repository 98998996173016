import React from "react";
// import {FormattedMessage, injectIntl} from 'react-intl';

class ErrorList extends React.Component{
    constructor(props){
        super(props);
        this.state = {};
    }

  render() {
        
        let template = <div></div>,
            errors = '';
        
        for(let e in this.props.errors)
            errors+= this.props.errors[e] + ', ';
            
        if(!!errors){

            errors = errors.substring(0, errors.length - 2);
            let str = 'et';
            errors = errors.replace(/,(?!.*,)/,str);
        }
        
        if(!!this.props.errors&&!!Object.keys(this.props.errors).length&&!this.props.logoutError){
            template = (
                <div className="error-list" >
                    <h5>
                        Ces champs comportents des erreurs, vérifiez-les s'il vous plaît
                    </h5>
                    
                    <span className="capitalize">
                        les champs erronnés sont: 
                    </span>
                </div>
            )
        }

        else if(!!this.props.errors&&this.props.logoutError){
            template = (
                <div className="error-list" >
                    <h5>
                        Erreur de connexion
                    </h5>
                </div>
            )
        }

        return (template)
  }
}

export default ErrorList;