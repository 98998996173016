import React, { useState,useRef} from 'react';
import { privateAxios as axios } from "../utils/axios";

import InputMask from 'react-input-mask';

import { 
    Form,
    FormGroup,
    Label,
    Input,
    Alert,
    Button,
    Row,
    Col,
    Card,
    CardBody,
    CardTitle
} from 'reactstrap';
import SignaturePad from 'react-signature-pad-wrapper'
// const LAWYER = "lawyer";
// const ELAWYER = "elawyer";
// const SALES = "sales";
const NEOPHONE = '(855) 996-9695';
const DANGER = 'danger';
const PRIMARY = 'primary';
const NONE = 'none';
const BLOCK = 'block';
  
const logo = require('../assets/img/brand/neolegal.png');
function NewLawyer() {
    const [lastname, setLastname] = useState('');
    const [firstname, setFirstname] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [cell, setCell] = useState(NEOPHONE);
    const [alertColor, setAlertColor] = useState(PRIMARY);
    const [alert, setAlert] = useState('');

    var signaturePad = useRef(null);
    
    function handleSubmit(){
        axios
            .post("/user", 
                {
                    auth_id:localStorage.getItem('user_id'),
                    lastname:lastname,
                    firstname:firstname,
                    sign:signaturePad.toDataURL(),
                    password:password,
                    email:email,
                    cell:cell,
                }
            )
            .then(
                (status) =>{
                    console.log(status);
                    
                    setAlertColor(PRIMARY);
                    setAlert(firstname+' '+lastname +' a bel et bien été ajouté')
                    setLastname('')
                    setFirstname('')
                    setEmail('')
                    setPassword('')
                    setCell(NEOPHONE)
                },
                (err) =>{
                    console.log(err);
                    setAlert(DANGER);
                    setAlert('Erreur du serveur');
                    setAlertColor(DANGER)
                },
            )
        
    }

  return (
    <div className="main-content login">
    <div className="header bg-gradient-info py-5 py-lg-5">
      {/* <Container > */}
          
        <div className="header-body text-center ">
            <img src={logo} width="300px;" alt="Neolegal"></img>
        </div>
        <Row>
            <Col md={{ size: 4, offset: 4 }}>
                <Card>
                    <CardBody>
                        <div style={{display:!!alert?BLOCK:NONE}}>
                            <Alert color={alertColor}>
                                {alert}
                            </Alert>
                        </div>
                        
                        <CardTitle>Nouveau compte</CardTitle>
                        <Form>
                            <FormGroup>
                                <Label for="firstname">Prénom</Label>
                                <Input type="text" value={firstname} name="firstname" id="firstname"  onChange={ (e) => setFirstname(e.target.value)} />
                            </FormGroup>
                            <FormGroup>
                                <Label for="lastname">Nom</Label>
                                <Input type="text" value={lastname} name="lastname" id="lastname" onChange={ (e) => setLastname(e.target.value)}  />
                            </FormGroup>
                            
                            {/* <FormGroup>
                                <Label for="address">Adresse</Label>
                                <Input type="text" value={address} name="address" id="address" onChange={ (e) => setAddress(e.target.value)}  />
                            </FormGroup>
                            
                            <FormGroup>
                                <Label for="city">Ville</Label>
                                <Input type="text" value={city} name="city" id="city" onChange={ (e) => setCity(e.target.value)}  />
                            </FormGroup>
                            
                            <FormGroup>
                                <Label for="province">Province</Label>
                                <Input type="text" value={province} name="province" id="province" onChange={ (e) => setProvince(e.target.value)}  />
                            </FormGroup>
                            
                            <FormGroup>
                                <Label for="lastname">code postal</Label>
                                <Input type="text" value={cp} name="lastname" id="lastname" onChange={ (e) => setLastname(e.target.value)}  />
                            </FormGroup> */}

                            <FormGroup>
                                <Label>Téléphone</Label>
                                <InputMask 
                                    className={"form-control"}
                                    value={cell}
                                    id="cell"
                                    mask={"(999) 999-9999"} 
                                    maskChar=" " 
                                    onChange={(e) => setCell(e.target.value)} 
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for="email">Email</Label>
                                <Input autocomplete="new-username" type="email" value={email} name="email" id="email" onChange={ (e) => setEmail(e.target.value)}  />
                            </FormGroup>
                            <FormGroup>
                                <Label for="password">Mot de passe (si vide, ce sera celui par défaut)</Label>
                                <Input autoComplete="new-password" type="password" value={password} name="password" id="password" onChange={ (e) => setPassword(e.target.value)}  />
                            </FormGroup>
                            <FormGroup>
                                <Label for="file">Signature</Label>
                                {/* <Dropzone
                                    maxSize={500000}
                                    accept={'image/*'} 
                                    handleFile={setSign}
                                /> */}
                                <SignaturePad
                                    ref={ref => signaturePad = ref}
                                    options={{
                                        width: 300,
                                        clear:true
                                    }}
                                />
                            </FormGroup>
                            <FormGroup className={"text-center"}>
                                <Button color={"primary"} type={"button"} onClick={ handleSubmit } >soumettre</Button>
                            </FormGroup>
                        </Form>
                    </CardBody>
                </Card>
            </Col>
        </Row>
        
      {/* </Container> */}
      </div>
      </div>
  )
}

export default NewLawyer;