import React, { Component } from "react";

import { Link } from "react-router-dom";
import chroma from "chroma-js";
import Calendar from "@toast-ui/react-calendar";
import "tui-calendar/dist/tui-calendar.css";

// If you use the default popups, use this.
// import 'tui-date-picker/dist/tui-date-picker.css';
// import 'tui-time-picker/dist/tui-time-picker.css';
import LeaveModal from "./LeaveModal";

import { connect } from "react-redux";

import rootsActions from "../_actions/root-actions";

import _ from "lodash";
import { ButtonGroup, Button } from "reactstrap";

import { privateAxios as axios } from "../utils/axios";

import moment from "moment-timezone";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faCalendar } from "@fortawesome/free-solid-svg-icons";

const dot = (color = "#ccc") => ({
  alignItems: "center",
  display: "flex",
  paddingLeft: "5px",
  ":before": {
    backgroundColor: color,
    borderRadius: 10,
    content: '" "',
    display: "block",
    marginRight: 8,
    height: 10,
    width: 10
  }
});

const colourStyles = {
  control: styles => ({ ...styles, backgroundColor: "white" }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected
        ? data.color
        : isFocused
        ? color.alpha(0.5).css()
        : null,
      color: isDisabled
        ? "#ccc"
        : isSelected
        ? chroma.contrast(color, "white") > 2
          ? "white"
          : "black"
        : "#333",
      cursor: isDisabled ? "not-allowed" : "default",

      ":active": {
        ...styles[":active"],
        backgroundColor:
          !isDisabled && (isSelected ? data.color : color.alpha(0.8).css())
      }
    };
  },
  multiValue: (styles, { data }) => {
    //   const color = chroma(data.color);
    return {
      ...styles,
      ...dot(data.color),
      color: "#333 !important"
      // backgroundColor: color.alpha(0.4).css(),
    };
  },
  multiValueLabel: (styles, { data }) => {
    // const color = chroma(data.color);
    return {
      ...styles,
      color: "#333 !important",
      // backgroundColor: color.alpha(0.1).css(),
      paddingLeft: "5px"
    };
  },
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: data.color,
    ":hover": {
      backgroundColor: data.color,
      color: "white"
    }
  })
};

const START_DATE = "startDate";
const END_DATE = "endDate";

class ScheduleTimer extends Component {
  constructor(props) {
    super(props);

    let ok = this.defaultVisible();
    this.state = {
      visible: ok,
      focusedInput: props.autoFocusEndDate ? END_DATE : START_DATE,
      calendarUsers: [],
      modal: false,
      startDate: new Date(),
      endDate: new Date(),
      startTime: "",
      endTime: "",
      editedLawyer: 0,
      leaveExplaination: "",
      leaveReason: 0
    };

    this.newAppointment = this.newAppointment.bind(this);
    
    // setInterval(() => this.newAppointment() ,5000);
    setInterval(() => this.newAppointment() ,1000*60*10);
    this.handleWeek = this.handleWeek.bind(this);
    this.convertTakenIntoCalendar = this.convertTakenIntoCalendar.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.onDatesChange = this.onDatesChange.bind(this);
    this.onFocusChange = this.onFocusChange.bind(this);
    this.addVacation = this.addVacation.bind(this);
    this.generateCalendarUsers = this.generateCalendarUsers.bind(this);
  }

  calendarRef = React.createRef();

  componentDidUpdate(prevProps) {
    if (!_.isEqual(this.props.visible.sort(), prevProps.visible.sort())) {
      this.setState({
        schedule: this.convertTakenIntoCalendar(
          this.state.appointments,
          this.takenList()
        )
      });
    }
  }

  takenList(){
    return _.map(this.props.work, e => {
      return { id: e.id, type: e.type };
    });
  }

  newAppointment(){
    // let params = {
    //   auth_id: localStorage.getItem("user_id")
    // };

      axios
      .get("availabilities", {
          params: {
          default: false,
          week: this.props.weekId,
          year: this.props.year,
          // user:this.state.editedLawyer,
          // ticket_id:52583,
          auth_id: localStorage.getItem("user_id")
          }
      })
      .then(response => {
        if (response && response.data) {

          let arr = [],
              av = response.data.data.taken;

          for (let a in av) {
              for (let t in av[a].taken) {
              arr.push({
                  ...av[a].taken[t],
                  user: av[a].user
              });
              }
          }
          let userId = parseInt(localStorage.getItem("user_id"));
          // let userId = 7962;
          if(_.filter(arr,{user:userId}).length!==_.filter(this.state.appointments,{user:userId}).length){
            
            this.setState({
              wData:av,
              loading: false,
              availabilities: response.data.data.availabilities,
              appointments: arr,
              error: false,
              deleteAppointment: false,
              notification:setInterval(() => {
                if(document.title !== 'Nouveau RDV!!'){
                  document.title = 'Nouveau RDV!!';
                }
                else
                  document.title = '🔔'
              },1000),
              schedule: this.convertTakenIntoCalendar(
                  arr,
                  this.takenList()
              )
            },() => {
                const calendarInstance = this.calendarRef.current.getInstance();
                calendarInstance.setDate(
                    this.props.weekMonday
                        .clone()
                        .add(1, "d")
                        .utc()._d
                );
              }
            );

          }//if
          
        }
      });
  }

  componentDidMount() {

    
    let params = {
        auth_id: localStorage.getItem("user_id")
    };

    axios.get("lawyers", { params: params }).then(response => {
      localStorage.setItem("lawyerData", JSON.stringify(response));
      
      let users = response.data.data[0].lawyers;
      this.handleSelect(null);
      if (response && response.data && response.data.data) {

        this.props.setLawyers({
          params: {
            lawyers: [
              {
                auth_id: -1,
                firstname: "Tous",
                lastname: " ",
                color: "#545454"
              },
              ...users
            ],
            
            work:response.data.data[0].types
          }
        });

        axios
        .get("availabilities", {
            params: {
            default: false,
            week: this.props.weekId,
            year: this.props.year,
            // user:this.state.editedLawyer,
            // ticket_id:52583,
            auth_id: localStorage.getItem("user_id")
            }
        })
        .then(response => {
            if (response && response.data) {

                let arr = [],
                    av = response.data.data.taken;
    
                for (let a in av) {
                    for (let t in av[a].taken) {
                    arr.push({
                        ...av[a].taken[t],
                        user: av[a].user
                    });
                    }
                }
    
                this.setState({
                    wData:av,
                    loading: false,
                    availabilities: response.data.data.availabilities,
                    appointments: arr,
                    error: false,
                    deleteAppointment: false,
                    calendarUsers: this.generateCalendarUsers(users),
                    schedule: this.convertTakenIntoCalendar(
                        arr,
                        this.takenList()
                    )
                },() => {
                    const calendarInstance = this.calendarRef.current.getInstance();
                    calendarInstance.setDate(
                        this.props.weekMonday
                            .clone()
                            .add(1, "d")
                            .utc()._d
                    );
                });
                } else {
                this.setState({
                    deleteAppointment: false,
                    loading: false,
                    availabilities: [],
                    error: (
                    <>
                        <div>
                        Erreur, faîtes la tâche manuellement,<br></br>{" "}
                        puis avisez un IT
                        </div>
                    </>
                    )
                });
                }

        });

      }
    });
  }

  generateCalendarUsers(lawyers) {
    // if(localStorage.getItem('admin')=== 'false'){
    //     lawyers = _.filter(lawyers,{'auth_id':localStorage.getItem('user_id')});
    // }

    let schedule1 = _.map(lawyers, function(e) {
      return {
        id: e.auth_id,
        name: e.firstname + " " + e.lastname,
        bgColor: e.color,
        borderColor: e.color
      };
    });
    // return schedule1
    let self = this;
    let schedule2 = _.map(lawyers, function(e) {
      return {
        id: "clientRDV_" + e.auth_id,
        name: e.firstname + " " + e.lastname,
        bgColor: self.darker(e.color),
        borderColor: e.color
      };
    });
    return schedule1.concat(schedule1, schedule2);
    // .unshift({id:"0",name:'tous',color:""});
  }

  darker(color) {
    color = color.substr(1);

    let colors = color.match(/.{1,2}/g);

    for (let c in colors) {
      colors[c] = parseInt(colors[c], 16);
      colors[c] -= 60;
      if (colors[c] < 0) colors[c] = 0;
      colors[c] = Number(colors[c]).toString(16);
      if (colors[c].length === 1) colors[c] = "0" + colors[c];
    }

    return "#" + colors.join("");
  }

  defaultVisible() {
    return localStorage.getItem("admin") === "true"
      ? [{ value: -1, label: "Tous" }]
      : [
          {
            value: localStorage.getItem("user_id"),
            label: "Moi"
          }
        ];
  }
  convertTakenIntoCalendar(takens, takenList) {
    // if(localStorage.getItem('admin')=== 'false'){
    //     takens = _.filter(takens,{'user':parseInt(localStorage.getItem('user_id'))});
    // }

    if (this.props.visible && this.props.visible[0].value !== -1) {
      takens = _.filter(takens, f => {
        return _.find(this.props.visible, { value: f.user + "" });
      });
    }
    
    var self = this;
    let arr = _.map(takens, function(e) {
      let body = "",
        user = _.find(self.props.userList, { auth_id: e.user + "" });

      // if(!user||!user.firstname){
      //     debugger;
      // }
      // body += user.firstname +' '+user.lastname+'<br>'
      // body += '<i class="fa fa-coffee"></i>';
      body +=
        e.client && e.client.details
          ? '<i class="fas fa-tags"></i> <b>' + e.client.details + "</b><br>"
          : "";
      body +=
        e.client && e.client.name
          ? '<i class="fas fa-user"></i> ' + e.client.name + "<br>"
          : "";
      // body += e.service+"<br>";
      body += (e.app_description.trim())?"<i class=\"fas fa-comment\"></i> "+e.app_description+"":"";

      body += "<hr>";
      body += e.ticket_id
        ? '<a href="https://neolegal.zendesk.com/agent/tickets/' +
          e.ticket_id +
          '" target="_blank" class="ticket-link"><i class="fas fa-link" title="Aller sur le ticket"></i> ' +
          e.ticket_id +
          "</a>"
        : "";

      let start = moment(e.start),
        end = moment(e.end);
      let isAllDay = end.diff(start, "hours") > 12;
      if (isAllDay) {
        body += "<div>" + e.app_description + "</div>";
      }

      let str = "";

      str += user
        ? user.firstname + " " + user.lastname
        : "Utilisateur inconnu";
      str += e.service ? ", " + e.service : ", Action inconnue";
      str += e.ticket_id ? " #" + e.ticket_id : "";

      let withCustomer = false;

      let found = _.find(takenList, { id: parseInt(e.service_id) });
      if(!found)
        console.log(e.service_id)
      if (
        e.service_id < 999 &&
        takenList &&
        found &&
        found.type === 0
      )
        withCustomer = true;

      return {
        id: e.id,
        calendarId: withCustomer ? "clientRDV_" + e.user : e.user,
        title: str,
        start: e.start,
        end: e.end,
        category: "time",
        body: body,
        isAllDay: isAllDay,
        onupdateSchedule: e => {
          console.log("are??", e);
        }
      };
    });

    // arr.push({
    //     id:'000000',
    //     calendarId:"38",
    //     title:'jay',
    //     start:"2019-11-27T13:00:00+00:00",
    //     end:"2019-11-27T20:00:00+00:00",
    //     isAllDay:true,
    //     category:'allday',
    //     // body:'<strong>DAMELEON</strong>',
    //     isReadOnly:true
    // });

    return arr;
  }

  handleSelect(e) {
    // e = _.map(e,'value');

    if (!e || !e.length || !e[0]) {
      e = this.defaultVisible();
    }

    this.props.setVisible({
      params: {
        visible: e
      }
    });
  }

  handleWeek(e) {
    const calendarInstance = this.calendarRef.current.getInstance();
    // enlever le 1er if si non necessaire
    // sunday = (difference>0)?
    let m = moment(e.start);
    if (e.next) {
      m = this.props.weekMonday.clone().add(1, "w");
      this.props.setWeek({
        week: m.isoWeek(),
        year: m.isoWeekYear(),
        monday: m,
        isDST: m.isDST()
      });
    } else if (e.previous) {
      m = this.props.weekMonday.clone().subtract(1, "w");
      this.props.setWeek({
        week: m.isoWeek(),
        year: m.isoWeekYear(),
        monday: m,
        isDST: m.isDST()
      });
    } else if (e) {
      this.props.setWeek({
        week: e.value,
        year: e.year,
        monday: m,
        isDST: m.isDST()
      });
    }

    axios
      .get("/availabilities", {
        params: {
          default: false,
          week: e.value || m.isoWeek(),
          year: e.year || m.isoWeekYear(),
          user: this.state.editedLawyer,
          auth_id: localStorage.getItem("user_id")
        }
      })
      .then(response => {
        if (response && response.data) {
          let arr = [],
            av = response.data.data.taken;

          for (let a in av) {
            for (let t in av[a].taken) {
              arr.push({
                ...av[a].taken[t],
                user: av[a].user
              });
            }
          }

          this.setState(
            {
              wData: av,
              loading: false,
              schedule: this.convertTakenIntoCalendar(arr, this.takenList()),
              availabilities: response.data.data.availabilities,
              appointments: arr,
              error: false,
              deleteAppointment: false
            },
            () => {
              calendarInstance.setDate(
                this.props.weekMonday
                  .clone()
                  .add(1, "d")
                  .utc()._d
              );
            }
          );
        }
      });
  }

  onDatesChange({ startDate, endDate }) {
    this.setState({ startDate, endDate });
  }

  onFocusChange(focusedInput) {
    this.setState({ focusedInput: !focusedInput ? START_DATE : focusedInput });
  }

  addVacation() {
    // dispatch({
    //     type:rootsActions.usersActions.TOGGLE
    // });
  }

  render() {
    var theme = {
      "common.border": "1px solid #e5e5e5",
      "common.backgroundColor": "white",
      "common.holiday.color": "#ff4040",
      "common.saturday.color": "#333",
      "common.dayname.color": "#333",
      "common.today.color": "#333"
    };

    return (
      <>
        <LeaveModal />
        <h1>
          #{this.props.weekId},{" "}
          {moment(this.props.weekMonday._d)
            .add(3, "days")
            .format("MMMM YYYY")}{" "}
        </h1>
        <ButtonGroup>
          {this.state.notification?
            <Button
                color="neo-blue"
                onClick={() => {
                  clearInterval(this.state.notification);
                  document.title = 'neo admin';
                  this.setState({notification:undefined})
                }}
              >
              <span role="img" aria-label="bell" >🔔</span>
            </Button>:''}
          <Button
            color="neo-blue"
            onClick={() => this.handleWeek({ previous: true })}
          >
            «
          </Button>
          <Select
            id="weekSearch"
            className={"button-select"}
            onChange={e => this.handleWeek(e)}
            options={this.props.weekList}
            defaultValue="Cette semaine"
            name="week"
            isSearchable
            placeholder={"cette semaine"}
          />
          <Button
            color="neo-blue"
            onClick={() => this.handleWeek({ next: true })}
          >
            »
          </Button>
          {localStorage.getItem("admin") === "true" ? (
            <Button color="neo-orange" onClick={() => this.props.toggleModal()}>
              Ajouter un congé
            </Button>
          ) : (
            ""
          )}

          {/* <Button color={"neo-blue"} title={"Calendrier"}> */}
              <Link 
                className="btn btn-neo-blue"
                to={"/calendar"}
                title={"Calendrier"}
                style={{color: '#FFF'}}
            >
                <FontAwesomeIcon icon={faCalendar} />
            </Link>
          {/* </Button> */}
        </ButtonGroup>
        <div>
          <Select
            id="lawyerAppointmentDisplay"
            onChange={e => this.handleSelect(e)}
            options={this.props.userList.map(e => {
              return {
                value: e.auth_id,
                label: e.firstname + "." + e.lastname[0],
                color: e.color || "#ffffff"
              };
            })}
            styles={colourStyles}
            name="avocats"
            isClearable
            isSearchable
            isMulti
            placeholder={
              localStorage.getItem("admin") === "false" ? "Moi" : "Tous"
            }
          />
        </div>
        <Calendar
          ref={this.calendarRef}
          useDetailPopup={true}
          onupdateSchedule={e => {
            // console.log('there will be blood --->',e);
          }}
          taskView={false}
          isReadOnly={localStorage.getItem("admin") === "true" ? false : true}
          defaultView="week"
          theme={theme}
          week={{
            startDayOfWeek: 0,
            workweek: true,
            hourStart: 7,
            hourEnd: 20
          }}
          timezones={[
            {
              timezoneOffset: this.props.isDST ? 4 * 60 * -1 : 5 * 60 * -1,
              displayLabel: "joie",
              tooltip: "Ebola"
            }
          ]}
          calendars={this.state.calendarUsers}
          disableDblClick={true}
          disableClick={true}
          schedules={this.state.schedule}
          scheduleView
          time
          template={{
            allday(schedule) {
              return `${schedule.title}<i class="fa fa-refresh"></i>`;
            },
            alldayTitle() {
              return '<div class="journalierParent"><strong class="journalier" >Journalier</strong></div>';
            }
          }}
          onBeforeDeleteSchedule={({ schedule }) => {
            const calendarInstance = this.calendarRef.current.getInstance();

            calendarInstance.deleteSchedule(schedule.id, schedule.calendarId);

            axios
              .delete("availabilities", {
                params: {
                  id: schedule.id,
                  auth_id: localStorage.getItem("user_id")
                }
              })
              .then(e => {
                console.log(e, "omedetou gozaimasu");
              });
          }}
          // on={{
          //     beforeDeleteSchedule:() =>{
          //         console.log('hikari are')
          //         }
          //     }
          // }
        />
      </>
    );
  }
}

const mapStateToProps = state => ({
  toggle: state.users.toggle,
  userList: state.users.userList,
  work: state.users.work,
  weekList: state.time.weekList,
  weekId: state.time.currentWeek,
  year: state.time.currentYear,
  weekMonday: state.time.currentMonday,
  visible: state.users.visible,
  isDST: state.time.isDST
});

const mapActionsToProps = {
  setWeek: rootsActions.timeActions.setWeek,
  setLawyers: rootsActions.usersActions.setLawyers,
  setVisible: rootsActions.usersActions.setVisible,
  toggleModal: rootsActions.usersActions.toggleModal
};

export default connect(mapStateToProps, mapActionsToProps)(ScheduleTimer);
