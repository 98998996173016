import React, {Component} from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faEdit
} from '@fortawesome/free-solid-svg-icons';

// const Edit = <FontAwesomeIcon icon={faEdit} color={'blue'}/>
// import { BeatLoader } from 'react-spinners'; 

export default class TableIcon extends Component{

    render(){
        return (
            <div
                style={{cursor:'pointer'}} 
                onClick={
                (e) => {
                    if(this.props.callback)
                        this.props.callback();
                    else
                        console.error('callback is missing in TableIcon');
                }
            }>
                <FontAwesomeIcon 
                    icon={this.props.icon||faEdit}
                    color={this.props.color||'blue'} 
                    
                />
            </div>
        );
    }
}   

// const mapStateToProps = state => ({
//     loading:state.loading.loading
// });

// export default connect(mapStateToProps)(Loading);