export const NEO_CALENDAR = "ticket.customField:custom_field_360028127774";
export const CALENDAR_EVENT_TIME = "ticket.customField:custom_field_80617148";
export const CALENDAR_EVENT_TIME_ZONE = "ticket.customField:custom_field_80617168";
export const CALENDAR_EVENT_DATE ="ticket.customField:custom_field_80617128";
export const CALENDAR_EVENT_DURATION = "ticket.customField:custom_field_80617188";
export const CALENDAR_EVENT_LAWYER = "ticket.customField:custom_field_80617108";
export const LAWYER_IN_CHARGE = "ticket.customField:custom_field_360023465514";
export const SALE_BY = 'ticket.customField:custom_field_360000185447';
export const NEO_RDV = 'ticket.customField:custom_field_1260800287610';

export const TEXT = 'Texte';
export const MULTILIGNE = 'Texte multiligne';
export const LISTE_DEROULANTE = 'Liste';
export const CASE_A_COCHER = 'Case à cocher';
export const DATE = 'Date';
export const DECIMAL = 'Décimal';
export const NUMBER = 'nombres';
export const EMAIL = 'email';
export const TIME = 'time';

export const PHONE = 'phone';

export const FIELD_LIST = {
    "Email":{title:'Courriel',id:1000,"zendesk_id":"user.email",type:EMAIL,filled:false},
    'Sujet':{title:'Sujet',id:1,zendesk_id:'ticket.subject',type:TEXT,filled:false},
    'Description':{title:'Description',id:2,zendesk_id:'ticket.customField:description',type:MULTILIGNE,filled:false},
    'Statut':{title:'Statut',id:3,zendesk_id:'ticket.customField:status',type:LISTE_DEROULANTE,filled:false,
        list:[
            {value:'Nouveau',tag:'new',default:false},
            {value:'Ouvert',tag:'open',default:false},
            {value:'En Attente',tag:'pending',default:false},
            {value:'Résolu',tag:'solved',default:false},
            {value:'Clos',tag:'closed',default:false}
        ]
    },
    'Type':{title:'Type',id:4,zendesk_id:'ticket.type',type:LISTE_DEROULANTE,filled:false,
        list:[
            {value:'Tâches',tag:'task',default:false},
            {value:'Problèmes',tag:'problem',default:false},
            {value:'Incident',tag:'incident',default:false},
            {value:'Question',tag:'question',default:false}
        ]
    },
    'Priorité':{title:'Priorité',id:5,zendesk_id:'ticket.priority',type:LISTE_DEROULANTE,filled:false,
        list:[
            {value:'-',tag:'u0',default:true},
            {value:'Basse',tag:'low',default:false},
            {value:'Normale',tag:'normal',default:false},
            {value:'Elevée',tag:'high',default:false},
            {value:'Urgente',tag:'urgent',default:false}
        ]
    },
    // 'Groupe':{title:'Groupe',id:6,zendesk_id:'ticket.customField:custom_field_76369247',type:LISTE_DEROULANTE,filled:false},
    // 'Assigné':{title:'Assigné',id:7,zendesk_id:'ticket.assignee',type:LISTE_DEROULANTE,filled:false},
    'Partie Adverse':{title:'Partie Adverse',id:8,zendesk_id:'ticket.customField:custom_field_80325147',type:MULTILIGNE,filled:false},
    'Satisfaction Survey Sent FR':{title:'Satisfaction Survey Sent FR',id:9,zendesk_id:'ticket.customField:custom_field_80548828',type:CASE_A_COCHER,filled:false},
    'Calendar name':{title:'Calendar name',id:10,zendesk_id:'ticket.customField:custom_field_80617108',type:TEXT,filled:false},
    'Calendar event date':{title:'Calendar event date',id:11,zendesk_id:'ticket.customField:custom_field_80617128',type:DATE,filled:false},
    'Calendar event time':{title:'Calendar event time',id:12,zendesk_id:'ticket.customField:custom_field_80617148',type:TEXT,filled:false},
    'Calendar event timezone':{title:'Calendar event timezone',id:13,zendesk_id:'ticket.customField:custom_field_80617168',type:TEXT,filled:false},
    'Calendar event duration (in minutes)':{title:'Calendar event duration (in minutes)',id:14,zendesk_id:'ticket.customField:custom_field_80617188',type:NUMBER,filled:false},
    'Calendar event link':{title:'Calendar event link',id:15,zendesk_id:'ticket.customField:custom_field_80617208',type:TEXT,filled:false},
    'Calendar event subject':{title:'Calendar event subject',id:16,zendesk_id:'ticket.customField:custom_field_80617228',type:TEXT,filled:false},
    'Calendar event description':{title:'Calendar event description',id:17,zendesk_id:'ticket.customField:custom_field_80617248',type:MULTILIGNE,filled:false},
    'Calendar event location':{title:'Calendar event location',id:18,zendesk_id:'ticket.customField:custom_field_80617268',type:TEXT,filled:false},
    'Calendar event invite requester':{title:'Calendar event invite requester',id:19,zendesk_id:'ticket.customField:custom_field_80617288',type:CASE_A_COCHER,filled:false},
    'Calendar event invite CCs':{title:'Calendar event invite CCs',id:20,zendesk_id:'ticket.customField:custom_field_80617308',type:CASE_A_COCHER,filled:false},
    'Next Step':{title:'Next Step',id:21,zendesk_id:'ticket.customField:custom_field_80655127',type:TEXT,filled:false},
    'FollowUp':{title:'FollowUp',id:22,zendesk_id:'ticket.customField:custom_field_80705508',type:CASE_A_COCHER,filled:false},
    'Produit':{title:'Produit',id:23,zendesk_id:'ticket.customField:custom_field_80870188',type:LISTE_DEROULANTE,filled:false},
    'Date Prescription':{title:'Date Prescription',id:24,zendesk_id:'ticket.customField:custom_field_360000089368',type:DATE,filled:false},
    'Vente par':{title:'Vente par',id:25,zendesk_id:'ticket.customField:custom_field_360000185447',type:LISTE_DEROULANTE,filled:false,
        list:[
            {value:'Aucun',tag:'u0',default:true},
            {value:'Aimée',tag:'u2',default:false},
            {value:'Camaylia',tag:'camaylia',default:false},
            {value:'Doha',tag:'doha',default:false},
            {value:'Patrick',tag:'patrick',default:false},
            {value:'Pierre-luc',tag:'pierre-luc',default:false}
        ]
    },
    'Prix Vente':{title:'Prix Vente',id:26,zendesk_id:'ticket.customField:custom_field_360000194588',type:DECIMAL,filled:false},
    'Client additionnel':{title:'Client additionnel',id:27,zendesk_id:'ticket.customField:custom_field_360002363474',type:TEXT,filled:false},
    'Pénal : Date de cour dossier Pénal':{title:'Pénal : Date de cour dossier Pénal',id:28,zendesk_id:'ticket.customField:custom_field_360003279173',type:DATE,filled:false},
    'Sondage Med Gratuite':{title:'Sondage Med Gratuite',id:29,zendesk_id:'ticket.customField:custom_field_360003324874',type:CASE_A_COCHER,filled:false},
    'Number of tasks':{title:'Number of tasks',id:30,zendesk_id:'ticket.customField:custom_field_360005376354',type:NUMBER,filled:false},
    'Number of completed tasks':{title:'Number of completed tasks',id:31,zendesk_id:'ticket.customField:custom_field_360005376374',type:NUMBER,filled:false},
    'Remaining tasks':{title:'Remaining tasks',id:33,zendesk_id:'ticket.customField:custom_field_360005376394',type:TEXT,filled:false},
    'Parent Ticket ID':{title:'Parent Ticket ID',id:34,zendesk_id:'ticket.customField:custom_field_360005376414',type:NUMBER,filled:false},
    'Linked task ID':{title:'Linked task ID',id:35,zendesk_id:'ticket.customField:custom_field_360005376434',type:NUMBER,filled:false},
    'Task list ID':{title:'Task list ID',id:36,zendesk_id:'ticket.customField:custom_field_360005376454',type:NUMBER,filled:false},
    'Abonnement':{title:'Abonnement',id:37,zendesk_id:'ticket.customField:custom_field_360007230313',type:CASE_A_COCHER,filled:false},
    'Abonnement Annulé':{title:'Abonnement Annulé',id:38,zendesk_id:'ticket.customField:custom_field_360007230333',type:CASE_A_COCHER,filled:false},
    'Historique Dossier':{title:'Historique Dossier',id:39,zendesk_id:'ticket.customField:custom_field_360014859753',type:MULTILIGNE,filled:false},
    'Coordonnées Négociation':{title:'Coordonnées Négociation',id:40,zendesk_id:'ticket.customField:custom_field_360019452754',type:MULTILIGNE,filled:false},
    'Civil : Date de cour dossier Civil':{title:'Civil : Date de cour dossier Civil',id:41,zendesk_id:'ticket.customField:custom_field_360019463233',type:DATE,filled:false},
    'Palais de Justice (Ne pas oublier les accents)':{title:'Palais de Justice (Ne pas oublier les accents)',id:42,zendesk_id:'ticket.customField:custom_field_360019463573',type:LISTE_DEROULANTE,filled:false},
    'Contravention Numéro':{title:'Contravention Numéro',id:43,zendesk_id:'ticket.customField:custom_field_360021266914',type:TEXT,filled:false},
    // 'Cours municipale':{title:'Cours municipale',id:44,zendesk_id:'ticket.customField:custom_field_360021286674',type:LISTE_DEROULANTE,filled:false},
    'Lien SharePoint':{title:'Lien SharePoint',id:45,zendesk_id:'ticket.customField:custom_field_360022264074',type:TEXT,filled:false},
    // 'Avocat en Charge':{title:'Avocat en Charge',id:46,zendesk_id:'ticket.customField:custom_field_360023465514',type:LISTE_DEROULANTE,filled:false},
    'SN Ticket Data':{title:'SN Ticket Data',id:47,zendesk_id:'ticket.customField:custom_field_360027091693',type:MULTILIGNE,filled:false},
    'Note Penal':{title:'Note Penal',id:48,zendesk_id:'ticket.customField:custom_field_360027724993',type:TEXT,filled:false},
    'Statut Dossier':{title:'Statut Dossier',id:49,zendesk_id:'ticket.customField:custom_field_360027726013',type:TEXT,filled:false},
    'neo_calendar':{title:'neo_calendar',id:50,zendesk_id:'ticket.customField:custom_field_360028127774',type:TEXT,filled:false},
    'courriel':{title:'Email',id:51,type:EMAIL,filled:false},
    'téléphone':{title:'téléphone',id:52,type:PHONE,filled:false},
    'temps':{title:'temps',id:53,type:TIME,filled:false}
}