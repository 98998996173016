

const LOAD = "LOAD";
const UNLOAD = "UNLOAD";

export function load(params){
    return {
        type:LOAD,
        params:params
    }
}

export {
    LOAD,UNLOAD
}