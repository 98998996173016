import React from 'react'
import { useTable } from 'react-table'
import {Table as RTable} from 'reactstrap'

function Table({ columns, data }) {
  // Use the state and functions returned from useTable to build your UI

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
  })

  // Render the UI for your table
  return (
    // <div class="table-responsive">
      <RTable {...getTableProps()} responsive striped>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                })}
              </tr>
            )
          })}
        </tbody>
      </RTable>
    // </div>
  )
}

function App({columns,data}) {
  const columnsCache = React.useMemo(
    () => columns,[columns]
  )
// const data = [];
//   const data = React.useMemo(() => makeData(20), [])

  return (
      <Table columns={columnsCache} data={data} />
  )
}

export default App
