import React, { Component } from "react";
import { Alert } from "reactstrap";
import _ from 'lodash'

const INFO = 'info'
export default class AlertNotification extends Component {
    constructor(props) {
        super(props);

        this.state = {
            visible: this.props.visible||false,
            color:this.props.color||INFO,
            message:this.props.message||''
        };

        this.onDismiss = this.onDismiss.bind(this);
    }

    componentDidUpdate(prevProps){
        let obj = {};
        if(prevProps.visible !== this.props.visible)
            obj.visible=this.props.visible;

        if(prevProps.message !== this.props.message)
            obj.message=this.props.message;

        if(prevProps.color !== this.props.color)
            obj.color=this.props.color;

        if(!!_.keys(obj).length){
            this.setState(obj);
        }
    }

    onDismiss(){
        this.setState({visible:false})
    }

    render(){
        return (
        <>
            <Alert
                key='alert'
                color={this.state.color || INFO}
                isOpen={this.state.visible}
                toggle={this.onDismiss}
            >
                {this.state.message}
            </Alert>
        </>
        )
    }
}
