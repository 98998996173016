import React, {Component} from 'react';
import {
    Container,
    Row,
    NavItem,
    NavLink
} from 'reactstrap';

import SideBar from './utils/SideBar';

import DashboardElement from './utils/DashboardElement'
import { connect } from 'react-redux';
import UserPanel from './adminDashboard/UserPanel';
import UserEditType from './adminDashboard/UserEditType';
import AddUser from './adminDashboard/AddUser';
// import {ProductsForm,ProductsTable} from './adminDashboard/Products';
import {FeesByUserForm,FeesByGroupForm, FeesTable} from './adminDashboard/Fees';

import Licenses from './adminDashboard/Licenses';
import Specialities from './adminDashboard/Specialities';

import Ticket from './adminDashboard/Ticket';
import {ProductsForm,ProductsTable} from './adminDashboard/Products';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faPlusSquare, faIdBadge, faUsersCog,faCommentDollar,
     faTags
    } from '@fortawesome/free-solid-svg-icons';
import { Nav } from 'reactstrap';
import _ from 'lodash'
import rootsActions from '../_actions/root-actions';
import {
    privateAxios as axios,
    adminAxios
} from '../utils/axios';
import { Redirect } from 'react-router-dom';

const Plus = <FontAwesomeIcon icon={faPlusSquare} />
const list = 'list';
const manage = 'manage'
const manageUser = 'manageUser'

const BLOCK = 'block';
const NONE = 'none';

const services = 'services';
const newUser = 'newUsers';
const fees = 'fees';
const licenses = 'licenses';
const all = 'all';

class Groups extends Component{
    constructor(props){
        super(props);

        this.state = {
            visible:services
        }

        this.lawyerCallback = this.lawyerCallback.bind(this);
    }


    //test commit

    componentDidMount(){
        let uID = this.props["userId"];
        
        if(!uID){
          let token = window.parseJwt(localStorage.getItem('token'));
          
          uID=token.sub
          
        }

        // console.log('auth_id',uID);
        if(!uID){
            console.log('---> uID',uID);
            return;

        }

        // if(!localStorage.getItem('lawyerData')){ 
            axios.get(
                'lawyers',
                {params:{
                    auth_id:uID,
                    refresh:true
                }}
            )
            .then(this.lawyerCallback);
        // }
        // else{
        //     this.lawyerCallback(JSON.parse(localStorage.getItem('lawyerData')))
        // }
    }

    lawyerCallback(response){
        if( response && response.data && response.data.data[0]){
            
            let uID = this.props["userId"];

            if(!uID){
                let token = window.parseJwt(localStorage.getItem('token'));
                uID=token.sub
            }

            localStorage.setItem('lawyerData',JSON.stringify(response));
            // debugger;
            this.props.init({params:{
                userList:[{auth_id: -1,firstname:"Tous",lastname:' '},...response.data.data[0].lawyers],
                types:[...response.data.data[0].types],
                specialities:[...response.data.data[0].specialities],
                durations:[...response.data.data[0].durations],
                // categories:[...response.data.data[0].categories]
            }});

            

            adminAxios.get(
                '/tarifs',
                {params:{
                    auth_id:uID
                }}
            )
            .then(
                (response) =>{
                    
                    let groupAmount = '';
                    if(response.data && response.data.data){

                        let global = response.data.data.global;
                        let typeName = '';
                        let types = this.props.types;
                        // console.log(_.find(this.props.types,{id:4}))
                        global = _.map(global, (e) => {
                            typeName = _.find(types,{id:parseInt(e.type_id)});
                            
                            if(typeName)
                                typeName = typeName.title;
                            else 
                                typeName = '';
                            
                            return {
                                ...e,
                                type:typeName
                            }
                        });

                        let username = '';
                        
                        let userList = this.props.userList;
                        let negotiated = response.data.data.negotiated;
                        // debugger;
                        negotiated = _.map(negotiated, (e) => {
                            typeName = _.find(types,{id:parseInt(e.type_id)});
                            // debugger;
                            // console.log('typename -> ', typeName)
                            if(typeName)
                                typeName = typeName.title;
                            else 
                                typeName = '';

                            
                            username = _.find(userList,{auth_id:e.user_id.toString()});
                            if(username)
                                username = username.firstname + ' ' + username.lastname ;
                            else 
                                username = '';
                            
                                
                    groupAmount = _.find(global,{type_id:e.type_id.toString()})
                    
                    if(!groupAmount)
                      groupAmount='inconnu';


                            return {
                                ...e,
                                type:typeName,
                                user:username,
                                groupAmount:groupAmount.amount||'inconnu'
                            }
                        });

                        // console.log(negotiated)
                        // debugger;

                        this.props.tarifFetch({global:global,negotiated:negotiated});
                    }
                },
                () =>{
                    console.error('tarif error')
                }
            )
        }
    }

    render(){

        
        let token = window.parseJwt(localStorage.getItem('token'));

        if(!token.scope||!token.scope.admin)
            return <Redirect to={{pathname:"/schedule"}} />

        return (
        <Container className="sidebarMargin padding-10">
                
                <SideBar/>

                <Nav tabs>
                    <NavItem >
                        <NavLink 
                            className={'m-l-5 p-t-5 p-b-5'}
                            active={all === this.state.visible}
                            tag={'span'}
                            color={'primary'} 
                            // data-target={e.target}
                            onClick={(f) => this.setState({visible:all})}
                        >
                            Tout
                        </NavLink>
                    </NavItem>
                    <NavItem >
                        <NavLink 
                            className={'m-l-5 p-t-5 p-b-5'}
                            active={services === this.state.visible}
                            tag={'span'}
                            color={'primary'} 
                            // data-target={e.target}
                            onClick={(f) => this.setState({visible:services})}
                        >
                            Services
                        </NavLink>
                    </NavItem>
                    {/* <NavItem >
                        <NavLink 
                            className={'m-l-5 p-t-5 p-b-5'}
                            active={fees === this.state.visible}
                            tag={'span'}
                            color={'primary'}
                            onClick={(f) => this.setState({visible:fees})}
                        >
                            Tarifs
                        </NavLink>
                    </NavItem> */}
                    {/* <NavItem >
                        <NavLink 
                            className={'m-l-5 p-t-5 p-b-5'}
                            active={licenses === this.state.visible}
                            tag={'span'}
                            color={'primary'} 
                            // data-target={e.target}
                            onClick={(f) => this.setState({visible:licenses})}
                        >

                            Licenses
                        </NavLink>
                    </NavItem> */}
                    {/* <NavItem >
                        <NavLink 
                            className={'m-l-5 p-t-5 p-b-5'}
                            active={newUser === this.state.visible}
                            tag={'span'}
                            color={'primary'} 
                            // data-target={e.target}
                            onClick={(f) => this.setState({visible:newUser})}
                        >

                            Nouvel utilisateur
                    </NavLink>
                    </NavItem> */}
                </Nav>
                
                <Row style={{display:(this.state.visible === all || this.state.visible === fees)?BLOCK:NONE}}>
                    <DashboardElement 
                        defaultActive={manage}
                        col={12}
                        title={<>Tarifs <FontAwesomeIcon icon={faCommentDollar} /></>}
                        nav={[
                            {name:'Tarifs',target:manage},
                            {name:'Tarifs négociés',target:manageUser},
                            {name:'Liste',target:list},
                        ]}
                        
                        collapse={[{
                            target:manage,
                            component:<FeesByGroupForm />
                        },{
                            target:manageUser,
                            component:<FeesByUserForm />
                        },
                        {
                            target:list,
                            component:<FeesTable />
                        }]} />
                </Row>
                <Row style={{display:(this.state.visible === all || this.state.visible === services)?BLOCK:NONE}}>
                    <DashboardElement 
                        col={12}
                        title={<>Gestion des tickets <FontAwesomeIcon icon={faTags} /></>}
                    >
                        <Ticket />
                    </DashboardElement>
                </Row>
                <Row style={{display:(this.state.visible === all || this.state.visible === services)?BLOCK:NONE}}>
                    <DashboardElement 
                        col={12}
                        title={<>Services <FontAwesomeIcon icon={faUsersCog} /></>}
                    >
                        <Specialities />
                    </DashboardElement>
                </Row>
                
                
                <Row style={{display:(this.state.visible === all || this.state.visible === fees|| this.state.visible === services)?BLOCK:NONE}}>
                    <DashboardElement 
                        defaultActive={manage}
                        col={12}
                        title={<>Type de rendez-vous <FontAwesomeIcon icon={faTags} /></>}
                        nav={[
                            {name:'Produits',target:manage},
                            {name:'Liste',target:list}]
                        }
                        collapse={[{
                            target:manage,
                            component:<ProductsForm/>
                        },
                        {
                            target:list,
                            component:<ProductsTable/>
                        }]} />
                </Row>

                {/* <Row style={{display:(this.state.visible === all || this.state.visible === licenses)?BLOCK:NONE}}>
                    
                <DashboardElement
                    col={12}
                     title={<>Licenses <FontAwesomeIcon icon={faIdBadge} /></>}
                    >
                        <Licenses />
                    </DashboardElement>
                </Row> */}
                {/* <Row style={{display:(this.state.visible === all || this.state.visible === newUser)?BLOCK:NONE}}>
                    
                    <DashboardElement 
                        col={12}
                        title={<>Ajout utilisateur {Plus}</>}
                        id={"userForm"}
                        className={'blueTransform'}
                    >
                        <UserPanel />
                    </DashboardElement>
                </Row> */}
                <Row style={{display:(this.state.visible === all || this.state.visible === services)?BLOCK:NONE}}>
                    
                    <DashboardElement 
                        col={12}
                        title={<>Édition utilisateur {Plus}</>}
                        id={"userForm"}
                        className={'blueTransform'}
                    >
                        <UserEditType />
                    </DashboardElement>
                </Row>
                <Row>
                    <DashboardElement 
                        col={12}
                        title={<>Ajout d'un utilisateur {Plus}</>}
                        id={"userForm"}
                        className={'blueTransform'}
                    >
                        <AddUser />
                    </DashboardElement>
                </Row>
            </Container>)
    }
}

const mapStateToProps = state => ({
    userList:state.users.userList,
    specialities:state.users.specialities,
    types:state.users.types,
    // userList:state.users.lawyers,
    global:state.tarif.global,
    negotiated:state.tarif.negotiated,
    admin:state.users.result.admin,
    userId:state.users.result.userId
});

const mapActionsToProps = {
    setLawyers: rootsActions.usersActions.setLawyers,
    init:rootsActions.usersActions.initUsersData,
    tarifFetch:rootsActions.tarifActions.tarifFetch,
}
export default connect(mapStateToProps,mapActionsToProps)(Groups)
