import React from 'react';
import { 
    Container,
    Row,
    Col,
    Card,
    CardBody,
} from 'reactstrap';

import ExternalSideBar from './utils/ExternalSideBar';

  
const logo = require('../assets/img/brand/neolegal.png');

const ExternalDashboard = ({ children }) =>  {

  return (
    <>
        <ExternalSideBar/>
        <div className="main-content login" >
            <div className="header bg-gradient-info " style={{overflow:'scroll'}}>
                <Container className="sidebarMargin  m-t-15" style={{minHeight:'100vh',height:'100vh'}}>
                    <div className="header-body text-center ">
                        <img src={logo} width="300px;" alt="Neolegal"></img>
                    </div>
                    <Row>
                        <Col>
                            <Card className={"min-height-800"}>
                                <CardBody>
                                    {children}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
      </>
  )
}

export default ExternalDashboard;