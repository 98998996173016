import React, { Component } from 'react';

import {
  Alert,
  Form,
  FormGroup,
  Button,
  ListGroup,
  ListGroupItem,
  Badge,
} from 'reactstrap';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { DayPickerRangeController } from 'react-dates';

import { connect } from 'react-redux';

import rootsActions from '../_actions/root-actions';

import _ from 'lodash';
import { privateAxios as axios } from '../utils/axios'
import _axios from "axios";

import moment from 'moment-timezone';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faCheckCircle, faBan } from '@fortawesome/free-solid-svg-icons';
import { BeatLoader } from 'react-spinners';

import {
  NEO_CALENDAR,
  CALENDAR_EVENT_TIME,
  CALENDAR_EVENT_TIME_ZONE,
  CALENDAR_EVENT_DATE,
  CALENDAR_EVENT_DURATION,
  CALENDAR_EVENT_LAWYER,
  LAWYER_IN_CHARGE
}
  from '../data/zendeskFields';
import { Redirect } from 'react-router-dom';

const PATH = process.env.REACT_APP_API_URL;

const options = [
  { value: '15', label: '15min' },
  { value: '30', label: '30min' },
  { value: '45', label: '45min' },
  { value: '60', label: '60min' },
];

const BLOCK = 'block';
const NONE = 'none';

class BookingClient extends Component {

  constructor(props) {

    super(props);

    let tokenClient = localStorage.getItem('token-client');

    if (tokenClient) {

      tokenClient = window.parseJwt(tokenClient);

    }

    let week = tokenClient.scope.week,
      year = tokenClient.scope.year;

    let start = moment().isoWeek(week).year(year).startOf('isoWeek').startOf('day').add(2, 'hours') || moment().startOf('isoWeek').startOf('day').add(2, 'hours');

    let end = moment().isoWeek(week).year(year).endOf('isoWeek').startOf('day').add(2, 'hours') || moment().endOf('isoWeek').startOf('day').add(2, 'hours');

    // console.log('--->',start.startOf('isoWeek').isoWeekYear(),start)
    this.state = {
      ...tokenClient.scope,
      availabilities: [],
      startDate: null,
      endDate: null,
      selectedButton: "",
      selectedUser: -1,
      duration: options[1].value,
      durations: [
        { value: '15', label: '15min' },
        { value: '30', label: '30min' },
        { value: '45', label: '45min' },
        { value: '60', label: '60min' }
      ],
      filter: {
        type: 1,
        speciality: 0,
        user: -1,
      },
      filterType: 1,
      filterSpeciality: false,
      filterUser: false,
      focusedInput: 'startDate',
      loading: false,
      error: false,
      toggle: 'block',
      userList: [{ auth_id: -1, firstname: "Tous", lastname: ' ' }],
      alreadyTaken: false,
      RDVInfo: false,
      RDVDate: false,
      assignedLawyer: false,
      textArea: "",
      ampm: (localStorage.getItem('ampm') === 'true' || this.props.ampm) ? true : false,
      selectedWeek: start.isoWeek(),
      selectedYear: start.startOf('isoWeek').isoWeekYear(),
    }

    this.handleDateChange = this.handleDateChange.bind(this);
    this.onClick = this.onClick.bind(this);
    this.onChange = this.onChange.bind(this);
    this.handleSelect = this.handleSelect.bind(this)
    this.renderCalendar = this.renderCalendar.bind(this);
    this.renderAppointment = this.renderAppointment.bind(this);
    this.deleteTaken = this.deleteTaken.bind(this);
    this.onWeekChange = this.onWeekChange.bind(this);
    this.lawyerCallback = this.lawyerCallback.bind(this);
    this.book = this.book.bind(this);

  }

  componentDidMount() {
    _axios.post(PATH + 'validate',
      {},
      {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('token-client'),
          // 'Content-Type': 'application/json',
          // withCredentials: false
          // 'x-jwt-token':localStorage.getItem('token-client'),
        }
        // auth:{
        //     username:'admin',
        //     password:localStorage.getItem('token-client')
        // },
      }).then(
      (status) => {
        if (!status || !status.data || !status.data.data || !status.data.data.scope) {
          return this.setState({
            redirect: true
          })
        }

        let tokenClient = localStorage.getItem('token-client');

        if (tokenClient) {

          tokenClient = window.parseJwt(tokenClient);

        }

        this.setState({
          ...tokenClient.scope,
          //startDate:moment().isoWeek(tokenClient.scope.week).year(tokenClient.scope.year).startOf('isoWeek').startOf('day').add(2,'hours'),
          //endDate: moment().isoWeek(tokenClient.scope.week).year(tokenClient.scope.year).endOf('isoWeek').startOf('day').add(2,'hours'),
          // selectedWeek:tokenClient.week,
          // selectedYear:tokenClient.year,
        }, this.getBooks);


      },
      (err) => {

        return this.setState({
          redirect: true
        })
      }
    )

    //     let take = localStorage.getItem('token-client')


    // let tkn = 'Bearer ' + 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE2MDQwODM4MzcsImV4cCI6MTYwNDI1NjYzNywianRpIjoiNjlOc0xocmJtNW9KT201V09sTWw4eSIsInN1YiI6IjQ1Iiwic2NvcGUiOnsiaWRfemVuZGVzayI6IjMiLCJhdXRoX2lkIjoiNDUiLCJ3ZWVrIjoiNSIsInllYXIiOiI2IiwiZHVyYXRpb24iOiI5IiwidGlja2V0X2lkIjoiNSIsImNsaWVudF9pZCI6IjMiLCJ0eXBlIjoiMyIsInNwZWNpYWxpdHkiOiI5IiwidXNlciI6IjMifX0.YgksfJI--EEgzyqYg3IF07mqd3rvfXmEXqeUobil1IU'
    // https://8dbd64eabbb7.ngrok.io


    // console.log('componentDidMount')
    //    if(true||!localStorage.getItem('lawyerData')){

    //         this.props.clientInit();
    //         axios.get(
    //             'lawyers',
    //             {params:{auth_id:uID}}
    //         )
    //         .then(this.lawyerCallback);
    //     }
    //     else{
    //         this.lawyerCallback(JSON.parse(localStorage.getItem('lawyerData')))
    //     }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.lang !== this.props.lang) {
      moment.locale(this.props?.lang ?? "fr");
    }
  }

  lawyerCallback(response) {
    // debugger;
    if (response && response.data && response.data.data[0]) {


      localStorage.setItem('lawyerData', JSON.stringify(response));
      let u = _.find(response.data.data[0].lawyers, { 'auth_id': this.props.userId });
      let filter = this.state.filter
      if (u && u.type !== 'sales') {
        filter.user = parseInt(this.props.userId);
      }

      this.props.setLawyers({
        params: {
          lawyers: [
            {
              auth_id: -1,
              firstname: "Tous",
              lastname: " ",
              color: "#545454"
            },
            ...response.data.data[0].lawyers
          ]
        }
      });

      this.setState({
          types: [...response.data.data[0].types],
          specialities: [...response.data.data[0].specialities],
          durations: [...response.data.data[0].durations],
          filter: filter
        },
        () => {
          this.getWithCallback(this.getBooks, this);
        }
      );
    }
  }

  handleSelect(e, loading = true) {

    this.setState({
        duration: _.find(this.state.durations, { id: e.id }).value,
        loading: loading
      },
      () => {
        if (loading)
          this.getBooks();
      }
    );
  }

  onChange(e) {
    let obj = { ...this.state.filter };

    if (e.name !== 'type') {
      obj[e.name] = e.value;
    } else {
      obj['type'] = parseInt(e.value);
      this.handleSelect({ id: e.duration }, false)
    }

    this.setState({
      filter: obj,
      filterType: obj['type'],
      filterSpeciality: obj['speciality'],
      filterUser: obj['user'],
      loading: true
    }, () => {
      this.getBooks();
    });
  }

  onWeekChange(e) {
    this.setState({
      startDate: moment(e.start),
      endDate: moment(e.start).endOf('isoWeek'),
      selectedWeek: e.value,
      selectedYear: e.year,
    }, () => {
      this.getBooks();
    });
  }

  onClick(e) {
    //in double
    let event = new CustomEvent('getCurrentComment',
      {
        detail: {
          field: "ticket.comment",
          neoName: 'currentComment'
        }
      }
    );
    window.dispatchEvent(event);

    let event2 = new CustomEvent('getTicket');
    window.dispatchEvent(event2);

    this.setState({
      selectedButton: e.currentTarget.id,
      multi: (e.currentTarget.attributes.multi.value === 'true') ? true : false,
      selectedButtonEnd: e.currentTarget.attributes.end.value,
      selectedUser: parseInt(e.currentTarget.attributes.user.value)
    });
  }

  closestAppointment(appointments, userList) {
    // let rejectArr = _.reject(appointments,{id:id});
    let moreRecent;
    appointments = _.orderBy(
      _.filter(appointments, (e) => e.service_id < 999)
      , 'start');

    if (appointments && appointments.length) {
      moreRecent = _.find(appointments, (e) => {
        return moment().isBefore(moment(e.start))
      });

      if (moreRecent) {
        let u = _.find(userList, { 'auth_id': moreRecent.user });
        if (u)
          u = u.firstname + ' ' + u.lastname;

        this.setTicket(NEO_CALENDAR, moreRecent.start);
        this.setTicket(CALENDAR_EVENT_TIME, moment(moreRecent.start).format('LT'));
        this.setTicket(CALENDAR_EVENT_TIME_ZONE, moment.tz.guess());
        this.setTicket(CALENDAR_EVENT_DATE, moment(moreRecent.start).toDate());
        this.setTicket(CALENDAR_EVENT_DURATION, moreRecent.duration);
        this.setTicket(CALENDAR_EVENT_LAWYER, u);
      }
    }
  }

  deleteTaken(id) {

    // code de moi qui valide avec un modale ou quelque chose....
    if (!id)
      return;

    let uID = this.props["userId"];

    if (!uID) {
      let token = window.parseJwt(localStorage.getItem('token'));

      uID = token.sub
    }

    axios
      .delete('availabilities', {
        params: {
          id: id,
          auth_id: uID
        }
      })
      .then((response) => {
        let rejectArr = _.reject(this.state.appointments, { id: id });
        let moreRecent;
        rejectArr = _.orderBy(rejectArr, 'start');

        if (rejectArr && rejectArr.length) {
          moreRecent = _.find(rejectArr, (e) => {
            return moment().isBefore(moment(e.start))
          });

          if (moreRecent) {
            let u = _.find(this.props.userList, { 'auth_id': moreRecent.user });
            if (u)
              u = u.firstname + ' ' + u.lastname;

            this.setTicket(NEO_CALENDAR, moreRecent.start);
            this.setTicket(CALENDAR_EVENT_TIME, moment(moreRecent.start).format('LT'));
            this.setTicket(CALENDAR_EVENT_TIME_ZONE, moment.tz.guess());
            this.setTicket(CALENDAR_EVENT_DATE, moment(moreRecent.start).toDate());
            this.setTicket(CALENDAR_EVENT_DURATION, moreRecent.duration);
            this.setTicket(CALENDAR_EVENT_LAWYER, u);
          }
        }

        if (!rejectArr || !rejectArr.length || !moreRecent) {
          this.setTicket(NEO_CALENDAR, null);
          this.setTicket(CALENDAR_EVENT_TIME, null);
          this.setTicket(CALENDAR_EVENT_TIME_ZONE, null);
          this.setTicket(CALENDAR_EVENT_DATE, null);
          this.setTicket(CALENDAR_EVENT_DURATION, null);
          this.setTicket(CALENDAR_EVENT_LAWYER, null);
        }

        //je m'attends à un objet data avec les nouvelles availabilities et les nouveaux taken
        // si j'annule un RDV, ça veut dire qu'une dispo est de retour est sur le marché!
        this.getBooks()
      });

  }

  getBooks(e) {

    let uID = this.props["userId"];

    if (!uID) {
      let token = window.parseJwt(localStorage.getItem('token-client'));

      uID = token.sub
    }

    let params = {
      default: false,
      auth_id: uID,
      week: this.state.selectedWeek,
      year: this.state.selectedYear,
      duration: this.state.duration,
      ticket_id: this.state.ticket_id,
      client_id: this.state.client_id,
      type: this.state.type
    };
    if (this.state.user !== undefined) {
      params.user = this.state.user || 0;
    }
    _axios.get(
      PATH + 'availabilities',
      {
        params: params,
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token-client'),
          'Content-Type': 'application/json'
        }

      }
    )
      .then((response) => {

          if (response && response.data) {
            let arr = [],
              av = response.data.data.taken;

            for (let a in av) {
              for (let t in av[a].taken) {
                arr.push({
                  ...av[a].taken[t],
                  user: av[a].user
                })
              }
            }

            if (!window.Neolegal.ticket_id) {
              arr = []
            }


            let week = {};
            let weekDrop = {};
            if (

              response.data.data.availabilities && response.data.data.availabilities.length
            ) {

              weekDrop = moment(response.data.data.availabilities[0].days).startOf('isoWeek').add(2, 'h')
              //weekDrop = moment().startOf('isoWeek').add(2,'h')
              if (weekDrop) {
                week.startDate = weekDrop;
                week.endDate = weekDrop.clone().endOf('isoWeek');
                week.selectedWeek = week.startDate.isoWeek();
                week.selectedYear = week.startDate.isoWeekYear();
                week.isDST = weekDrop.isDST();
              } else if (!this.state.startDate) {
                week.startDate = moment().startOf('isoWeek').add(2, 'h');
                week.endDate = moment().startOf('isoWeek');
              }
            } else if (!this.state.startDate) {
              week.startDate = moment().startOf('isoWeek').add(2, 'h');
              week.endDate = moment().startOf('isoWeek');
            }

            this.setState({
              loading: false,
              availabilities: response.data.data.availabilities,
              appointments: arr,
              error: false,
              deleteAppointment: false,
              ...week
            }, () => {

              this.closestAppointment(this.state.appointments, this.props.userList)
            })
          } else {
            let week = {};
            if (!this.state.startDate) {
              week = {
                startDate: moment().startOf('isoWeek').add(2, 'h'),
                endDate: moment().startOf('isoWeek'),
              }
            }
            this.setState({
              ...week,
              deleteAppointment: false,
              loading: false,
              availabilities: [],
              error: <>
                <div>Erreur, faîtes la tâche manuellement,<br></br> puis avisez un IT</div>
              </>
            })
          }
        }, (error) => {
          console.log(error)
        }
      );
  }

  book(e) {


    let uID;
    // console.log(uID);

    if (!uID) {
      let token = window.parseJwt(localStorage.getItem('token-client'));

      //   console.log(token);

      uID = token.sub;

    }
    this.setState({
      loading: true
    });

    let isDST = moment(moment(this.state.selectedButton).clone().format());
    isDST = isDST.isDST();

    let appText = (this.state.textArea) ? this.state.textArea : (window.Neolegal.commentType === 'internalNote') ? window.Neolegal.currentComment : '';

    // console.log(_.find(this.state.types,{id:parseInt(this.state.filter.type)}).type)
    // debugger;
    _axios.post(
      PATH + 'availabilities', {
        week: this.state.startDate.clone().week(),
        day: moment(this.state.selectedButton).startOf('day').add((isDST) ? 2 : 1, 'hours').utc().format("YYYY-MM-DDTHH:mm:ss+00:00"),
        user: parseInt(this.state.selectedUser),
        auth_id: uID,
        taken: {
          'public': 'true',
          "title": this.state.title,
          "description": this.state.description,
          "group": {
            "id": "111111-22222-efefef-123456",
            "name": "PNC"
          },
          "service_id": parseInt(this.state.type),
          "service": this.state.typeName,
          "service_type": this.state.typeType,
          "start": this.state.selectedButton,
          "end": this.state.selectedButtonEnd,
          "TimeZone": "America/Toronto",
          "duration": parseInt(this.state.duration),
          "where": "montreal",
          "organizer": {
            "id_zendesk": this.props.id_zendesk || localStorage.getItem('id_zendesk'),
            "id": uID,
            "role": this.props.type
          },
          "ics_url": "url?",
          "ticket_id": parseInt(this.state.ticket_id),
          "app_subject": this.state.subject,
          "app_description": appText,
          "client": {
            id: this.state.client_id,
            email: this.state.email
          },

          "multi": !!this.state.multi
        },
      },
      {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('token-client'),
          // 'Content-Type': 'application/json',
          // withCredentials: false
          // 'x-jwt-token':localStorage.getItem('token-client'),
        }
      }
    )
      .then((response) => {
        if (response && response.status === 200 && !response.data.error) {

          let assignedLawyer = { firstname: '', lastname: '', id_zendesk: '' }

          if (response.data.data && response.data.data.data)
            assignedLawyer = response.data.data.data.user;

          let name = 'Me ' + assignedLawyer.lastname + ' ' + assignedLawyer.firstname;
          // if(assignedLawyer && assignedLawyer.firstname)
          //     name = assignedLawyer.firstname+' '+ assignedLawyer.lastname;

          this.updateNeoCalendar(
            this.state.selectedButton,
            assignedLawyer.id_zendesk,
            // _.find(this.state.types,{id:this.state.type})
            {}
            ,
            name,
            this.state.duration
          );

          _axios.post(PATH + 'validate',
            { expire: true },
            {
              headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token-client'),
                // 'Content-Type': 'application/json',
                // withCredentials: false
                // 'x-jwt-token':localStorage.getItem('token-client'),
              }
              // auth:{
              //     username:'admin',
              //     password:localStorage.getItem('token-client')
              // },
            }).then(() => {
              localStorage.removeItem('token-client');
              this.setState({
                taken: true
              })
            },
          )

          this.setState({
            alreadyTaken: false,
            RDVInfo: true,
            RDVDate: moment(this.state.selectedButton).format('LLL'),
            assignedLawyer: (assignedLawyer) ? assignedLawyer.firstname + ' ' + assignedLawyer.lastname : '',
            selectedUser: false,
            selectedButton: false,
            selectedButtonEnd: false,
            multi: false
          }, () => {
            this.getBooks();
          });

        } else {

          this.setState({
            alreadyTaken: (response.data) ? response.data.error : 'Erreur',
            RDVInfo: false,
            RDVDate: false,
            assignedLawyer: false,
            selectedUser: false,
            selectedButton: false,
            selectedButtonEnd: false,
            multi: false
          });
        }
      });
  }

  getWithCallback(callback, self, params) {
    // debugger;
    if (window.Neolegal.ticket_id) {
      // console.log('ULTIMATE CHANCE')
      return callback.call(self);
    }

    let event = new CustomEvent('getWithCallback',
      { detail: { callback: callback, self: self, params: params } }
    );

    window.dispatchEvent(event);

  }

  setTicket(field, value) {
    let event = new CustomEvent('setTicket',
      {
        detail: {
          field: field,
          value: value
        }
      }
    );

    window.dispatchEvent(event);

  }

  updateNeoCalendar(date, lawyer, type, name, duration) {
    // if(window.Neolegal.nextAppointment){
    let m;
    if (window.Neolegal.nextAppointment)
      m = (moment().isAfter(moment(window.Neolegal.nextAppointment)) || moment(window.Neolegal.nextAppointment).isAfter(moment(date)))
    else
      m = true;

    if (m) {

      window.Neolegal.nextAppointment = date;
      this.setTicket(NEO_CALENDAR, date);
      this.setTicket(CALENDAR_EVENT_TIME, moment(date).format('LT'));
      this.setTicket(CALENDAR_EVENT_TIME_ZONE, moment.tz.guess());
      this.setTicket(CALENDAR_EVENT_DATE, moment(date).toDate());
      this.setTicket(CALENDAR_EVENT_DURATION, duration);
      this.setTicket(CALENDAR_EVENT_LAWYER, name);


    }

    if (!window.Neolegal.lawyerInCharge && lawyer && type.type !== 1) {

      this.setTicket(LAWYER_IN_CHARGE, lawyer);
    }
    // }

  }

  handleDateChange(e) {
    return false;
  }

  renderCalendar(availabilities) {
    availabilities = _.uniq(availabilities, 'start');

    if (availabilities && !availabilities.length && this.state.types) {
      let type = _.find(this.state.types, { id: this.state.filter.type }).type;

      if (type === 1 && this.state.filter.user > 0)
        return (<><strong>{
          this.genLang({
            fr: 'Le service demandé nécessite un usager précis!',
            en: 'The requested service requires a specific user!'
          })
        }</strong></>)
      else if (type === 0)
        return (<><strong>{
          this.genLang({
            fr: 'Aucun résultat!',
            en: 'No results!'
          })
        }</strong></>)
    }

    // if(this.state.types){
    //     console.log('kokoni')
    //     let isAMPM = (_.find(this.state.types,{id:this.state.filter.type}).type > 0 && this.state.filter.user>0);
    //     if(this.state.ampm && isAMPM ){
    //         let i = this.state.startDate.clone();
    //         while(i.isBefore(this.state.endDate)){
    //             // no fds
    //             if(i.isoWeekday()<6){

    //                 availabilities.push({
    //                     "user": this.state.filter.user,
    //                     "day":i.clone().hour(6).format(),
    //                     "busy": 0,
    //                     "start":i.clone().startOf('day').minute(15).format(),
    //                     "end":i.clone().startOf('day').minute(30).format(),
    //                     "duration": "0",
    //                     "color": "",
    //                     "multi":true,
    //                     "text":"AM"
    //                 });

    //                 availabilities.push({
    //                     "user": this.state.filter.user,
    //                     "day":i.clone().hour(6).format(),
    //                     "busy": 0,
    //                     "start":i.clone().startOf('day').minute(30).format(),
    //                     "end":i.clone().startOf('day').minute(45).format(),
    //                     "duration": "0",
    //                     "color": "",
    //                     "multi":true,
    //                     "text":"PM"
    //                 })
    //             }
    //             i.add(1,'d');
    //         }
    //     }
    // }
    let arr = [],
      outterArr = [],
      day = this.initDate(),
      tempAvailable;

    availabilities = _.filter(
      _.sortBy(availabilities, function (dateObj) {
        return new Date(dateObj.start);
      }), (e) => {

        return ((e.multi && moment().startOf('day').isSameOrBefore(e.start)) || moment().isBefore(moment(e.start)));
      });


    for (let a in availabilities) {

      if (day.weekday && day.weekday !== moment(availabilities[a].start).isoWeekday()) {
        outterArr.push(<div className="calendar-btn" id={'-=-' + a} key={'-=-' + a}>{arr}</div>);
        arr = [];
        day = this.initDate();
        tempAvailable = undefined;
      }

      if (!day.weekday) {
        tempAvailable = moment(availabilities[a].start);
        day.weekday = tempAvailable.weekday();
        day.date = tempAvailable.day();
        arr.push(<Badge color="info" className="bold badge-block"
                        key={day.date + a}>{tempAvailable.format('dd DD')}</Badge>)
      }

      if (availabilities[a].multi) {
        if (availabilities[a].text === 'PM') {
          availabilities[a].start = moment(availabilities[a].start).add(12, 'h').format()
          availabilities[a].end = moment(availabilities[a].end).add(12, 'h').format()
        }


        arr.push(<Button
          onClick={this.onClick}
          user={availabilities[a].user}
          id={availabilities[a].start}
          multi={"true"}
          end={availabilities[a].end}
          key={availabilities[a].start + '-' + a}
          color={
            this.state.selectedButton === availabilities[a].start ?
              "neo-orange" : "neo-blue"
          }
          // style={{backgroundColor:'#ffcccc !Important'}}
          // style={(availabilities[a].color)?{backgroundColor:availabilities[a].color+' !important'}:{}}
        >
          {availabilities[a].text}
        </Button>);
      } else {
        arr.push(<Button
          onClick={this.onClick}
          user={availabilities[a].user}
          id={availabilities[a].start}
          end={availabilities[a].end}
          key={availabilities[a].start + '-' + a}
          multi={"false"}
          color={
            this.state.selectedButton === availabilities[a].start ?
              "neo-orange" : "neo-blue"
          }
          // style={{backgroundColor:'#ffcccc !Important'}}
          // style={(availabilities[a].color)?{backgroundColor:availabilities[a].color+' !important'}:{}}
        >
          {moment(availabilities[a].start).format('HH[:]mm')}
        </Button>);
      }


      // }
    }

    if (!arr || !arr.length)
      arr = [<div key="vacuite">{this.genLang({
        fr: 'Aucun résultat pour cette semaine',
        en: 'No results for this week'
      })}</div>]
    outterArr.push(<div key="empty-list" className="calendar-btn" id={'-=-' + 99999}>{arr}</div>);
    // debugger;
    return (outterArr.length) ? outterArr : false;
  }

  initDate() {
    return { weekday: '', date: -1 }
  }

  insertMissingDay(arr, start, end) {
    // debugger;
    let lastDay = start,
      secondArr = [];

    lastDay.hour(2)
    let date;
    for (let a in arr) {

      date = moment(arr[a].day).date();

      if (date > lastDay.date()) {
        // console.log(arr,lastDay._d!==arr[a].day,!lastDay.isAfter(end))
        while (lastDay.date() < date) {
          lastDay.add(1, 'd');
          if (lastDay.date() < date) {
            secondArr.push(lastDay._d);
          }


        }
      }
      // while(lastDay.isBefore(arr[a].day)){
      //     lastDay.add(1,'d');
      //     if(arr[a + 1 ])
      //     secondArr.push(lastDay._d);
      // }

      secondArr.push(arr[a]);
    }

    return arr;
  }

  renderAppointment(appointments) {
    if (this.props.userList.length <= 1)
      return [];

    let arr = [];

    let assignedLawyer = '';
    let str;

    if (this.state.deleteAppointment) {
      for (let a in appointments) {
        if (this.state.deleteAppointment !== appointments[a].id)
          continue;

        if (appointments[a].user)
          str = appointments[a].user.toString();

        assignedLawyer = _.find(this.props.userList, { 'auth_id': str });
        arr.push(<div key={appointments[a].id + 'xyz'}><Alert id={appointments[a].id} color={'warning'}>
          {this.genLang({
            fr: 'Voulez - vous vraiment en finir avec ce rendez-vous?',
            en: 'Do you really want to cancel this appointment?'
          })}<br />{
          assignedLawyer.firstname + ' ' + assignedLawyer.lastname} {moment(appointments[a].start).format('LLL')}{this.genLang({
          fr: ' à ',
          en: ' at '
        })}{moment(appointments[a].end).format('HH:mm')}<br />
          <Button color={"white"} style={{ padding: '0 5px' }} id={appointments[a].id} onClick={(e) => {
            this.setState({ deleteAppointment: false })
          }}>{this.genLang({ fr: 'Restaurer', en: 'Restore' })}</Button>
          <Button color={"danger"} className={"booking-delete"} id={appointments[a].id} onClick={(e) => {
            this.deleteTaken(e.currentTarget.id)
          }}>X</Button>
        </Alert></div>);
      }
    } else if (appointments) {
      appointments = _.filter(
        _.orderBy(appointments, 'start'), (e) => {
          return (moment().isBefore(moment(e.start)));
        });
      let color = '';
      let text = '';
      let str2 = '';

      for (let a in appointments) {

        if (appointments[a].user)
          str2 = appointments[a].user.toString();

        assignedLawyer = _.find(this.props.userList, { 'auth_id': str2 });

        if (!assignedLawyer || !assignedLawyer.lastname || !assignedLawyer.firstname)
          continue;

        if (!_.find(this.state.types, { id: appointments[a].service_id }))
          continue;

        color = (_.find(this.state.types, { id: appointments[a].service_id }).type > 0) ? 'warning' : 'primary';
        text = moment(appointments[a].start).format('LLL') + this.genLang({
          fr: ' à ',
          en: ' at '
        }) + moment(appointments[a].end).format('HH:mm');
        if (appointments[a].multi) {
          color = "success";
          text = moment(appointments[a].start).format('LL A')
        }

        arr.push(<div key={appointments[a].id + 'xyz'}><Alert id={appointments.id} color={color}>{
          assignedLawyer.firstname + ' ' + assignedLawyer.lastname} {text}
          <Button id={appointments[a].id} color={"danger"} className={"booking-delete"} onClick={(e) => {
            ;this.setState({ deleteAppointment: appointments[a].id })
          }}>X</Button>
        </Alert></div>);
      }
    }
    return arr;
    // return arr;
  }

  renderStatusError() {
    return (<div style={{ color: "red", textAlign: "center" }}><strong> Ticket clos ou résolu </strong></div>)
  }

  func(fun) {
    return fun();
  }

  genLang(text, lang) {
    if (!lang)
      lang = this.props.lang;

    if (typeof text === 'string') {
      return text;
    }

    if (typeof text === 'object') {
      if (lang && text[lang]) {
        return text[lang];
      } else {
        console.error('lang missing');
        return ''
      }
    }

    return '';
  }

  render() {

    if (this.state.redirect)
      return (
        <Redirect to={"/expire"} />
      )

    if (this.state.taken) {
      return (<>{
        this.genLang({
          fr: `Rendez-vous ${this.state.RDVDate} avec ${this.state.assignedLawyer}`,
          en: `Appointment ${this.state.RDVDate} with ${this.state.assignedLawyer}`
        })
      }</>)
    }

    return (
      <div id="booking-app" key='booking-client'>
        <div style={{ textAlign: "center" }}>
          {/* <Badge
                        tag={"a"}
                        href={"/calendar"}
                        target={"_blank"}
                        title={"Disponibilités"}
                        color="primary"
                    >
                        <FontAwesomeIcon icon={faCalendar}/> Dispo
                    </Badge>
                    <span> </span>
                    <Badge
                        tag={"a"}
                        href={"/schedule"}
                        target={"_blank"}
                        title={"Mon horaire"}
                        color="primary"
                    >
                        <FontAwesomeIcon icon={faBusinessTime}/> Horaire
                    </Badge>
                    <span> </span>
                    <Badge
                        // tag={"a"}
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            this.receiveLink(e)
                        }}
                        className='a'
                        style={{cursor:'pointer'}}
                        title={"link"}
                        color="primary"
                    >
                        <FontAwesomeIcon icon={faLink}/> RDV
                    </Badge> */}
          {/* <Badge
                        tag={"a"}
                        href={"/logout"+window.location.search}
                        title={"Mon horaire"}
                        color="warning"
                    >
                        <FontAwesomeIcon icon={faDoorOpen}/>
                    </Badge> */}

          {/* <Badge
                        title={"summon"}
                        color="danger"
                        onClick={ () => {
                            console.log(window.store.getState())
                            // let event = new CustomEvent('getTicket');
                            // window.dispatchEvent(event);
                            // console.time('zawarudo');
                            // setInterval(() =>{
                            //     if(window.Neolegal.ticket_id)
                            //         console.timeEnd('zawarudo');
                            //     else
                            //         console.log(' to be continued... ====>')
                            // },1000)
                        }}
                    >
                        <FontAwesomeIcon icon={faHeart}/>
                    </Badge> */}
        </div>
        <ListGroup>
          {this.renderAppointment(this.state.appointments)}
        </ListGroup>
        <Form id="booking">
          <FormGroup
            id="calendarContainer"
            style={
              { textAlign: 'center !important' }
            }
          >
            <h3 style={{ textAlign: 'center' }}>
              {
                this.genLang({
                  fr: 'Veuillez sélectionner une semaine',
                  en: 'Please select a week'
                })
              }
            </h3>
            {/* <div style={{display:this.state.toggle}}> */}
            {this.state.startDate ? <DayPickerRangeController
              enableOutsideDays={false}

              isRTL={false}
              keepOpenOnDateSelect={false}
              maxDate={null}
              //   minDate={null}
              minimumNights={1}
              monthFormat="MMMM YYYY"
              navNext={null}
              navPrev={null}
              numberOfMonths={1}
              orientation="horizontal"
              renderCalendarDay={undefined}
              renderCalendarInfo={null}
              renderDayContents={null}
              renderKeyboardShortcutsButton={undefined}
              renderMonthElement={null}
              renderMonthText={null}
              verticalHeight={undefined}
              withPortal={false}
              minDate={(moment().startOf('isoWeek'))}
              initialVisibleMonth={() => moment(this.state.startDate)}
              startDate={this.state.startDate}
              endDate={this.state.endDate}
              firstDayOfWeek={1}
              onDatesChange={({ startDate, endDate }) => {
                // if(startDate.isAfter(
                //         moment()
                //             .startOf('isoWeek')
                //             .subtract(1,'days')
                //             .endOf('day')
                //     )
                // ){
                // startDate.startOf('isoWeek').startOf('day').add(2,'h');
                // endDate = startDate.clone().add(6,'d');
                this.setState({
                  startDate: startDate,
                  endDate: endDate,
                  selectedWeek: startDate.isoWeek(),
                  selectedYear: startDate.isoWeekYear()
                }, this.getBooks)
                // }
              }}
              // isDayBlocked={(day) => {
              //     return day.isoWeekday() > 1;
              // }}
              focusedInput={this.state.focusedInput}
              onFocusChange={focusedInput => {
                this.setState({ focusedInput })
              }}
              // onOutsideClick={action('DayPickerRangeController::onOutsideClick')}
              // onPrevMonthClick={action('DayPickerRangeController::onPrevMonthClick')}
              // onNextMonthClick={action('DayPickerRangeController::onNextMonthClick')}
              startDateOffset={(day) => day.startOf('isoWeek').startOf('day').add(2, 'h')}
              endDateOffset={(day) => day.endOf('isoWeek').startOf('day').add(2, 'h')}
              hideKeyboardShortcutsPanel={true}
              //initialVisibleMonth={() =>this.state.startDate}
              // initialVisibleMonth={() => moment()}
              // endDateOffset={day => day.add(1, 'weeks')}
            /> : <div style={{ textAlign: 'center', marginTop: '15px' }}><BeatLoader color="#4444ff" size="30" /></div>}

            {/* </div> */}
          </FormGroup>
          <div style={{ textAlign: 'center', paddingTop: '7px' }}>
            <BeatLoader size={18} margin={5} loading={this.state.loading} color="#0052BB" />
          </div>

          <ListGroup>
            {/* <ListGroupItem className="date-mark"><Badge color="info" className="bold">{this.state.duration + ' min'}</Badge></ListGroupItem> */}
            <ListGroupItem className="calendar"
                           style={{
                             textAlign: 'center',
                             margin: '0 auto'
                           }}
            >
              {(this.state.error) ? this.state.error : (this.renderCalendar(this.state.availabilities))}
            </ListGroupItem>
          </ListGroup>
          <FormGroup
            style={{
              textAlign: 'center',
              display: this.state.selectedButton ? BLOCK : NONE
            }}
          >
            <Button
              // size={'lg'}
              // className="badge"
              // style={{

              //     // position:'fixed',
              //     // top:'0px',
              //     // right:'0px',
              //     // // opacity:'1',
              //     // zIndex:'999999',
              //     // padding:'0px !important'
              //     // width:'100%',
              //     // marginTop:"5px"
              // }}
              type="button"
              disabled={!this.state.selectedButton || (window.Neolegal.ticketStatus === 'closed' || window.Neolegal.ticketStatus === 'solved')}
              color={(!!this.state.selectedButton) ? "success" : "danger"}
              onClick={this.book}
            >{!!this.state.selectedButton
              ? this.genLang({ fr: 'Prendre le rendez-vous ', en: 'Take the timeslot ' })
              : this.genLang({ fr: 'Prenez un Rendez-vous ', en: 'Please reserve a timeslot ' })}
              <FontAwesomeIcon icon={(!!this.state.selectedButton) ? faCheckCircle : faBan} />
            </Button>
          </FormGroup>
          <FormGroup
            style={{
              //     position:'fixed',
              //     bottom:'0px',
              //     // opacity:'1',
              //     zIndex:'999999',
              //     width:'100%',
              //     marginTop:"5px"
            }}>
            {this.state.alreadyTaken ? <Alert color={'danger'}>
              {this.genLang({ fr: 'Ce problème est survenu : ', en: 'A problem has occurred: ' })}
              {this.state.alreadyTaken}
            </Alert> : ''}
            {this.state.RDVInfo ? <Alert color={'success'}>
              {this.genLang({
                fr: `Rendez-vous ${this.state.RDVDate} avec ${this.state.assignedLawyer}.`,
                en: `Appointment booked at ${this.state.RDVDate} with ${this.state.assignedLawyer}.`
              })}
            </Alert> : ''}

          </FormGroup>

        </Form>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
  Zendesk: state.Zendesk,
  userList: state.users.userList,
  weekList: state.time.weekList,
  ampm: state.users.result.ampm,
  dispo: state.users.result.dispo,
  admin: state.users.result.admin,
  userId: state.users.result.userId,
  type: state.users.result.type,
  id_zendesk: state.users.result.id_zendesk,
  lang: ownProps.lang ?? state.users.result.language 
}};

const mapActionsToProps = {
  clientInit: rootsActions.clientActions.load,
  setLawyers: rootsActions.usersActions.setLawyers,
}

export default connect(mapStateToProps, mapActionsToProps)(BookingClient);
// export default Booking;
