import React, {Component} from 'react';
import { connect } from 'react-redux';

import { BeatLoader } from 'react-spinners';

const style = {
    textAlign:'center',
    position:'fixed',
    top:0,height:'100vh',
    zIndex:99999,
    width:'100%',
    background:'rgba(123,123,123,0.5)'
}

const OBJ = {};

class Loading extends Component{
    

    render(){

        return (
            <div style={this.props.loading?style:OBJ}>
                <div style={{position:'relative',top:'50vh'}}>
                    <BeatLoader  size={50} margin={5} loading={this.props.loading} color="#0052BB"/>
                </div>
            </div>
        );
    }
}   

const mapStateToProps = state => ({
    loading:state.loading.loading
});

export default connect(mapStateToProps)(Loading);