import React, { Component } from 'react';

import {
  usersReducer,
  clientReducer,
  timeReducer,
  loadingReducer,
  tarifReducer,
  invoiceReducer
} from './_reducers/root-reducer';
import {Provider} from 'react-redux';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

import './App.css';

import {combineReducers,createStore} from 'redux';

import moment  from 'moment-timezone';

import "./assets/vendor/nucleo/css/nucleo.css";
import "./assets/vendor/@fortawesome/fontawesome-free/css/all.min.css";
import "./assets/scss/argon-dashboard-react.scss";
import SilkRoad from './route'

import Loading from './component/utils/Loading';

const reducers = combineReducers({
  Zendesk:clientReducer,
  users:usersReducer,
  time:timeReducer,
  loading:loadingReducer,
  tarif:tarifReducer,
  invoices:invoiceReducer
});
const ALL = 'ALL';

const decodedToken = window.parseJwt(localStorage.getItem('token'))||{scope:{}}

moment.locale(decodedToken.scope.language||'fr');
var thisWeek = moment().startOf('isoWeek'),
  arr = [{
    label:'#'+thisWeek.isoWeek()+', '+thisWeek.format('LL'),
    value:thisWeek.isoWeek(),
    start:thisWeek.clone().add(2,'hours').format(),
    end:thisWeek.clone().startOf('isoWeek').startOf('day').add(2,'hours').format(),
    year:thisWeek.isoWeekYear()
}],
  newDay;

  
  
for(let i = 0;++i<=52;){
  newDay = thisWeek.clone().add(i,'w');
  arr.push({
      label:'#'+newDay.isoWeek()+', '+newDay.format('LL'),
      start:newDay.clone().add(2,'hours').format(),
      end:newDay.clone().endOf('isoWeek').startOf('day').add(2,'hours').format(),
      value:newDay.isoWeek(),
      year:newDay.isoWeekYear(),
      isDST:newDay.isDST()
  })
}

export const store = createStore(reducers,{
  invoices:{
    manual:[],
    automatic:[],
    list:[
      // {
      //   id:'1',
      //   invoiceId:'1',
      //   date:'2001-01-01',
      //   approved:1,
      //   done:1,
      //   type:1,
      //   "invoice_number":'122132',
      //   "approved":'1',
      //   "creation_date":'2010-01-01',
      // }
    ]
  },
  tarif:{
    global:[],
    negotiated:[]
  },
  users:{
    
    "neolegalExclusive":ALL,
   "fieldChange":{},
   "userList":[{auth_id: -1,firstname:"Tous",lastname:' '}],
   "toggle":false,
   "status": !!localStorage.getItem('token'),
   "result": {
      "language": decodedToken.scope['language']||'fr',
        "userId": decodedToken['sub'],
       "email": decodedToken.scope['email'],
       "token": decodedToken.scope['token'],
       "id_zendesk": decodedToken.scope['id_zendesk'],
       "admin": decodedToken.scope['admin'],
       "ampm": decodedToken.scope['ampm'],
       "dispo": decodedToken.scope['dispo'],
       "type": decodedToken.scope['type']||'sales',
   },
   categories:[{id: 1, name: "Defaut"}],
   "work":[
    {
      "title": "1er RDV Med/Lettre Famille",
      "id": 30,
      "type": 0,
      "duration": 4,
      "specialities": [
        {
          "id": 5
        }
      ]
    },
    {
      "title": "1er RDV Parler avocat travail",
      "id": 29,
      "type": 0,
      "duration": 3,
      "specialities": [
        {
          "id": 15
        }
      ]
    },
    {
      "title": "1er RDV Med / Réponse Med",
      "id": 1,
      "type": 0,
      "limit": 4,
      "duration": 2,
      "specialities": [
        {
          "id": 13
        }
      ]
    },
    {
      "title": "1er RDV Négo",
      "id": 2,
      "type": 0,
      "duration": 2,
      "specialities": [
        {
          "id": 13
        }
      ]
    },
    {
      "title": "1er RDV Révision de doc",
      "id": 3,
      "type": 0,
      "duration": 2,
      "specialities": [
        {
          "id": 13
        }
      ]
    },
    {
      "title": "1er RDV VC",
      "id": 4,
      "type": 0,
      "duration": 3,
      "specialities": [
        {
          "id": 13
        }
      ]
    },
    {
      "title": "1er RDV Parler avocat civil/criminel",
      "id": 5,
      "type": 0,
      "duration": 3,
      "specialities": [
        {
          "id": 3
        }
      ]
    },
    {
      "title": "1er RDV Parler avocat Familial",
      "id": 6,
      "type": 0,
      "duration": 5,
      "specialities": [
        {
          "id": 5
        }
      ]
    },
    {
      "title": "1er RDV PC / PC VC",
      "id": 7,
      "type": 0,
      "duration": 4,
      "specialities": [
        {
          "id": 6
        }
      ]
    },
    {
      "title": "1er RDV Régie",
      "id": 8,
      "type": 0,
      "duration": 4,
      "specialities": [
        {
          "id": 7
        }
      ]
    },
    {
      "title": "1er RDV CQ",
      "id": 28,
      "type": 0,
      "duration": 4,
      "specialities": [
        {
          "id": 8
        }
      ]
    },
    {
      "title": "1er RDV Incorporation",
      "id": 9,
      "type": 0,
      "duration": 3,
      "specialities": [
        {
          "id": 9
        }
      ]
    },
    {
      "title": "1er RDV Rédaction contrat de services",
      "id": 25,
      "type": 0,
      "duration": 4,
      "specialities": [
        {
          "id": 10
        }
      ]
    },
    {
      "title": "1er RDV Rédaction contrat de travail",
      "id": 26,
      "type": 0,
      "duration": 4,
      "specialities": [
        {
          "id": 11
        }
      ]
    },
    {
      "title": "1er RDV Rédaction entente confidentialité",
      "id": 27,
      "type": 0,
      "duration": 4,
      "specialities": [
        {
          "id": 12
        }
      ]
    },
    {
      "title": "Rédaction Med",
      "id": 10,
      "type": 1,
      "duration": 3,
      "specialities": [
        {
          "id": 0
        }
      ]
    },
    {
      "title": "Rédaction VC",
      "id": 11,
      "type": 1,
      "duration": 4,
      "specialities": [
        {
          "id": 0
        }
      ]
    },
    {
      "title": "Rédaction PC et Régie",
      "id": 12,
      "type": 1,
      "duration": 5,
      "specialities": [
        {
          "id": 0
        }
      ]
    },
    {
      "title": "Rédaction PC",
      "id": 13,
      "type": 1,
      "duration": 6,
      "specialities": [
        {
          "id": 0
        }
      ]
    },
    {
      "title": "Rédaction Incorporation",
      "id": 14,
      "type": 1,
      "duration": 4,
      "specialities": [
        {
          "id": 0
        }
      ]
    },
    {
      "title": "Rédaction Incorporation journée",
      "id": 15,
      "type": 1,
      "duration": 7,
      "specialities": [
        {
          "id": 0
        }
      ]
    },
    {
      "title": "2ème RDV PC",
      "id": 16,
      "type": 0,
      "duration": 4,
      "specialities": [
        {
          "id": 0
        }
      ]
    },
    {
      "title": "Suivi / Appel client",
      "id": 17,
      "type": 2,
      "duration": 1,
      "specialities": [
        {
          "id": 0
        }
      ]
    },
    {
      "title": "Analyse Dossier",
      "id": 18,
      "type": 1,
      "duration": 3,
      "specialities": [
        {
          "id": 0
        }
      ]
    },
    {
      "title": "Recherche",
      "id": 19,
      "type": 1,
      "duration": 1,
      "specialities": [
        {
          "id": 0
        }
      ]
    },
    {
      "title": "Appel partie adverse",
      "id": 20,
      "type": 2,
      "duration": 1,
      "specialities": [
        {
          "id": 0
        }
      ]
    },
    {
      "title": "Modification à la rédaction",
      "id": 21,
      "type": 1,
      "duration": 1,
      "specialities": [
        {
          "id": 0
        }
      ]
    },
    {
      "title": "RDV préparation audition PC",
      "id": 22,
      "type": 0,
      "duration": 4,
      "specialities": [
        {
          "id": 0
        }
      ]
    },
    {
      "title": "Envoi pièce",
      "id": 23,
      "type": 1,
      "duration": 1,
      "specialities": [
        {
          "id": 0
        }
      ]
    },
    {
      "title": "Vacation à la cour",
      "id": 24,
      "type": 1,
      "duration": 4,
      "specialities": [
        {
          "id": 0
        }
      ]
    },
    {
      "title": "Autre",
      "id": 0,
      "type": 2,
      "duration": 1,
      "specialities": [
        {
          "id": 0
        }
      ]
    }
  ],
  "durations":[],
  "visible":decodedToken.scope.admin? [{ value: -1, label: "Tous" }]
    : [
        {
          value: parseInt(decodedToken['sub']),
          label: "Moi"
        }
      ],
  },
  Zendesk:{init:() => false},
  time:{
    currentWeek:moment().isoWeek(),
    currentYear:moment().isoWeekYear(),
    currentMonday:moment().startOf('isoWeek').add(2,'hours'),
    isDST:moment().startOf('isoWeek').isDST(),
    weekList:arr
  },
  loading:{
   "loading":false,
  }
});

class App extends Component {

  render() {
    return (
      <Provider store={store}>
        <SilkRoad/>
        <Loading/>
      </Provider>
    );
  }

}

// eslint-disable-next-line 
const mapStateToProps = state => ({
  users:state.users,
});

export default App;

