
import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faBan} from '@fortawesome/free-solid-svg-icons';
import {
    danger,
    success,
    colorRed,
    colorGreen
} from '../../_const/index'
export default class ValidationIcon extends Component {
    constructor(props){
        super(props);

        this.state={
            icon:this.props.valid?faCheckCircle:faBan,
            color:this.props.valid?colorGreen:colorRed
        }
    }
    render(){
        return <span 
            style={{
                fontSize:'12px',
                marginLeft:'5px',

            }}
            color={this.props.valid?success:danger}
            className={this.state.color}    
        ><FontAwesomeIcon icon={this.state.icon} /></span>
    }

    componentDidUpdate(prevProps){
        if(this.props.valid !== prevProps.valid) {
            this.setState({
                icon:(this.props.valid)?faCheckCircle:faBan,
                color:(this.props.valid)?colorGreen:colorRed,
            })
        }
    }
    
}