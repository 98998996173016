import React, { Component } from 'react';

import {
    Alert,
    Form,
    FormGroup,
    Label,
    Button,
    ListGroup,
    ListGroupItem,
    Badge,
    Input
} from 'reactstrap';


import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import {DayPickerRangeController} from 'react-dates';
import Select from 'react-select';

import { connect } from 'react-redux';

import rootsActions from '../_actions/root-actions';

import _ from 'lodash';
import {privateAxios as axios} from '../utils/axios';
import moment  from 'moment-timezone';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faBusinessTime, faCalendar, faCheckCircle, faBan,faLink,faDoorOpen} from '@fortawesome/free-solid-svg-icons';
import { BeatLoader } from 'react-spinners';

import {
    NEO_CALENDAR,   
    CALENDAR_EVENT_TIME,    
    CALENDAR_EVENT_TIME_ZONE,   
    CALENDAR_EVENT_DATE,    
    CALENDAR_EVENT_DURATION,
    CALENDAR_EVENT_LAWYER,
    LAWYER_IN_CHARGE, 
    NEO_RDV
}
from '../data/zendeskFields';
import { Redirect } from 'react-router-dom';

const STRING = 'string';
const ALL_B2B = -2;
const OBJECT = 'object';
const FR = 'fr'
const orange = "#FFD186";
const white = "#efefef";
const customStyles = {
    control: styles => ({ ...styles, backgroundColor: 'white' }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      const color = data.color;
      return {
        ...styles,
        backgroundColor: isDisabled
          ? null
          : data.type === 'elawyer'
          ? color
          : isFocused
          ? color
          : null,
        // color: isDisabled
        //   ? '#ccc'
        //   : isSelected
        //   ? chroma.contrast(color, 'white') > 2
        //     ? 'white'
        //     : 'black'
        //   : data.color,
        cursor: isDisabled ? 'not-allowed' : 'default',
  
        ':active': {
          ...styles[':active'],
          backgroundColor: !isDisabled && (isSelected ? data.color : data.color),
        },
      };
    },
    // input: styles => ({ ...styles, ...dot() }),
    // placeholder: styles => ({ ...styles, ...dot() }),
    // singleValue: (styles, { data }) => ({ ...styles, ...dot(data.color) }),
  };

  const options = [
        { value: '15', label: '15min' },
        { value: '30', label: '30min' },
        { value: '45', label: '45min' },
        { value: '60', label: '60min' },
    ];

const BLOCK = 'block';
const NONE = 'none';

class Booking extends Component{
    
    constructor(props){

        super(props);

        this.state ={
            availabilities:[],
            startDate:moment().startOf('isoWeek').startOf('day').add(2,'hours'),
            endDate: moment().endOf('isoWeek').startOf('day').add(2,'hours'),
            selectedButton:"",
            selectedUser:-1,
            duration:options[1].value,
            durations: [
                { value: '15', label: '15min' },
                { value: '30', label: '30min' },
                { value: '45', label: '45min' },
                { value: '60', label: '60min' }
            ],
            filter:{
                type:1,
                speciality:0,
                user:-1,
                rdvType:0
            },
            filterType:1,
            filterSpeciality:false,
            filterUser:false,
            focusedInput:null,
            loading:false,
            error:false,
            toggle:'block',
            userList:[{auth_id: -1,firstname:"Tous",lastname:' '}],
            alreadyTaken:false,
            RDVInfo:false,
            RDVDate:false,
            assignedLawyer:false,
            textArea:"",
            ampm:(localStorage.getItem('ampm') === 'true'||this.props.ampm)?true:false,
            selectedWeek:moment().isoWeek(),
            selectedYear:moment().startOf('isoWeek').isoWeekYear(),
        }

        this.handleDateChange = this.handleDateChange.bind(this);
        this.onClick = this.onClick.bind(this);
        this.onChange = this.onChange.bind(this);
        this.handleSelect = this.handleSelect.bind(this)
        this.renderCalendar = this.renderCalendar.bind(this);
        this.renderAppointment = this.renderAppointment.bind(this);
        this.deleteTaken = this.deleteTaken.bind(this);
        this.onWeekChange = this.onWeekChange.bind(this);
        this.lawyerCallback = this.lawyerCallback.bind(this);
        this.genLang = this.genLang.bind(this);


    }
    
    componentDidMount(){
        
        let uID = this.props["userId"];

        if(!uID){
          let token = window.parseJwt(localStorage.getItem('token'));
          
          uID=token.sub
          
        }    

        
        
       // console.log('componentDidMount')
       if(true||!localStorage.getItem('lawyerData')){ 
           
            this.props.clientInit();
            axios.get(
                'lawyers',
                {params:{auth_id:uID}}
            )
            .then(this.lawyerCallback);
        }
        else{
            this.lawyerCallback(JSON.parse(localStorage.getItem('lawyerData')))
        }

    }

    lawyerCallback(response){
        
        if(response.redirect){
            this.setState({
                redirect:response.to
            })
        }

        if( response && response.data && response.data.data[0]){

            localStorage.setItem('lawyerData',JSON.stringify(response));
            let u = _.find(response.data.data[0].lawyers,{'auth_id':this.props.userId});
            let filter = this.state.filter
            if(u && u.type !== 'sales'){
                filter.user = parseInt(this.props.userId);
            }
            
            this.props.setLawyers({
            params: {
                lawyers: [
                    {
                        auth_id: -1,
                        firstname: "Tous",
                        lastname: " ",
                        color: "#545454"
                    },
                    {
                        auth_id: ALL_B2B,
                        firstname: "Tous (B2B)",
                        lastname: " ",
                        color: "#898989"
                    },
                    ...response.data.data[0].lawyers
                ]
            }
            });

            this.setState({
                types:[...response.data.data[0].types],
                specialities:[...response.data.data[0].specialities],
                durations:[...response.data.data[0].durations],
                filter:filter
            },
                () => {
                    this.getWithCallback(this.getBooks,this);   
                }
            );
        }
    }

    genLang(text,lang){
        if(!lang)
            lang = this.props.lang;

        if(typeof text === STRING){
            return text;
        }
    
        if(typeof text === OBJECT){
            if(lang && text[lang]){ 
                return text[lang];
            }
            else{
                console.error('lang missing');
                return ''
            }
        }
    
        return '';
    }

    handleSelect(e,loading=true){
        
        this.setState({
                duration:_.find(this.state.durations,{id:e.id}).value,
                loading:loading,
                change:true
            },
            () => {
                if(loading)
                    this.getBooks();
            }    
        );
    }

    onChange(e){
        
        let obj = {...this.state.filter};
        
        if(e.name !== 'type'){
            obj[e.name] = e.value;
        }
        else{
            obj['type'] = parseInt(e.value);
            obj['rdvType'] = parseInt(e.type);
            
            this.handleSelect({
                id:e.duration
            },false)
        }
        
        let state = {
            filter:obj,
            rdvType:obj['type'],
            filterType:obj['type'],
            filterSpeciality:obj['speciality'],
            filterUser:obj['user'],
            loading:true,
            change:true
        }

        this.setState(
            state,
            () => {
           
           this.getBooks();
        });
    }

    onWeekChange(e){
        this.setState({
            startDate:moment(e.start),
            endDate:moment(e.start).endOf('isoWeek'),
            selectedWeek:e.value,
            selectedYear:e.year,
            change:true
        },() => {
            this.getBooks();
        });
    }

    onClick(e){
        //in double
        let event = new CustomEvent('getCurrentComment', 
            { detail:{
                field:"ticket.comment",
                neoName:'currentComment'
            }}
        );
        window.dispatchEvent(event);
        
        let event2 = new CustomEvent('getTicket');
        window.dispatchEvent(event2);

        this.setState({
            selectedButton:e.currentTarget.id,
            multi:(e.currentTarget.attributes.multi.value==='true')?true:false,
            selectedButtonEnd:e.currentTarget.attributes.end.value,
            selectedUser:parseInt(e.currentTarget.attributes.user.value)
        });
    }

    closestAppointment(appointments,userList){
        // let rejectArr = _.reject(appointments,{id:id});
        let moreRecent;
        appointments = _.orderBy(
            _.filter(appointments,(e) => e.service_id < 999)
        ,'start');

        if(appointments&&appointments.length){
            moreRecent = _.find(appointments,(e) => {
                return moment().isBefore(moment(e.start))
            });

            if(moreRecent){
                let u = _.find(userList,{'auth_id':moreRecent.user});
                if(u)
                    u = u.firstname +' ' +u.lastname;

                this.setTicket(NEO_CALENDAR,moreRecent.start);
                this.setTicket(CALENDAR_EVENT_TIME,moment(moreRecent.start).format('LT'));
                this.setTicket(CALENDAR_EVENT_TIME_ZONE,moment.tz.guess());
                this.setTicket(CALENDAR_EVENT_DATE,moment(moreRecent.start).set('hour', 12).toDate());
                this.setTicket(CALENDAR_EVENT_DURATION,moreRecent.duration);
                this.setTicket(CALENDAR_EVENT_LAWYER,u);
            }
        }
    }

    deleteTaken(id){

        // code de moi qui valide avec un modale ou quelque chose....
        if(!id)
            return;

        let uID = this.props["userId"];

        if(!uID){
            let token = window.parseJwt(localStorage.getItem('token'));
            
            uID=token.sub  
        }    

        axios
            .delete('availabilities',{
                params:{
                    id:id,
                    auth_id:uID
                }
            })
            .then((response) => {
                let rejectArr = _.reject(this.state.appointments,{id:id});
                let moreRecent;
                rejectArr = _.orderBy(rejectArr,'start');

                if(rejectArr&&rejectArr.length){
                    moreRecent = _.find(rejectArr,(e) => {
                        return moment().isBefore(moment(e.start))
                    });

                    if(moreRecent){
                        let u = _.find(this.props.userList,{'auth_id':moreRecent.user});
                        if(u)
                            u = u.firstname +' ' +u.lastname;

                        this.setTicket(NEO_CALENDAR,moreRecent.start);
                        this.setTicket(CALENDAR_EVENT_TIME,moment(moreRecent.start).format('LT'));
                        this.setTicket(CALENDAR_EVENT_TIME_ZONE,moment.tz.guess());
                        this.setTicket(CALENDAR_EVENT_DATE,moment(moreRecent.start).set('hour', 12).toDate());
                        this.setTicket(CALENDAR_EVENT_DURATION,moreRecent.duration);
                        this.setTicket(CALENDAR_EVENT_LAWYER,u);
                    }
                }
                
                if(!rejectArr||!rejectArr.length||!moreRecent){
                    this.setTicket(NEO_CALENDAR,null);
                    this.setTicket(CALENDAR_EVENT_TIME,null);
                    this.setTicket(CALENDAR_EVENT_TIME_ZONE,null);
                    this.setTicket(CALENDAR_EVENT_DATE,null);
                    this.setTicket(CALENDAR_EVENT_DURATION,null);
                    this.setTicket(CALENDAR_EVENT_LAWYER,null);
                }

                //je m'attends à un objet data avec les nouvelles availabilities et les nouveaux taken
                // si j'annule un RDV, ça veut dire qu'une dispo est de retour est sur le marché!
                this.getBooks()
            });

    }

    getBooks(e){
        
        
        this.setTicket(NEO_RDV,'');

        let uID = this.props["userId"];

        if(!uID){
          let token = window.parseJwt(localStorage.getItem('token'));
          
          uID=token.sub  
        }    
        
        let params = {
            default:false,
            auth_id:uID,
            week:this.state.selectedWeek,
            year:this.state.selectedYear,
            duration:this.state.duration,
            ticket_id:window.Neolegal.ticket_id,
            client_id:(window.Neolegal.client)?window.Neolegal.client.id:''
        };

        if(this.state.filter.type>-1)
            params.type = this.state.filter.type;

        if(this.state.filter.speciality > -1)
            params.speciality = this.state.filter.speciality;
        
        if(this.state.filter.user> 0)
            params.user = this.state.filter.user;
        
        axios.get(
            'availabilities',
            {params:params}
        )
        .then( (response) => {

            if(response && response.data){
                let arr = [],
                    av = response.data.data.taken;

                for(let a in av){
                    for(let t in av[a].taken){
                        arr.push({
                            ...av[a].taken[t],
                            user:av[a].user
                        })
                    }
                }

                if(!window.Neolegal.ticket_id){
                    arr =[]
                }

                this.setState({
                    loading:false,
                    availabilities:response.data.data.availabilities,
                    appointments:arr,
                    error:false,
                    deleteAppointment:false,
                },() => {
                    this.closestAppointment(this.state.appointments,this.props.userList)
                })
            }
            else{
                this.setState({
                    deleteAppointment:false,
                    loading:false,
                    availabilities:[],
                    error:<><div>Erreur, faîtes la tâche manuellement,<br></br> puis avisez un IT</div></>
                })
            }
        });
    }

    receiveLink(e){
        // if(!this.props.Zendesk)
        //     this.props.clientInit();

        let uID = this.props["userId"];

        if(!uID){
          let token = window.parseJwt(localStorage.getItem('token'));
          
          uID=token.sub  
        }    
        
        let appText = this.state.textArea;

        let params = {
            // default:true,
            auth_id:uID,
            week:this.state.selectedWeek,
            year:this.state.selectedYear,
            duration:this.state.duration,
            ticket_id:this.state.forcedTicket,
            client_id:this.state.forcedClient,
            id_zendesk:null,
            title:window.Neolegal.subject,
            organizerRole:this.props.type,
            text:appText,
            client:this.state.client,
            //nice to have!
            // description:window.Neolegal.description,
            typeName:_.find(this.state.types,{id:parseInt(this.state.filter.type)}).title
        };

        if(this.state.filter.type>-1)
            params.type = this.state.filter.type;

        if(this.state.filter.speciality > -1)
            params.speciality = this.state.filter.speciality;
        
        if(this.state.filter.user> 0)
            params.user = this.state.filter.user;
        
        axios.get(
            'bookurl',
            {params:params}
        )
        .then( (response) => {
            
            if(response && response.data && response.data.data){

                this.setState({
                    loading:false,
                    link:response.data.data.url,
                    change:false,
                    error:false,
                    deleteAppointment:false,
                }, () => {
                    var copyText = document.getElementById("copy");

                    copyText.select();
                    copyText.setSelectionRange(0, 99999); 
                    document.execCommand("copy");

                    this.setTicket(NEO_RDV,response.data.data.url);
                    
                })
            }
            else{
                this.setState({
                    deleteAppointment:false,
                    loading:false,
                    availabilities:[],
                    error:<><div>Erreur, faîtes la tâche manuellement,<br></br> puis avisez un IT</div></>
                })
            }
        });
        
    }

    book(e){
        let uID = this.props["userId"];

        if(!uID){
          let token = window.parseJwt(localStorage.getItem('token'));
          
          uID=token.sub;
          
        }    

        this.setState({
            loading:true
        });
        
        let isDST = moment(moment(this.state.selectedButton).clone().format());
        isDST = isDST.isDST();

        let appText = (this.state.textArea)?this.state.textArea:(window.Neolegal.commentType === 'internalNote')?window.Neolegal.currentComment:'';

        axios.post(
            'availabilities',{
                week:this.state.startDate.clone().week(),
                day:moment(this.state.selectedButton).startOf('day').add((isDST)?2:1,'hours').utc().format("YYYY-MM-DDTHH:mm:ss+00:00"),
                user:this.state.selectedUser,
                auth_id:uID,
                taken:{
                    "title" : 'Rendez-vous pris manuellement',
                    "description" : window.Neolegal.description,
                    "group" : {
                        "id" : "111111-22222-efefef-123456",
                        "name" : "PNC"
                    },
                    "service_id":this.state.filter.type,
                    "service" : _.find(this.state.types,{id:parseInt(this.state.filter.type)}).title,
                    "service_type":_.find(this.state.types,{id:parseInt(this.state.filter.type)}).type.toString(),
                    "start" : this.state.selectedButton,
                    "end" : this.state.selectedButtonEnd,
                    "TimeZone" : "America/Toronto",
                    "duration" : this.state.duration,
                    "where" : "montreal",
                    "organizer" : {
                        "id_zendesk":this.props.id_zendesk||localStorage.getItem('id_zendesk'),
                        "id" : uID,
                        "role" : this.props.type
                    },
                    "ics_url" : "url?",
                    "ticket_id" : this.state.forcedTicket,
                    "app_subject" :window.Neolegal.subject,
                    "app_description" : appText,
                    "client":{id:parseInt(this.state.forcedClient)},
                    "multi":this.state.multi
                }
            }
        )
        
        .then( (response) => {
            if(response && response.status === 200 && !response.data.error){

                let assignedLawyer = _.find(this.props.userList, { 'auth_id': this.state.selectedUser.toString() });

                if(!assignedLawyer)
                    return console.error('no user selected');

                let name = '';
                if(assignedLawyer && assignedLawyer.firstname)
                    name = assignedLawyer.firstname+' '+ assignedLawyer.lastname;

                this.updateNeoCalendar(
                    this.state.selectedButton,
                    assignedLawyer.id_zendesk,
                    _.find(this.state.types,
                    {id:this.state.filter.type}),
                    name,
                    this.state.duration
                );
                this.setState({
                    alreadyTaken:false,
                    RDVInfo:true,
                    RDVDate:moment(this.state.selectedButton).format('LLL'),
                    assignedLawyer:(assignedLawyer)?assignedLawyer.firstname + ' '+ assignedLawyer.lastname:'',
                    selectedUser:false,
                    selectedButton:false,
                    selectedButtonEnd:false,
                    multi:false
                },() => {
                    this.getBooks();
                });
            
            }
            else{
            

                this.setState({
                    alreadyTaken:(response.data)?response.data.error:'Erreur',
                    RDVInfo:false,
                    RDVDate:false,
                    assignedLawyer:false,
                    selectedUser:false,
                    selectedButton:false,
                    selectedButtonEnd:false,
                    multi:false
                });
            }
        });
    }

    getWithCallback(callback,self,params){
        
        if(window.Neolegal.ticket_id||this.state.forcedTicket){
            // console.log('ULTIMATE CHANCE')
            return callback.call(self);
        }
        
        let event = new CustomEvent('getWithCallback', 
            {detail:{callback:callback,self:self,params:params}}
        );
        
        window.dispatchEvent(event);

    }

    setTicket(field,value){
        let event = new CustomEvent('setTicket', 
            { detail:{
                field:field,
                value:value
            }}
        );
        
        window.dispatchEvent(event);

    }

    updateNeoCalendar(date,lawyer,type,name,duration){
        // if(window.Neolegal.nextAppointment){
            
            let m;
            if(window.Neolegal.nextAppointment)
                m = (moment().isAfter(moment(window.Neolegal.nextAppointment))||moment(window.Neolegal.nextAppointment).isAfter(moment(date)))
            else
                m = true;

            if(m){
                
                window.Neolegal.nextAppointment = date;
                this.setTicket(NEO_CALENDAR,date);
                this.setTicket(CALENDAR_EVENT_TIME,moment(date).format('LT'));
                this.setTicket(CALENDAR_EVENT_TIME_ZONE,moment.tz.guess());
                this.setTicket(CALENDAR_EVENT_DATE,moment(date).set('hour', 12).toDate());
                this.setTicket(CALENDAR_EVENT_DURATION,duration);
                this.setTicket(CALENDAR_EVENT_LAWYER,name);
                

            }

            if(!window.Neolegal.lawyerInCharge&&lawyer&&type.type!==1){
                
                this.setTicket(LAWYER_IN_CHARGE,lawyer);
            }
        // }

    }

    handleDateChange(e){
        return false;
    }

    genTitle(color){
        // debugger;
        if(color === '#E34F32')
            return "Interne (hors dispo)"
        else if(color === '#000' ||color === '#000000')
            return "Externes"
        else
         return "Interne (heures ouvrables)"
        
    }

    renderCalendar(availabilities){
        
        availabilities = _.uniq(availabilities,'start');

        if(availabilities && !availabilities.length && this.state.types){
            let type = _.find(this.state.types,{id:this.state.filter.type}).type;
            
            if(type === 1 && this.state.filter.user > 0)
                return (<><strong>Le service demandé nécessite un usager précis!</strong></>)
            else if(type === 0)
                return (<><strong>{this.genLang({fr:"Aucune disponibilité",en:'No availabilities'})}</strong></>)
        }
        
        if(this.state.types){
            let isAMPM = (_.find(this.state.types,{id:this.state.filter.type}).type > 0 && this.state.filter.user>0);
            if(this.state.ampm && isAMPM ){
                let i = this.state.startDate.clone();
                while(i.isBefore(this.state.endDate)){
                    // no fds
                    if(i.isoWeekday()<6){
                        
                        availabilities.push({
                            "user": this.state.filter.user,
                            "day":i.clone().hour(6).format(),
                            "busy": 0,
                            "start":i.clone().startOf('day').minute(15).format(),
                            "end":i.clone().startOf('day').minute(30).format(),
                            "duration": "0",
                            "color": "",
                            "multi":true,
                            "text":"AM"
                        });

                        availabilities.push({
                            "user": this.state.filter.user,
                            "day":i.clone().hour(6).format(),
                            "busy": 0,
                            "start":i.clone().startOf('day').minute(30).format(),
                            "end":i.clone().startOf('day').minute(45).format(),
                            "duration": "0",
                            "color": "",
                            "multi":true,
                            "text":"PM"
                        })
                    }
                    i.add(1,'d');
                }
            }
        }
        let arr = [],
            outterArr = [],
            day = this.initDate(),
            tempAvailable;
            
        availabilities = _.filter(
            _.sortBy(availabilities, function(dateObj) {
                    return new Date(dateObj.start);
            }), (e) => {

                return ((e.multi&&moment().startOf('day').isSameOrBefore(e.start))||moment().isBefore(moment(e.start)));
        });
        
        for(let a in availabilities){
                if(day.weekday && day.weekday !== moment(availabilities[a].start).isoWeekday()){
                    outterArr.push(<div 
                        className="calendar-btn" id={'-=-'+a} key={'-=-'+a}>{arr}</div>);
                    arr = [];
                    day = this.initDate();
                    tempAvailable = undefined;
                }

                if(!day.weekday&& day.weekday !== 0){
                    tempAvailable = moment(availabilities[a].start);
                    day.weekday = tempAvailable.weekday();
                    day.date = tempAvailable.day();
                    arr.push(<Badge color="info" 
                    className="bold badge-block" key={day.date+a} >{tempAvailable.format('dd DD')}</Badge>)
                }

                if(availabilities[a].multi){
                    if(availabilities[a].text === 'PM'){
                        availabilities[a].start = moment(availabilities[a].start).add(12,'h').format()
                        availabilities[a].end = moment(availabilities[a].end).add(12,'h').format()
                    }   
                        

                    arr.push(<Button 
                        onClick={this.onClick}
                        user={availabilities[a].user}
                        id={availabilities[a].start}
                        multi={"true"}
                        end={availabilities[a].end}
                        key={availabilities[a].start+'-'+a}
                        // title={
                        //     this.genTitle(availabilities[a].color)
                        // }
                        color={
                            this.state.selectedButton===availabilities[a].start?
                                "neo-orange":(availabilities[a].color)?
                                availabilities[a].color.slice(1):"neo-blue"
                        }
                        // style={{backgroundColor:'#ffcccc !Important'}}
                        // style={(availabilities[a].color)?{backgroundColor:availabilities[a].color+' !important'}:{}}
                        >
                        {availabilities[a].text}
                    </Button>);
                }
                else{
                    arr.push(<Button 
                        onClick={this.onClick}
                        user={availabilities[a].user}
                        id={availabilities[a].start}
                        end={availabilities[a].end}
                        key={availabilities[a].start+'-'+a}
                        multi={"false"}
                        title={
                            this.genTitle(availabilities[a].color)
                        }
                        color={
                            this.state.selectedButton===availabilities[a].start?
                                "neo-orange":(availabilities[a].color)?
                                availabilities[a].color.slice(1):"neo-blue"
                        }
                        style={{padding:'0 3px'}}
                        // style={(availabilities[a].color)?{backgroundColor:availabilities[a].color+' !important'}:{}}
                        >
                            {moment(availabilities[a].start).format('HH[:]mm')}
                        </Button>);
                }


            // }
        }
        
        outterArr.push(<div className="calendar-btn" id={'-=-'+99999} key={'-=-'+99999}>{arr}</div>);
        // debugger;
        return (outterArr.length)?outterArr:false;
    }

    initDate(){
        return {weekday:'',date:-1}
    }

    insertMissingDay(arr,start,end){
        // debugger;
        let lastDay = start,
            secondArr = [];

        lastDay.hour(2)
        let date;
        for(let a in arr){
            
            date = moment(arr[a].day).date();

            if( date > lastDay.date() ){ 
                // console.log(arr,lastDay._d!==arr[a].day,!lastDay.isAfter(end))
                while(lastDay.date()<date){
                    lastDay.add(1,'d'); 
                    if(lastDay.date()<date){
                        secondArr.push(lastDay._d);
                    }
                        

                }
            }
            // while(lastDay.isBefore(arr[a].day)){
            //     lastDay.add(1,'d'); 
            //     if(arr[a + 1 ])
            //     secondArr.push(lastDay._d);
            // }

            secondArr.push(arr[a]);
        }
        
        return arr;
    }

    renderAppointment(appointments){
        if(this.props.userList.length <= 1)
            return [];

        let arr = [];

        let assignedLawyer = '';
        let str;
        if(this.state.deleteAppointment){
            for(let a in appointments){
                if(this.state.deleteAppointment !== appointments[a].id)
                    continue;

                if(appointments[a].user)
                    str = appointments[a].user.toString();

                assignedLawyer = _.find(this.props.userList, { 'auth_id': str });
                arr.push(<div key={appointments[a].id+'xyz'} ><Alert  id={appointments[a].id} color={'warning'}>
                Voulez-vous vraiment en finir avec ce rendez-vous?<br />{
                    assignedLawyer.firstname +' '+assignedLawyer.lastname} {moment(appointments[a].start).format('LLL')} à {moment(appointments[a].end).format('HH:mm')}<br />
                    <Button color={"white"} style={{padding:'0 5px'}} id={appointments[a].id} onClick={(e) => {this.setState({deleteAppointment:false})}}>Restaurer</Button>
                    <Button color={"danger"} className={"booking-delete"}  id={appointments[a].id} onClick={(e) => {this.deleteTaken(e.currentTarget.id)}}>X</Button>                    
                </Alert></div>);
            }
        }
        else if(appointments){
            appointments = _.filter(
                _.orderBy(appointments,'start'), (e) => {
                    return (moment().isBefore(moment(e.start)));
            });
            let color = '';
            let text = '';
            let str2 = '';

            for(let a in appointments){

                if(appointments[a].user)
                    str2 = appointments[a].user.toString();

                assignedLawyer = _.find(this.props.userList, { 'auth_id': str2 });
                
                if(!assignedLawyer || !assignedLawyer.lastname||!assignedLawyer.firstname)
                    continue;

                if(!_.find(this.state.types,{id:appointments[a].service_id}))
                    continue;

                color = (_.find(this.state.types,{id:appointments[a].service_id}).type > 0)?'warning':'primary';
                text = moment(appointments[a].start).format('LLL') +' à '+moment(appointments[a].end).format('HH:mm');
                if(appointments[a].multi){
                    color="success";
                    text = moment(appointments[a].start).format('LL A')
                }
                    
                arr.push(<div key={appointments[a].id +'xyz'}><Alert  id={appointments.id} color={color}>{
                    assignedLawyer.firstname +' '+assignedLawyer.lastname} {text}
                    <Button id={appointments[a].id} color={"danger"} className={"booking-delete"}  onClick={(e) => {;this.setState({deleteAppointment:appointments[a].id})}}>X</Button>
            </Alert></div>);
            }
        }
        return arr;
        // return arr;
    }

    renderStatusError(){
        return(<div style={{color:"red",textAlign:"center"}}><strong> Ticket clos ou résolu </strong></div>)
    }

    func(fun){
        return fun();
    }

    render(){
        
        if(this.state.redirect)
            return (<Redirect to={this.state.redirect} />);

        moment.locale(this.props.lang);
        return(      
            <div id="booking-app">
                <div style={{textAlign:"center"}}>
                    <Badge 
                        tag={"a"}
                        href={"/calendar"}
                        target={"_blank"}
                        title={"Disponibilités"}
                        color="primary"
                    >
                        <FontAwesomeIcon icon={faCalendar}/> Dispo
                    </Badge>
                    <span> </span>
                    <Badge 
                        tag={"a"}
                        href={"/schedule"}
                        target={"_blank"}
                        title={this.genLang({fr:"Mon horaire",en:'My schedule'})}
                        color="primary"
                    >
                        <FontAwesomeIcon icon={faBusinessTime}/> {this.genLang({fr:"horaire",en:'Schedule'})}
                    </Badge>
                    <span> </span>
                    <Badge 
                        // tag={"a"}
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            this.receiveLink(e)
                        }}
                        className='a'
                        style={{
                            cursor:'pointer',
                            display:this.state.filter&&this.state.filter.rdvType!==0?NONE:'inline-block'
                        }}
                        title={"link"}
                        color="primary"
                    >
                        <FontAwesomeIcon icon={faLink}/> RDV
                    </Badge>
                    {/* <Badge 
                        tag={"a"}
                        href={"/logout"+window.location.search}
                        title={"Mon horaire"}
                        color="warning"
                    >
                        <FontAwesomeIcon icon={faDoorOpen}/>
                    </Badge> */}
                    {/* {window.location.search} */}
                    
                    {/* <Badge 
                        title={"summon"}
                        color="danger"
                        onClick={ () => {
                            console.log(window.store.getState())
                            // let event = new CustomEvent('getTicket');
                            // window.dispatchEvent(event);
                            // console.time('zawarudo');
                            // setInterval(() =>{
                            //     if(window.Neolegal.ticket_id)
                            //         console.timeEnd('zawarudo');
                            //     else
                            //         console.log(' to be continued... ====>')
                            // },1000)
                        }}
                    >
                        <FontAwesomeIcon icon={faHeart}/>
                    </Badge> */}
                </div>
                <ListGroup>
                    {this.renderAppointment(this.state.appointments)}
                </ListGroup>
                <Form id="booking"> 
                    
                    {/* <Alert color={'danger'} style={{display:this.state.link&&this.state.change?BLOCK:NONE}}>
                        Le lien courrant ne correspond pas à ce que vous avez sélectionné 
                    </Alert> */}
                    <FormGroup style={{display:this.state.link&&!this.state.change?BLOCK:NONE}} >
                        <Input 
                            styles={customStyles}
                            type="text"
                            
                            name="copy"
                            id={"copy"}
                            placeholder={"copy"}
                            value={this.state.link}
                            onChange={() => {}}
                        />
                    </FormGroup>
                    <FormGroup>
                            <Select

                                onChange={this.onChange}
                                value={this.state.filter.user}
                                options={
                                    _.map(this.props.userList,(u) => {
                                        return {
                                            label:u.firstname +' '+ u.lastname,
                                            value:u.auth_id,
                                            name:"user",
                                            type:u.type,
                                            color:u.type==="elawyer"?orange:white
                                        }
                                    })
                                }
                                // defaultValue={
                                //     () => {
                                //         let user = _.find(this.props.userList,{auth_id:this.props.userId});
                                //         console.log('user ---->',user)
                                //         if(user && user.type !== 'sales')
                                //             return this.props.userId;
                                //         return 0
                                //     }
                                // }
                                name="Lawyer"
                                styles={customStyles}
                                isSearchable
                                placeholder={
                                    this.func(() => {
                                        let found = _.find(this.props.userList,{auth_id:this.state.filter.user+''});
                                        // debugger;
                                        if(found)
                                            return found.firstname+' '+found.lastname;

                                        if(this.state.filter.user=== ALL_B2B){
                                            return 'Tous (B2B)';
                                        }
                                        return 'Tous';
                                    //     let found = _.find(this.props.userList,{
                                    //         auth_id:this.props.userId
                                    //     })

                                    //     return (found)?found.firstname+' '+found.lastname:'Tous';
                                    })
                                }
                            />
                        </FormGroup>
                        <FormGroup>
                            <Select
                                onChange={this.onChange}
                                value={this.state.filter.type}
                                options={this.func(() => {
                                        let lang = this.props.lang;
                                        // console.log(lang)
                                        // lang = 'en'
                                        

                                        let filtered = this.state.types;
                                        if(this.state.filter.user=== ALL_B2B){
                                            filtered = _.reject(filtered,{neolegalExclusive:true});
                                            // debugger
                                        }
                                        else
                                            filtered = _.filter(filtered,{neolegalExclusive:true});

                                        return _.map(filtered,(u) => {
                                            

                                            let label;
                                            if(lang ==='en')
                                                label = u.title_en
                                            else
                                                label = u.title;

                                            return {
                                                label:label,
                                                value:u.id,
                                                duration:u.duration,
                                                name:"type",
                                                type:u.type
                                            }
                                        })
                                    })
                                }
                                name="type"
                                // isClearable
                                isSearchable
                                placeholder={
                                    this.func(() => {

                                        let lang = this.props.lang;
                                        // console.log(lang)
                                        // lang = 'en';

                                        
                                        let filtered = this.state.types;
                                        if(this.state.filter.user=== ALL_B2B){
                                            filtered = _.reject(filtered,{neolegalExclusive:true});
                                            // debugger
                                        }
                                        else
                                            filtered = _.filter(filtered,{neolegalExclusive:true});

                                        let found = _.find(filtered,{
                                            id:parseInt(this.state.filter.type)
                                        });

                                        if(found){
                                            if(lang=== 'en')
                                                return found.title_en;

                                            return found.title
                                        }

                                        if(lang === 'en')
                                            return 'other';

                                        return 'Autre';
                                    })
                                }
                                defaultValue={
                                    this.func(() => {
                                        return this.state.filter.type?
                                                this.state.filter.type:
                                                0
                                    })
                                }
                            />
                        </FormGroup>
                        
                        <FormGroup>
                            <Input
                                onChange={(e) => {
                                        this.setState({forcedClient:e.target.value});
                                    }
                                }
                                placeholder={'id client'}
                                value={this.state.forcedClient||''}
                                type="text"
                                // }
                                name="forcedTicket"
                            />
                        </FormGroup>
                        <FormGroup>
                            <Input
                                placeholder={'id du ticket'}
                                onChange={(e) => {
                                        this.setState({forcedTicket:e.target.value});
                                    }
                                }
                                value={this.state.forcedTicket||''}
                                type="text"
                                // }
                                name="forcedTicket"
                            />
                        </FormGroup>

                        <FormGroup style={{display:"none"}}>
                            <Select
                                onChange={this.onChange}
                                value={this.state.filter.speciality}
                                options={
                                    _.map(this.state.specialities,(u) => {
                                        return {
                                            label:u.title,
                                            value:u.id,
                                            name:"speciality"
                                        }
                                    })
                                }
                                name="speciality"
                                // isClearable
                                isSearchable
                                placeholder={
                                    (_.find(this.state.specialities,{id:parseInt(this.state.filter.speciality)}))?
                                    _.find(this.state.specialities,{id:parseInt(this.state.filter.speciality)}).title:
                                    'Tous'
                                }
                                defaultValue={
                                    this.func(() => {
                                        return this.state.specialities?
                                                this.state.specialities[0].id:
                                                0
                                    })
                                }
                            />
                        </FormGroup>
                        <FormGroup>
                            <Select
                                onChange={this.handleSelect}
                                value={this.state.duration}
                                options={this.state.durations}
                                defaultValue={this.state.durations[0]}
                                name="duration"
                                // isClearable
                                isSearchable
                                placeholder={this.state.duration + 'min'}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Select
                                onChange={this.onWeekChange}
                                value={this.state.selectedWeek}
                                options={
                                    this.props.weekList
                                }
                                name="type"
                                // isClearable
                                isSearchable
                                placeholder={
                                    this.func(() => {
                                        return _.find(this.props.weekList,{value:this.state.selectedWeek}).label;
                                    })
                                }
                                defaultValue={
                                    this.func(() => {
                                        return moment().isoWeek()
                                    })
                                }
                            />
                        </FormGroup>
                        <FormGroup>
                            <Input 
                                type="textarea"
                                name={"message"}
                                id={"message"}
                                row={2}
                                placeholder={this.genLang({en:'Information about your appointment',fr:"Info sur le rendez-vous"})}
                                value={this.state.textArea}
                                onChange={(e) => {this.setState({textArea:e.target.value})}}
                            />
                        </FormGroup>
                        <div style={{textAlign:'center',paddingTop:'7px'}}>
                            <BeatLoader size={18} margin={5} loading={this.state.loading} color="#0052BB"/>
                        </div>
                        {/* <ListGroup>
                             <ListGroupItem className="date-mark"><Badge color="info" className="bold">{this.state.duration + ' min'}</Badge></ListGroupItem> 
                            <ListGroupItem className="calendar"> */}
                            <div
                                style={{
                                    display: 'inline-table',
                                    width:'100%'
                                }}
                            >
                                {(window.Neolegal.ticketStatus==='closed'||window.Neolegal.ticketStatus==='solved')?this.renderStatusError():''}
                                {(this.state.error)?this.state.error:(this.renderCalendar(this.state.availabilities))}
                            </div>
                            {/* </ListGroupItem>
                        </ListGroup> */}
                        
                        <FormGroup                         
                            style={{
                                //     position:'fixed',
                                //     bottom:'0px',
                                //     // opacity:'1',
                                //     zIndex:'999999',
                                //     width:'100%',
                                //     marginTop:"5px"
                            }}>
                            {this.state.alreadyTaken?<Alert color={'danger'}>Ce problème est survenu : {this.state.alreadyTaken}</Alert>:''}
                            {this.state.RDVInfo?<Alert color={'success'}>Rendez-vous {this.state.RDVDate} avec {this.state.assignedLawyer}.
                            </Alert>:''}
                            {this.state.RDVInfo?<Alert color={'warning'}><strong>N'oubliez-pas de sauvegarder le ticket zendesk!</strong></Alert>:''}
                            <Button
                                className="badge"
                                style={{
                                    position:'fixed',
                                    top:'0px',
                                    right:'0px',
                                    // opacity:'1',
                                    zIndex:'999999',
                                    padding:'0px !important'
                                    // width:'100%',
                                    // marginTop:"5px"
                                }}
                                type="button"
                                disabled={!this.state.selectedButton}
                                color={(!!this.state.selectedButton)?"success":"danger"}
                                onClick={ (e) => {
                                    // this.book()
                                    this.getWithCallback(this.book,this,e);
                                }}
                            ><FontAwesomeIcon icon={(!!this.state.selectedButton)?faCheckCircle:faBan}/></Button>
                        </FormGroup>
                        <ListGroup>
                            <Label 
                                onClick={ () => {
                                    this.setState({
                                        toggle:(this.state.toggle==='block')?'none':'block',
                                    });
                                }}
                                style={{
                                    cursor:'pointer',
                                    marginLeft:'15px',
                                    paddingTop:'5px',
                                    paddingBottom:'5px',
                                    fontWeight:'bold'
                                }}
                            >Dates</Label>
                            <div style={{display:this.state.toggle}}>
                                <DayPickerRangeController
                                      enableOutsideDays={false}
                                      
                                      initialVisibleMonth={null}
                                      isRTL={false}
                                      keepOpenOnDateSelect={false}
                                      maxDate={null}
                                    //   minDate={null}
                                      minimumNights={1}
                                      monthFormat="MMMM YYYY"
                                      navNext={null}
                                      navPrev={null}
                                      numberOfMonths={1}
                                      orientation="horizontal"
                                      renderCalendarDay={undefined}
                                      renderCalendarInfo={null}
                                      renderDayContents={null}
                                      renderKeyboardShortcutsButton={undefined}
                                      renderMonthElement={null}
                                      renderMonthText={null}
                                      verticalHeight={undefined}
                                      withPortal={false}
                                    minDate={(moment().startOf('isoWeek'))}
                                    
                                    startDate={this.state.startDate}
                                    endDate={this.state.endDate}
                                    firstDayOfWeek={1}
                                    onDatesChange={({ startDate, endDate }) => { 
                                        // if(startDate.isAfter(
                                        //         moment()
                                        //             .startOf('isoWeek')
                                        //             .subtract(1,'days')
                                        //             .endOf('day')
                                        //     )
                                        // ){
                                            // startDate.startOf('isoWeek').startOf('day').add(2,'h');
                                            // endDate = startDate.clone().add(6,'d');
                                            this.setState({ 
                                                startDate:startDate,
                                                endDate:endDate,
                                                selectedWeek:startDate.isoWeek(),
                                                selectedYear:startDate.isoWeekYear()
                                            },this.getBooks)
                                        // }
                                    }}
                                    // isDayBlocked={(day) => {
                                    //     return day.isoWeekday() > 1;
                                    // }}
                                    focusedInput={this.state.focusedInput}
                                    onFocusChange={focusedInput => {
                                        this.setState({ focusedInput })
                                    }}
                                    // onOutsideClick={action('DayPickerRangeController::onOutsideClick')}
                                    // onPrevMonthClick={action('DayPickerRangeController::onPrevMonthClick')}
                                    // onNextMonthClick={action('DayPickerRangeController::onNextMonthClick')}
                                    startDateOffset={(day) => day.startOf('isoWeek').startOf('day').add(2,'h')}
                                    endDateOffset={(day) => day.endOf('isoWeek').startOf('day').add(2,'h')}
                                    hideKeyboardShortcutsPanel={true}
                                    // initialVisibleMonth={() => moment()}
                                    // endDateOffset={day => day.add(1, 'weeks')}
                                />
                            </div>
                        </ListGroup>
                </Form>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    Zendesk:state.Zendesk,
    userList: state.users.userList,
    weekList:state.time.weekList,
    ampm:state.users.result.ampm,
    dispo:state.users.result.dispo,
    admin:state.users.result.admin,
    userId:state.users.result.userId,
    type:state.users.result.type,
    lang:state.users.result.language,
    id_zendesk:state.users.result.id_zendesk
  });
  
const mapActionsToProps = {
    clientInit:rootsActions.clientActions.load,
    setLawyers: rootsActions.usersActions.setLawyers,
}

export default connect(mapStateToProps,mapActionsToProps)(Booking);
// export default Booking;