import { LOAD } from '../_actions/client-actions';
import client from '../component/utils/clientZendesk';

export default function clientReducer(
  state = '',
  {type,params}
){
  switch(type){
    case LOAD:
        return {
          ...state,
          Zendesk:client.init(params)
        }
    default:
      return state;
  }
}