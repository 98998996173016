import React, { Component } from 'react';

import {
    Container,
    Table,
} from 'reactstrap';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

import { connect } from 'react-redux';

import rootsActions from '../_actions/root-actions';

import moment  from 'moment-timezone';
import 'moment/locale/fr-ca';
import _ from 'lodash';
import {
    privateAxios as axios,
    config
} from '../utils/axios';

import c  from '../utils/console';

import Menu from './Menu';
import Day from './Day';
import History from '../_models/History';

import Axios from 'axios';

import { Redirect } from 'react-router-dom';
// const CancelToken = Axios.CancelToken;
// const source = CancelToken.source();


moment().locale('fr-ca');  

const ELAWYER = 'elawyer';
const LAWYER = 'lawyer';

const ONT_ELAWYER = "ont_elawer"
const DATE_FORMAT = "YYYY-MM-DDTHH:mm:ss+00:00";
const LAWYERS = 1;
const SALES = 2;
const ADD = 'ADD';
const REMOVE = 'REMOVE';
const init = [[],[],[],[],[],[],[]];
const   officeOpen = 5,
        officeClose = 21;

const changes = [];

const STRING = 'string';
const OBJECT = 'object';
const FR = 'fr'
class Admin extends Component{
    
    constructor(props){
        super(props)
        
        this.state ={
            loading:true,
            editId:"",
            mode:null,
            // seven days in a week
            dispos:this.init(),
            userList:[
                {auth_id: 0,firstname:"Tous / all",lastname:" "}
            ],
            users:[],
            visible:[{value: 0,label:"Tous / all"}],
            modal:false,
            editedLawyer:0,
            wData:[],
            calendarType:LAWYERS,
            categoryEdit:1
        }

        
        this.genLang = this.genLang.bind(this);
        this.mergeDispo = this.mergeDispo.bind(this)
        this.handleSelect = this.handleSelect.bind(this);
        this.handleCategory = this.handleCategory.bind(this);
        this.handleWeek = this.handleWeek.bind(this);
        this.setPeriod = this.setPeriod.bind(this);
        this.deletePeriod = this.deletePeriod.bind(this);
        this.addPeriod = this.addPeriod.bind(this);
        this.saveCalendar = this.saveCalendar.bind(this);    
        this.emptyCalendar = this.emptyCalendar.bind(this);
        this.setLoading = this.setLoading.bind(this);
        this.switchCalendar = this.switchCalendar.bind(this);
        this.setEditedLawyer=this.setEditedLawyer.bind(this);
    }

    init(){
        return JSON.parse(JSON.stringify(init))
    }

    switchCalendar(){
        this.setState({calendarType:this.state.calendarType === LAWYERS?SALES:LAWYERS })
        return;
    }

    trackAction({action,start,end,type,user}){
        if(!action)
            return;

        changes.push(
            new History({
                action:action,
                user:user,
                start:start,
                type:type,
                end:end})
        );
    }

    componentDidMount(){
           
            let uID = this.props["userId"];

            if(!uID){
                let token = window.parseJwt(localStorage.getItem('token'));
                uID=token.sub
            }

            if(config.headers.Authorization === 'Bearer null'){
                window.location.reload()
            }
        
            this.setLoading();
            
            Axios.all([
                axios.get(
                    'calendar',
                    {params:{
                        default:true,
                        auth_id:uID,
                        }
                    },
                ),
                axios.get(
                    'lawyers',
                    {params:{
                        auth_id:uID,
                        refresh:true
                    }
                })
            ])
            .then( (response) => {
                
                // debugger;
                
                let res0 = response[0];
                if(res0.redirect){
                    this.setLoading();
                    this.setState({redirect:res0.to});
                  return    
                }

                if(!res0.data)
                    return;

                let wData = response[0].data.data;
                response = _.flatMap(response, (e) =>{
                    if(e && e.data && e.data.data)
                      return e.data.data
                });

                response = _.reduce(response, function(memo, current) { return _.assign(memo, current) },  {})

                    if (!_.isEmpty(response)) {
                                           
                        let calendarType=this.state.calendarType||this.state.calendarType||LAWYERS,
                            dispos=this.init(),
                            userList=[
                                {auth_id: 0,firstname:"Tous / All",lastname:' ',color: "#545454"},
                                ...response.lawyers];
                        
                            userList = _.map(userList,(e) => {
                                return {
                                    // province:(!e.auth_id||parseInt(e.auth_id)%2)?'qc':'ON',
                                    ...e
                                }
                            });
                        this.props.setLawyers({
                            params: {
                                lawyers: [
                                    // {
                                //     auth_id: -1,
                                //     firstname: "Tous",
                                //     lastname: " ",
                                //     color: "#545454"
                                // },
                                ...userList
                                ],
                                categories:response.categories
                            }
                        });
        
                        this.mergeDispo(wData,calendarType,dispos,userList)
                    }
                    else{
                        console.log('étoile?',response)
                    }
            });

    }


    deletePeriod(e){
        
        if(!this.props.dispo)
            return false;
            
        let DST_ajust = this.props.DST?3:2;

        let id = e.currentTarget.id;
        // this.setState({editId:e.currentTarget.id},() => {
            this.setState( state => {
                // 0:day 1:hour 2:us
                const arr = id.split(/_/g);
                arr.shift();
                const dispos = state.dispos;
                const dispo = dispos[arr[0]];
                
                for(let d in dispo){
                    if(dispo[d].user === parseInt(arr[2])){
                        
                        for(let e in dispo[d].dispo){
                            
                            let available = dispo[d].dispo[e],
                                start = moment(available.start).utc().local().hour(),
                                end = moment(available.end).utc().local().hour();
                            let arr1 = parseInt(arr[1]), arr0 = parseInt(arr[0]);
                            
                            if(arr1 === start){
                                this.trackAction({
                                    action:REMOVE,
                                    start:available.start,
                                    end:available.end,
                                    type:dispo[d].dispo[e].type||1,
                                    user:arr[2]
                                });

                                available.start = moment(available.start).add(1,'hours').utc().format(DATE_FORMAT);
                                if(available.start === available.end)
                                    dispo[d].dispo.splice(e,1)
                                else
                                    available.duration = available.duration - 60;
                                    
                                break;
                            }//else if end of period
                            else if(arr1 === end-1){
                                available.end = moment(available.end).subtract(1,'hours').utc().format(DATE_FORMAT);
                                if(available.start === available.end)
                                    dispo[d].dispo.splice(e,1)
                                else
                                    available.duration = available.duration - 60;
                                    
                                this.trackAction({
                                    action:REMOVE,
                                    start:available.end,
                                    end:available.end,
                                    type:available.type,
                                    user:arr[2]
                                })
                                break;
                            }//else if middle of period
                            else if((arr1<end-1) && arr1>start){
                                let part1 = {hidden:false},
                                    part2 = {hidden:false};
                                
                                part1.start = this.props.weekMonday.clone().add(arr0,'days').add(start-DST_ajust,'hours').utc().format(DATE_FORMAT);

                                part1.end = this.props.weekMonday.clone().add(arr0,'days').add(parseInt(arr1)-DST_ajust,'hours').utc().format(DATE_FORMAT);
                                part1.duration = (moment(part1.end).hour() - moment(part1.start).hour())*60;
                                part1.type = available.type||1;

                                part2.start = this.props.weekMonday.clone().add(arr0,'days').add(parseInt(arr1)-(DST_ajust-1),'hours').utc().format(DATE_FORMAT);
                                part2.end = this.props.weekMonday.clone().add(arr0,'days').add(end-DST_ajust,'hours').utc().format(DATE_FORMAT);
                                part2.duration = (moment(part2.end).hour() - moment(part2.start).hour())*60;
                                part2.type = available.type||1;

                                dispo[d].dispo.splice(e,1);
                                dispo[d].dispo.push(part1,part2);
                                
                                this.trackAction({
                                    action:REMOVE,
                                    start:part1.end,
                                    end:available.end,
                                    type:available.type||1,
                                    user:arr[2]
                                })
                                break;
                            }
                        }
                    }
                }

                dispos[arr[0]] = dispo;
                return {
                    dispos:dispos,
                };
            });
        // });
    }

    setPeriod(e,cb){
        this.setState({
            editId:e.currentTarget.id
        },() => (cb)?cb():'');
    }
    
    addPeriod(){
        
        if(this.state.editedLawyer === 0)
            return;

        this.setState( state => {
            const dispo = (state.dispos.length)?state.dispos:this.init();
            var [weekday,hour] = this.state.editId.split(/-/g);
            hour = parseInt(hour);
            const currentDay = dispo[weekday];

            //faut pas oublier que l'heure serveur est 2am :)
            let DST_ajust = this.props.DST?3:2;
            // moment().startOf('week').startOf('day').add(2,'hours').utc()
            //si l'utilisateur est pas pentoute dans les dispos de la journée

            if(!currentDay){
                return c.error('Outside week. No day have been chosed');
            }
            else if(!_.find(
                currentDay,
                (e) => {
                    return e.user === this.state.editedLawyer;
                })
            ){
                //le -2 et moins sont dûs à l'heure neutre qui est 2am :)
                let type=state.categoryEdit,
                    start=this.props.weekMonday.clone().add(hour-DST_ajust,'hours').add(weekday,'days').utc().format(DATE_FORMAT),
                    end=this.props.weekMonday.clone().add(hour-(DST_ajust-1),'hours').add(weekday,'days').utc().format(DATE_FORMAT),
                    duration=60;

                this.trackAction({
                    user:this.state.editedLawyer,
                    action:ADD,
                    type:type,
                    start:start,
                    end:end,
                    duration:duration,
                    // categoryEdit:state.categoryEdit
                });

                currentDay.push({
                    user:parseInt(this.state.editedLawyer),
                    dispo:[{
                        type:type,
                        start:start,
                        end:end,
                        duration:duration,
                        hidden:false,
                        // categoryEdit:state.categoryEdit
                    }]
                });
            }
            else{
                let arr = {
                    ..._.find(
                    currentDay,
                    (e,i) => {
                        return e.user === this.state.editedLawyer;
                    })
                }

                let found = _.find(
                    arr.dispo,
                    (e) => {
                        let b = (
                            ((moment(e.start).utc().local().hour()) <= hour)&&
                            ((moment(e.end).utc().local().hour() ) > hour) 
                        );
                        return b;
                    }
                );

                //si pas trouvé dans le profil du user,
                // si pas dans la période temporelle
                if(!found){
                    
                    let type=this.state.categoryEdit,
                    start=this.props.weekMonday.clone().add(hour-DST_ajust,'hours').add(weekday,'days').utc().format(DATE_FORMAT),
                    _end=this.props.weekMonday.clone().add(hour-(DST_ajust-1),'hours').add(weekday,'days').utc().format(DATE_FORMAT),
                    duration=60;
                    
                    this.trackAction({
                        user:this.state.editedLawyer,
                        action:ADD,
                        type:type,
                        start:start,
                        end:_end,
                        duration:duration
                    }
                )
                    arr.dispo.push({
                        type:type,
                        start:start,
                        end:_end,
                        duration:duration,
                        hidden:false
                    });

                    let index = _.findIndex(
                        currentDay,
                        {user:this.state.editedLawyer}
                    );

                    let end = -1;
                    let lastType = null;

                    arr.dispo = _.orderBy(arr.dispo,['start'],['asc'])
                    arr.dispo = _.forEach(
                        arr.dispo,
                        (value,key) => {
                            //pas besoin du -2 ici
                            if(value.start === end && (value.type === lastType|| (!value.type&&this.state.categoryEdit === LAWYERS) )){
                                value.exterminatus = true;
                                value.duration = 0;
                                let x = 1;
                                
                                do{
                                    if(!arr.dispo[key - x].exterminatus){
                                        arr.dispo[key - x].end = value.end;
                                        arr.dispo[key - x]
                                            .duration = (moment(arr.dispo[key - x].end).hour() - moment(arr.dispo[key - x].start).hour())*60;
                                        x=false;
                                    }
                                    else
                                        x++;
                                }
                                while(x)
                            }

                            end = value.end; 
                            lastType = value.type
                        }
                    );

                    arr.dispo =  _.filter(
                        arr.dispo,(e) => {return !e.exterminatus || e.duration}
                    );
                    
                    currentDay[index] = arr;

                }
                else{
                    return {
                        dispos:dispo
                    };
                }
            }
            
            dispo[weekday] = currentDay;
            
            return {
                dispos:dispo
            };
        });

    }

    mergeDispo(wData,calendarType,dispos,userList){
        
        let users   = [],
            changed = false;

        for(let d in wData){
            if(wData[d].day){
                changed = true;

                dispos[
                    moment(wData[d].day).isoWeekday()-1
                ]
                .push({
                    user:wData[d].user,
                    name:wData[d].name,
                    dispo:wData[d].dispo
                });

                users.push(wData[d].user);
            }

        }
        if(changed){
            this.setState({
                wData:wData,
                calendarType:calendarType,
                dispos:dispos,
                users:users.filter(
                function(value, index, self) { 
                    return self.indexOf(value) === index;
                })
            });
        }
        else{
            this.setState({
                wData:wData,
                calendarType:calendarType,
                dispos:dispos,
            });
        }
        
        this.setLoading();
    }
    
    initials(obj){

        if(obj && obj.auth_id === 0){
            return 'Tous et toutes'
        }

        if(!obj||!obj.firstname||!obj.lastname)
            return 'Undefined'

        return (obj.firstname[0] +'.'+obj.lastname[0]).toUpperCase()
    }

    time(start,end){
        var divs=[];

        for(;start <end; start++){
            divs.push(<div key={start} >{start}</div>)
        }
        return(
            <>
                {divs}
            </>
          )
    }

    setLoading(){
        // return
        return this.props.toggleLoading();
    }

    handleWeek(e){
        
        this.setLoading()
        // enlever le 1er if si non necessaire
        
        let m = moment(e.start);
        if(e.next){
            m = this.props.weekMonday.clone().add(1,'w')
            this.props.setWeek({
                week:m.isoWeek(),
                year:m.isoWeekYear(),
                monday:m,
                isDST:m.isDST(),
            });
        }
        else if(e.previous){
            m = this.props.weekMonday.clone().subtract(1,'w')
            this.props.setWeek({
                week:m.isoWeek(),
                year:m.isoWeekYear(),
                monday:m,
                isDST:m.isDST(),
            });
        }
        else if(e){
            this.props.setWeek({
                week:e.value,
                year:e.year,
                monday:m,
                isDST:e.isDST,
            });
        }

        let uID = this.props["userId"];

        if(!uID){
          let token = window.parseJwt(localStorage.getItem('token'));
          
          uID=token.sub
          
        }
        axios.get(
            '/calendar',
            {params:{
                default:false,
                auth_id:uID,
                week:e.value||m.isoWeek(),
                year:e.year||m.isoWeekYear(),
                // type:e.calendarType||this.state.calendarType||LAWYERS
            }
        }
        )
        .then((response) => {

            if(response && response.data){
                
                changes.length = 0;
                let i =  (response.data.data.length)?response.data.data:this.init();

                let calendarType=this.state.calendarType||this.state.calendarType||LAWYERS,
                    wData=i,
                    dispos=this.init();

                this.mergeDispo(wData,calendarType,dispos,this.state.userList)
            
            }
        });
    }

    handleCategory(e){
        this.setState({
            categoryEdit:e.value||1
        })
    }

    handleSelect(e){
        if(e&&e.length){

            if(_.find(e,{value:ELAWYER})){
                
                e = [ 
                      ...e,
                      ..._.map(
                        _.filter(
                            this.props.userList,{type:ELAWYER}
                          ),
                          (v) => {
                            return {
                                
                            province:v.province,
                              value:v.auth_id,
                              label:v.firstname,
                              color:'#040404'
                            }
                          }
                      )
                  ]
            }
              
            if(_.find(e,{value:LAWYER})){
                e = [ 
                    ...e,
                    ..._.map(
                    _.filter(
                        this.props.userList,{type:LAWYER}
                        ),
                        (v) => {
                        return {
                            
                            province:v.province,
                            value:v.auth_id,
                            label:v.firstname,
                            color:'#040404'
                        }
                        }
                    )
                ]
            }

            if(_.find(e,{value:ONT_ELAWYER})){
                console.log('fin de la vacuité, retour à l fausse réalité')
                e = [ 
                    ...e,
                    ..._.map(
                    _.filter(
                        this.props.userList,{province:'ON'}
                        ),
                        (v) => {
                            return {
                                
                                province:v.province,
                                value:v.auth_id,
                                label:v.firstname,
                                color:'#040404'
                            }
                        }
                    )
                ]
            }

            console.log('visible',e)
            this.setState({visible:e});
        }
        else{
            this.setState({visible:[{value:0,label:'Tous'}]});
        }
            
    }

    isDispoOk(dispo){
        if(!dispo.length)
            return false;

        dispo = _.filter(dispo,(e) => !!e.duration );

        if(dispo.length)
            return dispo;

        return false;
    }

    emptyCalendar(){
        
        if(this.state.editedLawyer === '0'){
            this.setState({
                dispos:_.each(
                    this.state.dispos,
                    (elt) => {
                        if(elt.length){
                            return _.each(elt,(e) => {
                                return e.dispo = []
                            }
                        )}
                    }
                ),
                empty:'empty'
            })
        }
        else{
            this.setState({
                dispos:_.each(
                    this.state.dispos,
                    (elt) => {
                        if(elt.length){
                            return _.each(elt,(e) => {
                                if(e.user === parseInt(this.state.editedLawyer))
                                    e.dispo = [];

                                return e;                            
                            }
                        )}
                    }
                ),
                empty:'empty'
            })
        }
    }

    saveCalendar(temp = true){
        
        this.setLoading();

        let dispo = this.state.dispos,
            inner,
            calendar = [],
            weekMonday = this.props.weekMonday.clone();
        
        for(let d in dispo){
            
            if(d !== '0' ){
                weekMonday.add(1,'day').format(DATE_FORMAT);
            }

            if(dispo[d].length){
                inner = dispo[d];
                for(let i in inner){
                    let temp = [];
                    // console.log('inner dispo length',inner[i].dispo.length);
                    for(let di in inner[i].dispo){
                        if(moment(inner[i].dispo[di].start).date() === weekMonday.date()){
                            temp.push(inner[i].dispo[di])
                        }
                        else{
                            console.log('huge warning')
                        }
                    }
                    // debugger;
                    // console.log('inner dispo length AFTER',temp.length);
                    inner[i].dispo = temp;

                        calendar.push({
                            metadata:{  
                                client:navigator.appVersion,
                            },
                            year:weekMonday.isoWeekYear(),
                            day:weekMonday.hour(6).format(DATE_FORMAT),
                            timezone:moment.tz.guess(),
                            week:0,
                            name:inner[i].name,
                            user:parseInt(inner[i].user),
                            dispo:inner[i].dispo,
                            taken:[]
                            // type:this.state.calendarType
                        });
                    // }
                }
            }
        }
        
                let uID = this.props["userId"];

        if(!uID){
          let token = window.parseJwt(localStorage.getItem('token'));
          
          uID=token.sub
          
        }
        // return;

        
        axios.post(
            '/calendar',{
                auth_id:uID,
                calendar:calendar,
                changes:changes,
                temp:temp,
                button:this.state.empty
            }
            // ,
            // {
            //     cancelToken: source.token
            // }
        ).then((response) => {
            changes.length = 0;
            this.setState({
                button:undefined
            });
            this.props.toggleLoading();
        });
    }

    setEditedLawyer(e){
        this.setState({editedLawyer:parseInt(e.currentTarget.value)})
    }
    
    genLang(text,lang){
        if(!lang)
            lang = this.props.lang;

        if(typeof text === STRING){
            return text;
        }
    
        if(typeof text === OBJECT){
            if(lang && text[lang]){ 
                return text[lang];
            }
            else{
                console.error('lang missing');
                return ''
            }
        }
    
        return '';
    }

    render(){


        let uID = this.props["userId"],
            admin = this.props.admin;

        if(!uID){
            let token = window.parseJwt(localStorage.getItem('token'));
            uID=token.sub;
            // debugger;
        }

        if(this.state.redirect)
            return (<Redirect to={this.state.redirect} />);
      
  
        let lawyers = [];
        let dispo = this.state.dispos;
        let userList = this.props.userList;
        let str = '';
        for(let o in userList){
            str = userList[o].firstname + ' ';
            if(userList[o].lastname)
                str += userList[o].lastname[0] ;

            lawyers.push(
                <option 
                    key={o+'-'+userList[o].auth_id}
                    value={userList[o].auth_id}>
                        {str}
                </option>);
        }

        // moment.locale('en')
        return(      
            <>
                <Container>
                    <Menu  
                        lang={this.props.lang}
                        admin={admin}
                        dispo={this.props.dispo}
                        modal={this.state.modal}
                        userList={userList}
                        weekId={this.props.weekId}
                        year={this.props.year}
                        weekMonday={this.props.weekMonday}
                        weekList={this.props.weekList}
                        categories={this.props.categories}
                        handleSelect={this.handleSelect}
                        handleCategory={this.handleCategory}
                        handleWeek={this.handleWeek}
                        emptyCalendar={this.emptyCalendar}
                        setEditedLawyer={this.setEditedLawyer}
                        // toggle={this.toggle}
                        saveCalendar={this.saveCalendar}
                        switchCalendar={this.switchCalendar}
                        initials={this.initials}
                        editedLawyer={this.state.editedLawyer}
                    />
                    <Table id="calendar">
                        <thead>
                        <tr>
                            <th>{this.genLang({fr:'Heures',en:'Hours'},)}</th>
                            <th>{this.genLang({fr:'Lundi',en:'Monday'},)} {this.props.weekMonday.format('DD')}</th>
                            <th>{this.genLang({fr:'Mardi',en:'Tuesday'},)} {this.props.weekMonday.clone().add(1,'days').format('DD')}</th>
                            <th>{this.genLang({fr:'Mercredi',en:'Wednesday'},)} {this.props.weekMonday.clone().add(2,'days').format('DD')}</th>
                            <th>{this.genLang({fr:'Jeudi',en:'Thursday'},)} {this.props.weekMonday.clone().add(3,'days').format('DD')}</th>
                            <th>{this.genLang({fr:'Vendredi',en:'Friday'},)} {this.props.weekMonday.clone().add(4,'days').format('DD')}</th>
                            <th>{this.genLang({fr:'Samedi',en:'Saturday'},)} {this.props.weekMonday.clone().add(5,'days').format('DD')}</th>
                            <th>{this.genLang({fr:'Dimanche',en:'Sunday'},)} {this.props.weekMonday.clone().add(6,'days').format('DD')}</th>
                        </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="timeRow">
                                    {this.time(officeOpen,officeClose)}                                
                                </td>
                                <Day 
                                    day={{...dispo[0]}}
                                    start={officeOpen}
                                    end={officeClose}
                                    weekDayId={0} 
                                    deletePeriod={this.deletePeriod}
                                    addPeriod={this.addPeriod}
                                    setPeriod={this.setPeriod}
                                    calendarType={this.state.calendarType}
                                    visible={this.state.visible}
                                    userList={this.props.userList}
                                />
                                
                                <Day 
                                    day={{...dispo[1]}}
                                    start={officeOpen}
                                    end={officeClose}
                                    weekDayId={1} 
                                    deletePeriod={this.deletePeriod}
                                    addPeriod={this.addPeriod}
                                    setPeriod={this.setPeriod}
                                    calendarType={this.state.calendarType}
                                    visible={this.state.visible}
                                    userList={this.props.userList}
                                />
                                <Day 
                                    day={{...dispo[2]}}
                                    start={officeOpen}
                                    end={officeClose}
                                    weekDayId={2} 
                                    deletePeriod={this.deletePeriod}
                                    addPeriod={this.addPeriod}
                                    setPeriod={this.setPeriod}
                                    calendarType={this.state.calendarType}
                                    visible={this.state.visible}
                                    userList={this.props.userList}
                                />
                                <Day 
                                    day={{...dispo[3]}}
                                    start={officeOpen}
                                    end={officeClose}
                                    weekDayId={3} 
                                    deletePeriod={this.deletePeriod}
                                    addPeriod={this.addPeriod}
                                    setPeriod={this.setPeriod}
                                    calendarType={this.state.calendarType}
                                    visible={this.state.visible}
                                    userList={this.props.userList}
                                />
                                <Day 
                                    day={{...dispo[4]}}
                                    start={officeOpen}
                                    end={officeClose}
                                    weekDayId={4} 
                                    deletePeriod={this.deletePeriod}
                                    addPeriod={this.addPeriod}
                                    setPeriod={this.setPeriod}
                                    calendarType={this.state.calendarType}
                                    visible={this.state.visible}
                                    userList={this.props.userList}
                                />
                                                                <Day 
                                    day={{...dispo[5]}}
                                    start={officeOpen}
                                    end={officeClose}
                                    weekDayId={5} 
                                    deletePeriod={this.deletePeriod}
                                    addPeriod={this.addPeriod}
                                    setPeriod={this.setPeriod}
                                    calendarType={this.state.calendarType}
                                    visible={this.state.visible}
                                    userList={this.props.userList}
                                />
                                <Day 
                                    day={{...dispo[6]}}
                                    start={officeOpen}
                                    end={officeClose}
                                    weekDayId={6} 
                                    deletePeriod={this.deletePeriod}
                                    addPeriod={this.addPeriod}
                                    setPeriod={this.setPeriod}
                                    calendarType={this.state.calendarType}
                                    visible={this.state.visible}
                                    userList={this.props.userList}
                                />          
                            </tr>
                        </tbody>
                    </Table>
                </Container>
            </>
        )
    }
}

const mapStateToProps = state => ({
    toggle:state.users.toggle,
    userList:state.users.userList,
    work:state.users.work,
    //すみません　変な所ですから　はやくにしなければならないよ。
    categories:state.users.categories,
    weekList:state.time.weekList,
    weekId:state.time.currentWeek,
    year:state.time.currentYear,
    weekMonday:state.time.currentMonday,
    isDST:state.time.isDST,
    dispo:state.users.result.dispo,
    admin:state.users.result.admin,
    userId:state.users.result.userId,
    lang:state.users.result.language,
});

const mapActionsToProps = {
    setLawyers: rootsActions.usersActions.setLawyers,
    toggleLoading:rootsActions.loadingActions.toggleLoading,
    setWeek:rootsActions.timeActions.setWeek
}

export default connect(mapStateToProps,mapActionsToProps)(Admin);