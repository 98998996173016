import { SET_WEEK } from '../_actions/time-actions';

export default function timeReducer(
  state = '',
  {type,params}
){
  switch(type){
    case SET_WEEK:
        return {
          
          ...state,
          currentWeek:params.week,
          currentYear:params.year,
          currentMonday:params.monday,
          isDST:params.isDST
        }
    default:
      return state;
  }
}