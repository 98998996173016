import React, {Component} from 'react';
import {
    Button,
    Form,
    FormGroup,
    Label,
    Input
} from 'reactstrap';
import Select from 'react-select';

import Table from '../utils/Table'
import rootsActions from '../../_actions/root-actions';
import _ from 'lodash';
import {adminAxios as axios} from '../../utils/axios';
import Alert from '../utils/Alert'
import { connect } from 'react-redux';
const list = 'list';
// const manage = 'manage'

const PRIMARY = 'primary';
const DANGER = 'danger';
const SUCCESS = 'La liste a été modifié avec succès';
const ERROR = 'Une erreur est survenue, la liste n\'a pas été mise à jour';

// const ERRORCANTEDIT = `La spécialité «${namae}» cible tout le monde; impossible de la sélectionner`;

const columns = [
        {
            Header: '#',
            accessor: 'id',
        },
        {
            Header: 'Type de RDV',
            accessor: 'title',
        },
        {
            Header: 'Durée',
            accessor: 'duration',
        },
        {
            Header: 'Limite quotidienne',
            accessor: 'limit',
        },
        {
            Header: 'Groupe',
            accessor: 'speciality',
        }
]

class TableProduct extends Component{

    constructor(props){
        super(props);

        this.state = {
            ...props
        }
    }

    componentDidUpdate(prevProps){
        let obj = {};

        if(JSON.stringify(prevProps.specialities)!==JSON.stringify(this.props.specialities)){
            obj.specialities = this.props.specialities
        }

        if(JSON.stringify(prevProps.types)!==JSON.stringify(this.props.types)){
            obj.types = this.props.types
        }

        if(JSON.stringify(prevProps.durations)!==JSON.stringify(this.props.durations)){
            obj.durations = this.props.durations
        }

        if(JSON.stringify(prevProps.userList)!==JSON.stringify(this.props.userList)){
            obj.userList = [{auth_id: -1,firstname:"Tous",lastname:' '},...this.props.userList];
        }

        if(_.keys(obj).length)
            this.setState(obj);
    }

    render(){
        let data = [];

        if(this.state.types){
            data = _.map(this.state.types, (type) => {
                let speciality = '';

                if(this.state.specialities && this.state.specialities[0]){
                    speciality = _.find(this.state.specialities,{id:type.specialities[0].id})

                    if(speciality && speciality.title)
                        speciality = speciality.title
                    else 
                        speciality = '';
                }

                let duration = 'indéfini';
                if(this.state.durations && this.state.durations[0]){
                    duration = _.find(this.state.durations,{id:type.duration})
                    if(duration && duration.label)
                        duration = duration.label
                    else 
                        duration = 'indéfini';
                }

                return {
                    id:type.id,
                    title:type.title,
                    speciality:speciality,
                    duration:duration,
                    limit:type.limit                    
                }
            })
        }

        // console.log(data);
        return(
            <Table 
                data={data}
                columns={columns}
                />
        )
    }
} 



export class FormProducts extends Component{
    constructor(props){
        super(props);

        this.state = {
            alert:false,
            alertColor:PRIMARY,
            alertMessage:'',
            users:[],
            specialities:[],
            group:0,
            tarif:list,
            productName:'',
            productNameEn:'',
            neolegalExclusive:'true',
            duration:1,
            limit:0,
            scheduleType:0,
            durations:[],
            categories:[],
            // category:1
        }

        this.onChange = this.onChange.bind(this);
        
        this.handleSubmit = this.handleSubmit.bind(this);
        
    }

    onChange(e){
        this.setState({
            users:e
        })
    }

    componentDidUpdate(prevProps){
        let obj = {};

        // debugger;
        if(JSON.stringify(prevProps.specialities)!==JSON.stringify(this.props.specialities)){
            obj.specialities = this.props.specialities;
            // console.log('spécialities in type!!!!!')
        }

        if(JSON.stringify(prevProps.types)!==JSON.stringify(this.props.types)){
            obj.types = this.props.types
        }

        if(JSON.stringify(prevProps.categories)!==JSON.stringify(this.props.categories)){
            obj.categories = _.map(this.props.categories, (e) => {
                return {...e,label:e.name,value:e.id}
            });
        }

        if(JSON.stringify(prevProps.durations)!==JSON.stringify(this.props.durations)){
            obj.durations = this.props.durations
        }

        if(JSON.stringify(prevProps.userList)!==JSON.stringify(this.props.userList)){
            obj.userList = [{auth_id: -1,firstname:"Tous",lastname:' '},...this.props.userList];
        }

        if(_.keys(obj).length)
            this.setState(obj);
    }
    
    handleSubmit(e){
        let uID = this.props["userId"];
        
        if(!uID){
          let token = window.parseJwt(localStorage.getItem('token'));
          
          uID=token.sub
          
        }

        // console.log('auth_id',uID);
        if(!uID){
            console.log('---> uID',uID);
            return;

        }
        let state = this.state;

        let obj = {
            auth_id:uID,
            title:state.productName,
            title_en:state.productNameEn,
            type:state.scheduleType,
            limit:state.limit,
            speciality:state.speciality,
            duration:state.duration,
            neolegalExclusive:this.state.neolegalExclusive,
            category:this.state.category
        };

        // if(state.limit && state.limit !== '0')
        //     obj.limit = state.limit;

        this.props.toggleLoading();
        
        axios
        .post("/type",
            obj
        )
        .then(
            (status) =>{
                if( status.status === 200){
                    
                    let index = _.findIndex(this.props.specialities,{id:state.speciality});
                    let arr = [...this.props.specialities];

                    arr[index].users = _.map(state.users,(u) => parseInt(u.value));

                    this.props.init({
                        params:{
                            specialities:arr
                        }
                    })
                    this.setState({
                        alert:true,
                        alertColor:PRIMARY,
                        alertMessage:SUCCESS,
                        group:0,
                        tarif:list,
                        productName:'',
                        productNameEn:'',
                        neolegalExclusive:true,
                        duration:1,
                        limit:0,
                        scheduleType:0,
                        category:1
                        
                    })
                }
                else{
                    
                    // console.log('err',status.status);
                    this.setState({
                        alert:true,
                        alertColor:DANGER,
                        alertMessage:ERROR,
                    },() => console.log(ERROR,this.state))
                }
                this.props.toggleLoading()
                // if(status.statusCode===200){
                // }
            },
            (err) =>{
                this.props.toggleLoading()
                // console.log('err',err);
                this.setState({
                    alert:true,
                    alertColor:DANGER,
                    alertMessage:ERROR,
                },() => console.log(ERROR,this.state))
            },
        )
    }
    
    render(){
        // console.log('that this',this);
        return (
            <Form>
                <FormGroup>
                    Nom du rendez-vous en français
                    <Input
                        required
                        value={this.state.productName} 
                        type={'text'}
                        onChange={(e) => this.setState({productName:e.currentTarget.value})} 
                    />
                </FormGroup>
                <FormGroup>
                    Nom du rendez-vous en anglais
                    <Input
                        required
                        value={this.state.productNameEn} 
                        type={'text'}
                        onChange={(e) => this.setState({productNameEn:e.currentTarget.value})} 
                    />
                </FormGroup>
                <FormGroup>
                    Durée du rendez-vous recommandée
                    <Select
                        onChange={(e) => {
                            this.setState({
                                duration: e.id,
                            });
                        }}
                        // value={this.state.duration}
                        options={this.state.durations}
                        name="type"
                        isClearable
                        isSearchable
                    />
                </FormGroup>
                <FormGroup>
                    Type de rendez-vous
                    <Select
                        onChange={(e) => {
                            this.setState({
                                category: e.id,
                            });
                        }}
                        // value={this.state.category}
                        options={this.state.categories}
                        name="type"
                        isClearable
                        isSearchable
                    />
                </FormGroup>
                <FormGroup>
                    Limite journalière <span style={{color:'red'}}> 0 pour illimité</span>
                    <Input
                        required
                        value={this.state.limit} 
                        type={'number'}
                        min={1}
                        onChange={(e) => this.setState({limit:parseInt(e.currentTarget.value)})} 
                    />
                </FormGroup>
                <FormGroup>
                    Disponible dans le calendrier
                    <Select
                        onChange={(e) => {
                            this.setState({
                                scheduleType: e.type,
                            });
                        }}
                        
                        options={[
                            {value:0,label:'Lors des dispos',type:0},
                            {value:1,label:'hors des dispo',type:1},
                            {value:2,label:'N\'importe quand',type:2}
                        ]}
                        name="type"
                        isClearable
                        isSearchable
                    />
                </FormGroup>
                <FormGroup>
                    Produit réservé à quel groupe?
                    <Select
                        onChange={(e) => {
                            let speciality = _.find(this.state.specialities, { id: e.speciality });


                            if(!speciality|| !speciality.users){
                                let name = speciality?speciality.title:'indéfinie';
                                
                                return this.setState({
                                    alert:true,
                                    alertColor:DANGER,
                                    alertMessage:`La spécialité «${name}» cible tout le monde; impossible de voir tous ses membres`,
                                });
                            }

                            let arr = _.filter(this.state.userList, (elt) => {
                                return speciality.users.find((e) => {
                                    return e === parseInt(elt.auth_id);
                                });
                            });

                            arr = _.map(arr, (e) => {
                            return {
                                value: e.auth_id,
                                label: e.firstname + "." + e.lastname[0],
                                name: "user",
                            };
                            });

                            this.setState({
                                speciality: speciality.id,
                                type: e.value,
                                users: arr,
                            });
                        }}
                        
                        options={_.map(this.state.specialities, (s) => {
                            return {
                            label: s.title,
                            value: s.id,
                            speciality: s.id,
                            };
                        })}
                        name="Groups"
                        isClearable
                        isSearchable
                    />
                </FormGroup>
                <FormGroup>
                    membres de ce groupe
                    <Select
                    onChange={this.onChange}
                    options={_.map(this.state.userList, (u) => {
                        return {
                        label: u.firstname + " " + u.lastname[0],
                        value: u.auth_id,
                        name: "user",
                        };
                    })}
                    value={this.state.users}
                    name="Lawyers"
                    isClearable
                    isMulti
                    isSearchable
                    isDisabled
                    />
                </FormGroup>
                <FormGroup check>
                    
                    <Input
                        name="exclusive"
                        value={this.state.neolegalExclusive==='true'} 
                        type={'checkbox'}
                        onChange={(e) => this.setState({neolegalExclusive:e.currentTarget.checked.toString()})} 
                    />
                    
                    <Label for="exclusive">
                        Exclusif à Neolegal 
                    </Label>

                </FormGroup>
                <FormGroup>
                    <Button
                    color={"primary"}
                    onClick={this.handleSubmit}
                    type="button"
                    size="lg"
                    className={"full-width m-t-10"}
                    >
                    Enregistrer
                    </Button>
                </FormGroup>
                <Alert 
                    visible={this.state.alert}
                    color={this.state.alertColor}
                    message={this.state.alertMessage}
                />
            </Form>)
    }
}

const mapActionsToProps = {
    setLawyers: rootsActions.usersActions.setLawyers,
    init:rootsActions.usersActions.initUsersData,
    toggleLoading:rootsActions.loadingActions.toggleLoading,
}

const mapStateToProps = state => ({
    userList:state.users.userList,
    specialities:state.users.specialities,
    types:state.users.types,
    durations:state.users.durations,
    categories:state.users.categories
});


export const ProductsTable = connect(mapStateToProps)(TableProduct);

export const ProductsForm = connect(mapStateToProps,mapActionsToProps)(FormProducts)