import React from 'react';
import SubMenu from './SubMenu.jsx';
const logo = require("../../assets/img/brand/logo-white-n.png")
// import neoLogo from "../neolegal.png";
// import neoLogo from './nlw.png';



// const NONE = 'none';
// const BLOCK = 'block';

const menu = [{
    name:'Calendrier' ,
    options:[{url:"calendar",name:"Dispo"},{url:"schedule",name:"Horaire"}],
    icon:'far fa-calendar'
  },
  {
    name:"Factures",
    options:[{
      url:"factures",
      name:"factures"
    }],
    icon:'fas fa-receipt'
  },
];

let admin;
let token = window.parseJwt(localStorage.getItem('token'));
admin=token.sub;

if(admin){
  menu.push({
    name:'Groupes' ,
    options:[{url:"groups",name:"Groupes"}],
    icon:'fas fa-cogs'
  })
}

menu.push(
  {
    link:"/logout",
    name:"Déconnexion",
    options:[{
      url:"logout",
      name:"Déconnexion"
    }],
    icon:'fas fa-sign-out-alt'
  })

class ExternalSideBar extends React.Component {

  
  render() {

    let style = {
        position:'fixed',
        top:0,
        left:0,
        width:'90px',
        zIndex:101,
        height:'100vh'
    }
    
    let arr = [];

    for(let m in menu){
      arr.push(
        <SubMenu key={'m_'+m} {...menu[m]} />
      )
    }

    return (
        <>
          <div style={style} className="menu-w color-scheme-dark color-style-bright menu-position-side menu-side-left menu-layout-mini sub-menu-style-over sub-menu-color-bright selected-menu-color-light menu-activated-on-hover menu-has-selected-link">
            <div className="logo-w">
              <a className="logo" href="/group">
                {/* <img alt="neolegal logo" src="https://www.neolegal.ca/static/assets/img/logo-white-n.png" width="180" /> */}
                <img alt="neolegal logo" src={logo} width="180" />
                <div className="logo-label">
                  NEODESK
                </div>
              </a>
            </div>
            <ul className="main-menu">
              {arr}
            </ul>
            <div className="side-menu-magic">
              <h4>
                NEODESK
              </h4>
              <div className="btn-w">
              </div>
            </div>
          </div>    
          
        </>
      )
  }
}

export default ExternalSideBar;