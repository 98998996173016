import React, {Component} from 'react';
import {
    Button,
    Form,
    FormGroup,
} from 'reactstrap';
import Select from 'react-select';

import _ from 'lodash';
import {privateAxios as axios} from '../../utils/axios';
import { connect } from 'react-redux';
const list = 'list';
// const manage = 'manage'

class Licenses extends Component{
    constructor(props){
        super(props);

        this.state = {
            users:[],
            specialities:[],
            group:0,
            tarif:list
        }

        this.onChange = this.onChange.bind(this);
        
        this.handleSubmit = this.handleSubmit.bind(this);
        this.lawyerCallback = this.lawyerCallback.bind(this);
    }

    onChange(e){
        this.setState({
            users:e
        })
    }

    handleSubmit(e){

        let state = this.state;
        
        axios
        .post("/specialities", {
            auth_id:localStorage.getItem('user_id'),
            type:state.type,
            speciality:state.speciality,
            members:_.map(state.users,(u) => parseInt(u.value))
        })
        .then(
            (status) =>{
                console.log(status);
            },
            (err) =>{
                console.log(err);
            },
        )
    }

    componentDidMount(){
        let uID;

        if(!uID){
          let token = window.parseJwt(localStorage.getItem('token'));
          
          uID=token.sub  
        }    

        if(!localStorage.getItem('lawyerData')){ 
            axios.get(
                'lawyers',
                {params:{auth_id:uID}}
            )
            .then(this.lawyerCallback);
        }
        else{
            this.lawyerCallback(JSON.parse(localStorage.getItem('lawyerData')))
        }
    }

    lawyerCallback(response){
        if( response && response.data && response.data.data[0]){

            localStorage.setItem('lawyerData',JSON.stringify(response));
            
            this.setState({
                userList:[{auth_id: -1,firstname:"Tous",lastname:' '},...response.data.data[0].lawyers],
                types:[...response.data.data[0].types],
                specialities:[...response.data.data[0].specialities],
                durations:[...response.data.data[0].durations]
            },);
        }
    }
    
    render(){
        return (
            <Form>
                <FormGroup>
                    Licenses
                    <Select
                    onChange={(e) => {
                        this.setState({
                        type: e,
                        users: [],
                        });
                    }}
                    value={this.state.type}
                    options={
                        [{label:'support',value:0},{label:'support + talk',value:1},{label:'Support + chat',value:2},{label:'Support + Talk + chat',value:2}]
                    }
                    name="license"
                    isClearable
                    isSearchable
                    />
                </FormGroup>
                <FormGroup>
                    membres
                    <Select
                    onChange={this.onChange}
                    options={_.map(this.state.userList, (u) => {
                        return {
                        label: u.firstname + " " + u.lastname[0],
                        value: u.auth_id,
                        name: "user",
                        };
                    })}
                    value={this.state.users}
                    name="Lawyers"
                    isClearable
                    isMulti
                    isSearchable
                    />
                </FormGroup>
                <FormGroup>
                    <Button
                    color={"primary"}
                    onClick={this.handleSubmit}
                    type="button"
                    size="lg"
                    className={"full-width m-t-10"}
                    >
                    Enregistrer
                    </Button>
                </FormGroup>
            </Form>)
    }
}

const mapStateToProps = state => ({
    userList:state.users.lawyers,
});

export default connect(mapStateToProps)(Licenses)