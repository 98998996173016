import React from 'react';
import ValidationIcon from './ValidationIcon';


import moment  from 'moment-timezone';

import {
    FormGroup,
    Label,
    Input
} from 'reactstrap';

import {
    none,
    block
} from '../../_const';

export default class DateInput extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            date:'',
            momentDate:'',
            display:(!this.props.mandatory)?none:block,
            mandatory:this.props.mandatory
        }
    }

    componentDidUpdate(prevProps){
        if(this.props.allvisible !== prevProps.allvisible && !this.state.mandatory) {
            this.setState({display:(this.props.allvisible)?none:block})
        }
    }

    render(){
        return (<FormGroup neokey={this.props.neokey}>
            <Label
                onClick={(e) => this.setState({display:(!this.state.mandatory&&this.state.display===block)?none:block})}
            >{this.props.title}<ValidationIcon valid={!!this.state.date} /></Label>
                <span 
                    style={{display:(this.state.display===none)?block:none}}>
                    {(this.props.date&&this.state.momentDate)?this.state.momentDate.format('LL'):this.state.date}
                </span>
                <Input
                    style={{display:this.state.display}}
                    onChange={(e) => 
                        this.setState({
                            mandatory:false,
                            momentDate:moment(e.target.value),
                            date:e.target.value
                        },() =>{
                                this.props.self.setForm(this.props.title,this.state.date,this.props.zendesk);
                            }
                    )}
                    invalid={this.state.mandatory}
                    zendesk={this.props.zendesk||''}
                    type={this.props.type}
                    value={this.state.date}
                    id={this.props.id} />
        </FormGroup>)

    }
}
