import React, { Component } from "react";
import { 
    Button,
    Form,
    FormGroup, 
    Input,
    Row,
    Col,
    Card,
    CardBody,
    CardHeader
} from "reactstrap";

import Select from "react-select";

import Table from '../utils/Table'

import TableIcon from '../utils/TableIcon';
import _ from "lodash";
import { adminAxios as axios } from "../../utils/axios";

import  NeoLabel  from "../utils/NeoLabel";
import { connect } from "react-redux";

import { faCheckCircle, faTimesCircle,faCoins,faClock} from '@fortawesome/free-solid-svg-icons';

import AlertNotification from '../utils/Alert'

import rootsActions from '../../_actions/root-actions'
import A from '../utils/A';
import moment from 'moment-timezone';

const MANUAL = '1';
const AUTOMATIC = '0';

const INFO = 'info';
const WARNING = 'warning';
const DANGER = 'danger';

const YELLOW_PENDING = '#E6D200';
const GREEN_APPROVED = 'green';
const RED_DENIED = 'red';
const APPROVED = 1;
const PENDING = 0;
const REFUSED = -1;

const STRING = 'string';
const OBJECT = 'object';

const elawyer = 'elawyer'
// const manage = 'manage
const BLOCK = 'block';
const NONE = 'none';
// const CAD = 'CAD';
const ZD = 'https://neolegal.zendesk.com/agent/tickets/';
const DATE_FORMAT = "YYYY-MM-DD"

const columns = [
  {
      Header: {fr:'#Facture',en:'Bill#'},
      accessor: 'ticketId',
  },
  {
      Header: {fr:'Produit',en:'Product'},
      accessor: 'typeLabel',
  },
  {
      Header: {fr:'Description',en:'Details'},
      accessor: 'description',
  },
  {
      Header: {fr:'Montant',en:'Sum'},
      accessor: 'tarif',
  },
  {
    Header: {fr:'Approuvé',en:'Approved'},
    accessor: 'approvedIcon',
  },
  {
    Header: {fr:'Facturable',en:'billable'},
    accessor: 'invoice',
  },
  {
    Header: {fr:'Modifier',en:'Edit'},
    accessor: 'edit',
  },
  
  {
    Header: {fr:'Date',en:'Date'},
    accessor: 'created',
  }
];

const adminColumns = [
  {
    Header: {fr:'#Facture',en:'Bill#'},
    accessor: 'ticketId',
},
{
    Header: {fr:'Produit',en:'Product'},
    accessor: 'typeLabel',
},
{
    Header: {fr:'Description',en:'Details'},
    accessor: 'description',
},
{
    Header: {fr:'Montant',en:'Sum'},
    accessor: 'tarif',
},
{
  Header: {fr:'Approuvé',en:'Approved'},
  accessor: 'approvedIcon',
},
{
  Header: {fr:'Facturable',en:'billable'},
  accessor: 'invoice',
},
{
  Header: {fr:'Modifier',en:'Edit'},
  accessor: 'edit',
},

{
  Header: {fr:'Date',en:'Date'},
  accessor: 'created',
}
];

const NEW = 'new';
const data = [];

class FactureTable extends Component{
    constructor(props){
      super(props);
      
      let facture = window.location.pathname.split(/\//)[2];
      if(facture.match(/new/i))
        facture = NEW;

      let plaisir = window.location.search;

      if(plaisir)
        plaisir = plaisir.split(/=/)[1];

      if(!plaisir)
        plaisir = this.props.userId;

      if(!plaisir){
        
        let uID = this.props["userId"];

        if(!uID){
          let token = window.parseJwt(localStorage.getItem('token'));
          
          plaisir=token.sub;
          
        }
      }
      this.state = {
        ...props,
        amount:'',
        description:'',
        ticket:'',
        currentPiece:{name:'',url:'',type:''},
        facture:facture&&facture!==NEW?facture:NEW,
        user_id:plaisir
      }

      this.init = this.init.bind(this);
      this.onChange = this.onChange.bind(this);
      this.addLign = this.addLign.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
      this.lawyerCallback = this.lawyerCallback.bind(this);
      this.edit = this.edit.bind(this);
      this.editLign = this.editLign.bind(this);
      this.genLang = this.genLang.bind(this);

    }


    init(){
      return {
        amount:'',
        description:'',
        ticket:'',
        // speciality:0,
        // type:0
      }
    }
    genLang(text,lang){
      if(!lang)
          lang = this.props.lang;
  
      if(typeof text === STRING){
          return text;
      }
  
      if(typeof text === OBJECT){
          if(lang && text[lang]){ 
              return text[lang];
          }
          else{
              console.error('lang missing');
              return ''
          }
      }
  
      return '';
    }
    onChange(e) {

      this.setState({
        user: e,
      });
    }

    editLign(){
      let state = this.state;
      let found = _.find(this.props[state.collection],{id:state.editId});

      if(!found)
        return console.error('no found desu ne');
      let ticket = state.ticket;
      let invoice = {
        ...found,
        type_id:this.state.type,
        typeLabel:_.find(this.state.types,{id:parseInt(state.type)}).title,
        ticketId:state.ticket,
        ticket_id:state.ticket,          
        description:state.description,
        tarif:state.amount,
        approved:PENDING,
        approvedIcon:<TableIcon
        color={YELLOW_PENDING}
        icon={faClock}
        callback={this.props.admin?() => {
          // debugger;
          this.props.invoiceTogglePending({
            collection:state.collection,
            id:state.editId
          })
          // let id = "/facture/1"
          
          // this.setState({redirection:id})
        }:false} 

      />
      }
      if(invoice.type.toString() === MANUAL){
        invoice.to_invoice = invoice.to_invoice === '1'? '0': '1';
        this.props.invoiceListEditManual(invoice);
      }
      else if(invoice.type.toString() === AUTOMATIC){ 
          invoice.to_invoice = invoice.to_invoice === '1'? '0': '1';
          this.props.invoiceListEditAutomatic(invoice);
      }

      this.setState({
        ticket:'',
        ticketId:'',
        ticket_id:'',          
        description:'',
        tarif:'',
        collection:'',
        editId:'',
        amount:0,

      },() => {
        
        var scrollingElement = (document.getElementsByClassName('header')[0]|| document.body);
        console.log(document.querySelectorAll('[anchor="'+ticket+'"]')[0].getBoundingClientRect().top)
        console.log('=========>', document.getElementsByClassName('header')[0].getBoundingClientRect().top, document.querySelectorAll('[anchor="'+ticket+'"]')[0].getBoundingClientRect().top)


        let header = window.getComputedStyle(document.querySelector('.header .row')).height
        let target = document.querySelectorAll('[anchor="'+ticket+'"]')[0].getBoundingClientRect().top;
        header = header.replace('px','');
        header = parseInt(header)
        // target += 100
        // debugger;


        if(document.querySelectorAll('[anchor="'+ticket+'"]')[0].getBoundingClientRect().top>0)
          return;

        scrollingElement.scrollTop = 0;
        if(invoice.type.toString() === MANUAL)
          scrollingElement.scrollTop = document.querySelectorAll('[anchor="'+ticket+'"]')[0].getBoundingClientRect().top-18;
        else
          scrollingElement.scrollTop = document.querySelectorAll('[anchor="'+ticket+'"]')[0].getBoundingClientRect().top-18
          // -(target);
      })
    }

    addLign(){
      let state = this.state;

      if(!state.ticket||!state.amount){
        return this.setState({
          alertColor:WARNING,
          alertMessage:'Le prix et le numéro de ticket sont obligatoires',
          alertVisible:true
        })
      }

      let id = new Date().getTime();
      let type = _.find(this.state.types,{
        id:state.type
      })

      if(!type)
        type = {id:0,title:'Autre'};

      this.props.invoiceAddManual(
        {
          typeLabel:type.title,
          type_id:type.id.toString(),
          id:id,
          ticketId:state.ticket,
          ticket_id:state.ticket,          
          description:state.description,
          tarif:state.amount,
          approved:PENDING,
          approvedIcon:<TableIcon
            color={YELLOW_PENDING}
            icon={faClock}
            callback={this.props.admin?() => {
              this.props.invoiceTogglePending({
                collection:'manual',
                id:id
              })
              // let id = "/facture/1"
              
              // this.setState({redirection:id})
            }:false} 

          />,
          edit:<TableIcon
            callback={() => {
              this.edit({
                collection:'manual',
                id:id
              })
              // let id = "/facture/1"
              
              // this.setState({redirection:id})
            }} 
          />,
          type:MANUAL,
          to_invoice:'1',
          invoice:1
        },
      )

      this.setState(this.init())
      
    }

    edit({id,collection}){
      // debugger;
      let found = _.find(this.props[collection],{id:id});

      if(!found)
        return console.error('not found in edit stuff');

      let speciality = _.find(this.state.types,{id:parseInt(found.type_id)});
      if(speciality&& speciality.specialities)
        speciality = speciality.specialities[0].id
      else
        speciality = null;

      this.setState({
        type:found.type_id,
        speciality:speciality,
        ticket:found.ticket_id,
        description:found.description,
        amount:found.tarif,
        editId:found.id,
        collection:collection
      },() => {
        var scrollingElement = (document.getElementsByClassName('header')[0]|| document.body);
        scrollingElement.scrollTop = scrollingElement.scrollHeight;
      })
    }

    componentDidMount() {

      this.props.toggleLoading();

      let uID = this.props["userId"];

      if(!uID){
        let token = window.parseJwt(localStorage.getItem('token'));
        
        uID=token.sub
        
      }

      if (!localStorage.getItem("lawyerData")) {
        axios
          .get("lawyers", {
            params: { auth_id: uID },
          })
          .then(this.lawyerCallback);
      } else {
        this.lawyerCallback(JSON.parse(localStorage.getItem("lawyerData")));
      }
    }

    
  lawyerCallback(response) {

    if(response.redirect){
      return  this.setState({redirect:response.to})  
    }

    if (response && response.data && response.data.data[0]) {
      localStorage.setItem("lawyerData", JSON.stringify(response));

      let uID = this.props["userId"];

      if(!uID){
        let token = window.parseJwt(localStorage.getItem('token'));
        
        uID=token.sub
        
      }

      let res = response;
      axios.get(
        'invoices/'+ this.state.facture,{
          params:{
            auth_id:uID,
            user_id:this.state.user_id,
            // ||this.state.facture===NEW?localStorage.getItem('user_id'):null,
            // invoice_number:this.state.facture,
            // start_date:"2020-01-01",
            // end_date:"2020-12-31",
            format:'json'
          }
      })
      .then(
        (response) => {
          if(response && response.data && response.data.data){
            
            // debugger;
            let type;
            let {user_id} = response.data.data;

            let lines = _.map(response.data.data.lines, (e) => {
              type = _.find(res.data.data[0].types,{id:parseInt(e.type_id)});
              
              type = type?type.title:'Produit inconu'
      
              let icon = faClock;
              let color = YELLOW_PENDING;
              
              if(parseInt(e.approved)===APPROVED){
                color = GREEN_APPROVED;
                icon = faCheckCircle;
              }
              else if(parseInt(e.approved)===REFUSED){
                color = RED_DENIED;
                icon = faTimesCircle;
              }

              return {
                ...e,
                typeLabel:type,
                approvedIcon:<TableIcon 
                  icon={icon}
                  color={color}
                  callback={this.props.admin?() => {
                    this.props.invoiceTogglePending({
                      collection:e.type===MANUAL?'manual':e.type===AUTOMATIC?'automatic':'list',
                      id:e.id
                    })
                    // let id = "/facture/1"
                    
                    // this.setState({redirection:id})
                  }:false} 
                />,
                
                edit:<TableIcon
                  callback={() => {
                    this.edit({
                      collection:e.type===MANUAL?'manual':e.type===AUTOMATIC?'automatic':'list',
                      id:e.id
                    })
                    // let id = "/facture/1"
                    
                    // this.setState({redirection:id})
                  }}
                />,
              }
            });

            let linesAuto = _.map(response.data.data.linesAuto, (e) => {
              type = _.find(res.data.data[0].types,{id:parseInt(e.type_id)});
              
              type = type?type.title:'Produit inconu'
              let icon = faClock;
              let color = YELLOW_PENDING;
              
              if(parseInt(e.approved)===APPROVED){
                color = GREEN_APPROVED;
                icon = faCheckCircle;
              }
              else if(parseInt(e.approved)===REFUSED){
                color = RED_DENIED;
                icon = faTimesCircle;
              }

              return {
                ...e,
                typeLabel:type,
                approvedIcon:<TableIcon 
                  icon={icon}
                  color={color}
                  callback={this.props.admin?() => {
                    this.props.invoiceTogglePending({
                      collection:'automatic',
                      id:e.id
                  })
            // let id = "/facture/1"
            
            // this.setState({redirection:id})
                }:false} />,
                
                
                edit:<TableIcon
                  callback={() => {
                    this.edit({
                      collection:'automatic',
                      id:e.id
                    })
                    // let id = "/facture/1"
                    
                    // this.setState({redirection:id})
                  }}
                />,
              }
            });

            let manual = _.filter(lines,{type:MANUAL})||[];
            manual = _.map(manual,(e) => {
              type = _.find(res.data.data[0].types,{id:parseInt(e.type_id)});
              
              type = type?type.title:'Produit inconu'
              
              let icon = faClock;
              let color = YELLOW_PENDING;
              
              if(parseInt(e.approved)===APPROVED){
                color = GREEN_APPROVED;
                icon = faCheckCircle;
              }
              else if(parseInt(e.approved)===REFUSED){
                color = RED_DENIED;
                icon = faTimesCircle;
              }

              return {
                ...e,
                typeLabel:type,
                approvedIcon:<TableIcon 
                  icon={icon}
                  color={color}
                  callback={this.props.admin?() => {
                    this.props.invoiceTogglePending({
                      collection:'manual',
                      id:e.id
                    })
                    // let id = "/facture/1"
                    
                    // this.setState({redirection:id})
                  }:false} 
                />,
                
                
                edit:<TableIcon
                  callback={() => {
                    this.edit({
                      collection:'manual',
                      id:e.id
                    })
                    // let id = "/facture/1"
                    
                    // this.setState({redirection:id})
                  }}
                />,
              }
            });

            let automatic = [
              ..._.filter(lines,{type:AUTOMATIC}),
              ...linesAuto
            ]

            this.props.invoiceFetch({manual:manual,automatic:automatic});


            this.setState({
              user_id:user_id,
              userList: [
                { auth_id: -1, firstname: "Tous", lastname: " " },
                ...res.data.data[0].lawyers,
              ],
              types: [...res.data.data[0].types],
              specialities: [...res.data.data[0].specialities],
              durations: [...res.data.data[0].durations],
              data:[...data]
            },() => this.props.toggleLoading());
          }
          else{
            
            this.setState({
              alertColor:DANGER,
              alertVisible:true,
              alertMessage:'Une erreur (tragique) est survenue'
            })
          }

        },
        (error) => {
          this.setState({
            alertColor:DANGER,
            alertVisible:true,
            alertMessage:(error.message)?error.message:'Une erreur (tragique) est survenue'
          },() => this.props.toggleLoading() );

        }
      )
    }
  }

    componentDidUpdate(prevProps){
      let obj = {};
      
      if(prevProps){
        
        if(prevProps.specialities&&!prevProps.specialities.isEqual(this.props.specialities)){
          obj.specialities = this.props.specialities
        }

        if(prevProps.types&&!prevProps.types.isEqual(this.props.types)){
            obj.types = [{title:'Tous',value:0,specialities:[{id:0}]},...this.props.types]
        }

        if(prevProps.durations&&!prevProps.durations.isEqual(this.props.durations)){
            obj.durations = this.props.durations
        }

        if(prevProps.userList&&!prevProps.userList.isEqual(this.props.userList)){
            obj.userList = [{auth_id: -1,firstname:"Tous",lastname:' ',type:elawyer},...this.props.userList];
        }
      }
      
      if(_.keys(obj).length)
          this.setState(obj);
    }

    // shouldComponentUpdate(nextProps){
    //   console.log('i am wondering if i should update')
    //   if(nextProps){
    //     console.log('hey i tried ok?',Array.isArray(nextProps.manual)&&!nextProps.manual.isEqual(this.props.manual))
    //     if(Array.isArray(nextProps.manual)&&!nextProps.manual.isEqual(this.props.manual)){
    //       return true
    //     }
  
    //     if(Array.isArray(nextProps.automatic)&&!nextProps.automatic.isEqual(this.props.automatic)){
    //       return true
    //     }
    //   }


    //   return false
    // }

    handleSubmit(){
      this.props.toggleLoading();

      let m = [
        ..._.map(this.props.manual,(elt) => {
        return  {
          approved:elt.approved||PENDING,
        "id":elt.id,
        "user_id": elt.user_id||this.state.user_id,
        "ticket_id":elt.ticket_id,
        "description":elt.description,
        "service_created":elt.service_created||moment().format(DATE_FORMAT),
        "tarif": elt.tarif,
        "start": elt.start,
        "end": elt.end,
        "type": MANUAL,
        "type_id":elt.type_id,
        // "invoice_id":this.state.facture ,
        'to_invoice':elt.to_invoice||'1',
        active:1
        }
      }),
      ..._.map(this.props.automatic,(elt) => {

        return  {
          approved:elt.approved||PENDING,
        "event_id":elt.event_id,
        "id":elt.id,
        "user_id":elt.user_id||this.state.user_id,
        "ticket_id":elt.ticket_id,
        "description":elt.description,
        "service_created":elt.service_created||moment().format(DATE_FORMAT),
        "tarif": elt.tarif,
        "type_id":elt.type_id,
        "start": elt.start,
        "end": elt.end,
        "type": AUTOMATIC,
          // "invoice_id":this.state.facture,
          'to_invoice':elt.to_invoice||'1',
          active:1
        }
      })];
      
      console.log('=====>',m)
      // debugger;

      this.setState({
        blob:'',
        pdfTitle:''
      });

      let uID = this.props["userId"];

      if(!uID){
        let token = window.parseJwt(localStorage.getItem('token'));
        
        uID=token.sub
        
      }

      let params = {
        auth_id:uID,
        invoice_id:this.state.facture,
        start_date:"",
        end_date:"",
        format:'pdf',
        data:m,
        
      }
      
      if(this.props.lang){
        params.lang = this.props.lang;
      }

      if(this.state.user_id!== NEW)
        params.user_id = this.state.user_id;
        
      axios
        .post('invoices/line',params)
        .then((response) => {
          
          this.props.toggleLoading();

          if(response && response.data && response.data.data){

            if(!response.data.data.pdf||!response.data.data.lines.length){

              if(this.state.facture===NEW && response.data.data.invoice_id){
                let stateObj = { id: '100' }; 
                window.history.replaceState(stateObj, "Facture", "/facture/"+response.data.data.invoice_id); 
              }
              
              return this.setState({
                facture:response.data.data.invoice_id,
                alertColor:INFO,
                alertVisible:true,
                alertMessage:'La facture n\'a aucune ligne facturable ou approuvée pour le moment'
              })
            }

            let  byte,blob;            
            byte = atob(response.data.data.pdf.replace(/^data:application\/pdf;base64,/,''))
            let buffer = new ArrayBuffer(byte.length);
            let view = new Uint8Array(buffer);
            for (var i = 0; i < byte.length; i++) {
                view[i] = byte.charCodeAt(i);
            }
            
            blob = URL.createObjectURL(new Blob([view] , {type:'application/pdf'}));
            
            let old_id = response.data.data.old;
            
            for(let o in old_id){
              // found = _.find(this.props.manual,{id:old_id[o].old_id});
              // found.id = old_id[o].id;
              // debugger
              this.props.invoiceListEditIndexManual(old_id[o])
              
            }


            this.setState({
              blob:blob,
              pdfTitle:'',
              facture:response.data.data.invoice_id,
              alertColor:INFO,
              alertVisible:true,
              alertMessage:'Le document est prêt à être téléchargé'
            }, () => {
            })
          }
        },
        (error) => {
          this.props.toggleLoading();
          this.setState({
            alertColor:DANGER,
            alertVisible:true,
            alertMessage:'Une erreur est survenue'
          }, () => {
          })
        }
      );
    }

    toggleInvoice(invoice){
      // debugger;
      if(invoice.type.toString() === MANUAL){
          invoice.to_invoice = invoice.to_invoice === '1'? '0': '1';
          this.props.invoiceListEditManual(invoice);
      }
      else if(invoice.type.toString() === AUTOMATIC){ 
          invoice.to_invoice = invoice.to_invoice === '1'? '0': '1';
          this.props.invoiceListEditAutomatic(invoice);
      }
      else
        console.error('type is invalid or missing in invoice!')
    }
    render(){

      // if(this.state.redirection)
      //   return (<Redirect push to={{pathname:this.state.redirection}} />);
      
      let type = _.find(this.state.types,{
        id:parseInt(this.state.type)
      });

      if(!type){
        type = {
          label:'indéfini',
          specialities:[{id:0}],
          value:1
        }
      }
      
      let state = this.state;
        return (
          <>
            {/*           
          <Col md={3}>
            <FormGroup>
              {'userId'}
              <Input name="amount" value={this.state.user_id} onChange={(e) => this.setState({userId:e.target.value})} type={"number"} />
            </FormGroup>
          </Col> */}
            <Row>
              <Col>
                <Button
                  id="PDF-preview"
                  style={{
                    position: "absolute",
                    color: "white",
                    right: 0,
                    top: -5,
                    display: this.state.blob ? BLOCK : NONE,
                  }}
                  tag="a"
                  href={this.state.blob}
                  target="_blank"
                  download={this.state.pdfTitle}
                  type="button"
                  color="dark"
                  onClick={() => localStorage.removeItem("PC_save")}
                  size="md"
                >
                  <i className="fas fa-save"></i>
                </Button>
                <NeoLabel><>{this.genLang({fr:'Facturation automatique',en:'Automatic billing'})}</></NeoLabel>
                <Table
                  data={_.map(this.props.automatic, (d) => {

                    return {
                      ...d,
                      // 'service_created':'lunatique',
                      ticketId: (
                        <A base={ZD} params={d.ticket_id} anchor={d.ticket_id}>
                          {d.ticket_id}
                        </A>
                      ),
                      invoice: (
                        <TableIcon
                          icon={
                            parseInt(d.to_invoice) === 1
                              ? faCoins
                              : faTimesCircle
                          }
                          callback={() => this.toggleInvoice(d)}
                        />
                      ),
                    };
                  })}
                  columns={this.props.admin ? _.map(adminColumns,(e) => {
                    return {
                      ...e,
                      'Header':this.genLang(e.Header,'en')
                    }
                  }) : _.map(columns,(e) => {
                    return {
                      ...e,
                      'Header':this.genLang(e.Header,'en')
                    }
                  })}
                />
                <NeoLabel><>{this.genLang({fr:'Facturation manuelle',en:'Manual billing'})}</></NeoLabel>
                <Table
                  data={_.map(this.props.manual, (d) => {

                    

                    return {
                      ...d,
                      // 'service_created':'lunatique',
                      ticketId: (
                        <A base={ZD} params={d.ticket_id} anchor={d.ticket_id} >
                          {d.ticket_id}
                        </A>
                      ),
                      invoice: (
                        <TableIcon
                          icon={
                            parseInt(d.to_invoice) === 1
                              ? faCoins
                              : faTimesCircle
                          }
                          callback={() => this.toggleInvoice(d)}
                        />
                      ),
                    };
                  })}
                  columns={this.props.admin ? _.map(adminColumns,(e) => {
                    return {
                      ...e,
                      'Header':this.genLang(e.Header,'en')
                    }
                  }) : _.map(columns,(e) => {
                    return {
                      ...e,
                      'Header':this.genLang(e.Header,'en')
                    }
                  })}
                />
                <Card>
                  <CardHeader 
                    className={'text-center p-t-10 p-b-5'}>
                    <h4
                      // style={{textAlign:'center'}}
                    >
                      {this.genLang({fr:"Ajouter une demande de facturation",en:"Add acustom billing line"})}
                    </h4>
                  </CardHeader>
                  <CardBody>
                  <Form>
                  <FormGroup>
                    <Row>
                      <Col md={3}>
                        <FormGroup>
                          {this.genLang({en:'Kind of appointment',fr:'Type de rendez-vous'})} {this.state.label}
                          <Select
                            styles={{
                              input: () => {return {
                                padding: 8
                              }}
                            }
                            }
                            value={{
                              label: type.title || "",
                              value: type.id || 0,
                              speciality: type.specialities[0].id || 0,
                            }}
                            onChange={(e) => {
                              if (!this.state || !this.state.specialities || !e)
                                return;

                              let speciality = _.find(this.state.specialities, {
                                id: e.speciality,
                              });

                              if (speciality) {
                                this.setState({
                                  speciality: speciality.id,
                                  type: e.value,
                                });
                              }
                            }}
                            options={_.map(this.state.types, (s) => {
                              return {
                                label: s.title,
                                value: s.id,
                                speciality: s.specialities[0].id,
                              };
                            })}
                            name="license"
                            isClearable
                            isSearchable
                          />
                        </FormGroup>
                      </Col>
                      <Col md={2}>
                        <FormGroup>
                        {this.genLang({en:'Ticket#',fr:'#ticket'})}
                          <Input
                            value={this.state.ticket}
                            name="ticket"
                            type={"number"}
                            onChange={(e) =>
                              this.setState({ ticket: e.target.value })
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col md={3}>
                        <FormGroup>
                        {this.genLang({en:'Description',fr:'Description'})}
                          <Input
                            name="description"
                            value={this.state.description}
                            onChange={(e) =>
                              this.setState({ description: e.target.value })
                            }
                            type={"text"}
                          />
                        </FormGroup>
                      </Col>

                      <Col md={2}>
                        <FormGroup>
                        {this.genLang({en:'Price',fr:'Sum'})}
                          <Input
                            name="amount"
                            value={this.state.amount}
                            onChange={(e) =>
                              this.setState({ amount: e.target.value })
                            }
                            type={"number"}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={1}>
                        <Button
                          // style={{marginTop: '24px!important'}}
                          color={"success"}
                          onClick={
                            this.state.editId ? this.editLign : this.addLign
                          }
                          type="button"
                          // size="md"
                          className={"m-t-25"}
                        >
                          {this.state.editId ? this.genLang({en:"Edit",fr:"Modif"}): this.genLang({en:"Add",fr:"Ajouter"})}
                        </Button>
                      </Col>
                    </Row>
                    <Row>
                      <Col> 
                        <AlertNotification
                          color={state.alertColor}
                          visible={state.alertVisible}
                          message={state.alertMessage}
                        />
                      </Col>
                    </Row>
                  </FormGroup>
                </Form>
                  </CardBody>
                </Card>
                
              </Col>
            </Row>
            <Row>
              <Col>
                <p style={{ textAlign: "center" }}>
                  <Button
                    color={"primary"}
                    onClick={this.handleSubmit}
                    type="button"
                    size="md"
                    className={"m-t-10"}
                  >
                    {this.genLang({en:"Save",fr:"Enregistrer"})}
                  </Button>
                </p>
              </Col>
            </Row>
          </>
        );
    }
} 

const mapStateToProps = (state) => ({
  userList:state.users.userList,
  specialities:state.users.specialities,
  types:state.users.types,
  durations:state.users.durations,
  manual:state.invoices.manual,
  automatic:state.invoices.automatic,
  admin:state.users.result.admin,
  userId:state.users.result.userId,
  lang:state.users.result.language,
});


const mapActionsToProps = {
  invoiceFetch:rootsActions.invoiceActions.invoiceFetch,
  invoiceAddManual:rootsActions.invoiceActions.invoiceAddManual,
  invoiceListEditIndexManual:rootsActions.invoiceActions.invoiceListEditIndexManual,
  invoiceListEditManual:rootsActions.invoiceActions.invoiceListEditManual,
  invoiceListEditAutomatic:rootsActions.invoiceActions.invoiceListEditAutomatic,
  invoiceTogglePending:rootsActions.invoiceActions.invoiceTogglePending,
  toggleLoading:rootsActions.loadingActions.toggleLoading,
}

// export default connect(mapStateToProps)(FactureTable)
export const Facturation = connect(mapStateToProps,mapActionsToProps)(FactureTable);

// export const FeesByGroupForm = connect(mapStateToProps)(ByGroup);
// export const FeesTable = connect(mapStateToProps)(FTable);
