import { 
    TOGGLE_LOADING
} from '../_actions/loading-actions.js';
  
export default function loadingReducer(
    state = '',
    {type,params}
){
    switch(type){
        case TOGGLE_LOADING:
            return {loading:!state.loading}
        default:
            return state;
    }
}