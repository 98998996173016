
import React from 'react';

import {
    FormGroup,
    Label,
    Input
} from 'reactstrap';

import {
    none,
    block
} from '../../_const';

export default class CheckInput extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            checked:!!this.props.default,
            // display:'none'
        }
    }

    componentDidUpdate(prevProps){
        if(this.props.allvisible !== prevProps.allvisible && !this.state.mandatory) {
            this.setState({display:(this.props.allvisible)?none:block})
        }
    }

    render(){
        return (<FormGroup neokey={this.props.neokey} check>
            <Label 
                check
                // onClick={(e) => this.setState({display:(!this.state.mandatory&&this.state.display==='block')?'none':'block'})}
                >
            <Input
            
                onChange={(e) => {
                    this.setState({
                        checked:e.target.checked
                    },() =>{
                            this.props.self.setForm(this.props.title,this.state.text,this.props.zendesk);
                        }
                    )}
                }
                
                zendesk={this.props.zendesk||''}
                type={this.props.type}
                value={this.state.checked}
                id={this.props.id} />{this.props.title}</Label>
        </FormGroup>)
    }
}