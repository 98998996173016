import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faSearch,
    faCoins,
    faTachometerAlt,
    faTags,
    faFilter,
    faGlobeAmericas,
    faPlusCircle
} from '@fortawesome/free-solid-svg-icons';

import React, { 
    Component} from 'react';
import { 
    Alert,
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    Navbar,
    NavbarBrand,
    Nav,
    NavItem,
    FormGroup,
    Input,
    InputGroup,
    InputGroupAddon,
    // Table
} from 'reactstrap';
import DashboardElement from './utils/DashboardElement'
import Container from 'reactstrap/lib/Container';
import Button from 'reactstrap/lib/Button';
import Table from './utils/Table';
// import Booking from './Booking'
import TableIcon from './utils/TableIcon';
import Booking from './Booking';

const PRIMARY = 'primary';
const NONE = 'none';
const BLOCK = 'block';
  
const logo = require('../assets/img/brand/neolegal.png');
const columns = [
    {
        Header: '#ticket',
        accessor: 'ticketId',
    },
    {
        Header: 'Nom du client',
        accessor: 'name',
    },
    {
        Header: 'Description',
        accessor: 'description',
    },
    {
        header:'Action!',
        accessor:'action'
    }
  ];

const data = [{
    ticketId:'52583',
    name:'Bongo Boisvert',
    description:'chien du voisin pas gentil',
    action:<TableIcon
            icon={faCoins}
            callback={() => console.log('wouhou')}
        />
    },
    {
    ticketId:'52443',
    name:'bobain Langevin',
    description:'Dur de vivre l\'amour en Afghanistan',
    action:<TableIcon
            icon={faCoins}
            callback={() => console.log('wouhou')}
        />
    },
    {
    ticketId:'777',
    name:'Alaine Trouvelaille',
    description:'Astur me fait peur',
    action:<TableIcon
            icon={faCoins}
            callback={() => console.log('wouhou')}
        />
    },
    

]

class Lead extends Component {
    constructor(props){
        super(props);

    }

    render(){

        return(
            <Container style={{
                minHeight:'100%',
                height:'100vh',
                color:"#ededed",
                // background:"232323"
                // background: 'repeating-linear-gradient(-45deg, #454545, #454545 10px, #242424 10px, #242424 20px)'
                 background: 'repeating-linear-gradient(-45deg, #efefef, #efefef 20px, #fefefe 20px, #fefefe 30px)'
            }}
                
                className="menu-w color-style-bright menu-position-side menu-side-left menu-layout-mini sub-menu-style-over sub-menu-color-bright selected-menu-color-light menu-activated-on-hover menu-has-selected-link"
            >
                <Row>
                    <Col md={2} sm={2}>
                        <Nav
                            style={{
                                textAlign:'center',
                                background:'#1c4cc3',
                                marginLeft:'-15px',
                                minHeight:'100vh',
                                maxWidth:'200px'
                            }} 
                            
                            vertical
                        >
                            <NavbarBrand logo style={{
                                textAlign:'center',padding:0,margin:'0 auto'}}>
                                <div className="logo-w">
                                    <img className="l-logo" src={logo||"https://www.neolegal.ca/static/assets/img/logo-white-n.png"} alt="Neolegal" width={'400px'}></img>
                                </div>
                            </NavbarBrand>
                            {/* <Nav> */}
                            <NavItem>
                                <Row>
                                    <DashboardElement
                                        title={"Performance"}
                                        col={12}
                                    >
                                        <h5 style={{}}>Moi</h5>
                                        <div style={{fontSize:'40pt'}}>
                                            <FontAwesomeIcon icon={faTachometerAlt} color="#0052BB"/>
                                        </div>
                                        <h5 style={{}}>Équipe</h5>
                                        <div style={{fontSize:'40pt'}}>
                                            <FontAwesomeIcon icon={faTachometerAlt} color="#0052BB"/>
                                        </div>
                                    </DashboardElement>
                                </Row>
                            </NavItem>
                            {/* </Nav> */}
                        </Nav>
                    </Col>
                    <Col md={7} >
                        <Row>
                            <Col md={12}>
                                <h1 style={{color:"#0052BB"}} className='text-align-center m-t-15'>Leads</h1>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12} style={{background:""}}>

                               <DashboardElement 
                                    col={12}
                                    title={<>Mes prochains leads <FontAwesomeIcon icon={faTags} /></>}
                                >
                                    <Row>
                                        <Col md={12}>
                                            <Nav
                                                style={{
                                                    paddingTop:"0",
                                                    marginBottom:"10px"
                                                    // textAlign:'center',
                                                    // background:'#1c4cc3',
                                                    // marginLeft:'-15px',
                                                    // minHeight:'100vh'
                                                }} 
                                            >
                                                {/* <Nav> */}
                                                    <NavItem style={{marginRight : '1em'}}>
                                                        <InputGroup>
                                                            <InputGroupAddon addonType="prepend">
                                                            <span className="input-group-text"> <FontAwesomeIcon icon={faSearch} /></span>
                                                            </InputGroupAddon>
                                                            <Input placeholder="Recherche" />
                                                        </InputGroup>
                                                    </NavItem>
                                                    <NavItem style={{marginRight : '1em'}}>
                                                        <InputGroup>
                                                            <InputGroupAddon addonType="prepend">
                                                            <span className="input-group-text"> <FontAwesomeIcon icon={faFilter} /></span>
                                                            </InputGroupAddon>
                                                            <Input placeholder="domaine" />
                                                        </InputGroup>
                                                    </NavItem>
                                                    <NavItem style={{marginRight : '1em'}}>
                                                        <InputGroup>
                                                            <InputGroupAddon addonType="prepend">
                                                            <span className="input-group-text"> <FontAwesomeIcon icon={faGlobeAmericas} /></span>
                                                            </InputGroupAddon>
                                                            <Input placeholder="Province" />
                                                        </InputGroup>
                                                    </NavItem>
                                                    <NavItem className="ml-auto"> 
                                                        <Button color={'primary'}>
                                                            <FontAwesomeIcon icon={faPlusCircle} />
                                                        </Button>
                                                    </NavItem>
                                                {/* </Nav> */}
                                            </Nav>
                                        </Col>
                                    </Row>
                                    <Table
                                    data={data}
                                    columns={columns}
                                    />
                                </DashboardElement>
                                </Col>
                        </Row>
                    </Col>
                    <Col md={3} className="m-0 p-0 lead-board">
                        <Row>
                            <DashboardElement
                                col={12}
                                height={'400px'}
                                maxHeight={'400px'}
                                title={'Calendrier'}
                            >
                                <Booking />
                            </DashboardElement>
                            
                            <DashboardElement
                                col={12}
                                height={'200px'}
                                maxHeight={'200px'}
                                title={'Calendrier'}
                            >
                                <Booking />
                            </DashboardElement>
                        </Row>
                    </Col>
                </Row>
            </Container>
        )
        // return (
            // <div className="main-content login" >
            //     <div className="header bg-gradient-info py-5 py-lg-5" style={{height:'100vh'}}>
                {/* <Container > */}
                    
                    // <div className="header-body text-center " style={{paddingBottom:'30px'}}>
                    //     <img src={logo} width="300px;" alt="Neolegal"></img>
                    // </div>
                    // <Row>
                    //     <Col 
                    //         lg={{ size: 4, offset: 4 }}
                    //         md={{ size: 6, offset: 3 }}
                    //         // sm={{ size: 12 }}
                    //     >
                    //         <Card>
                    //             <CardBody>
                                    
                    //                 <CardTitle style={{textAlign:'center'}}><h2>Expiration</h2></CardTitle>
                    //                 --------------LEAD------------
                    //             </CardBody>
                    //         </Card>
                    //     </Col>
                    // </Row>
                    
                {/* </Container> */}
            //     </div>
            //   </div>)
        // )
    }
}

export default Lead;