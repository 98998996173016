import axios from 'axios';

//const PATH = 'https://fedd8d42.ngrok.io';
const PATH = 'https://7f2c47df.ngrok.io';

const myApi = axios.create({
    baseURL: PATH,
    timeout: 10000,
    withCredentials: false,
    transformRequest: [(data) => JSON.stringify(data.data)],
    headers: {
      // 'Accept': 'application/json',
      
      // 'WWW-Authenticate':"banana",
      // 'Authorization':'banana',
      // "Access-Control-Allow-Origin":"*",
      'Content-Type': 'application/json'
    },
    auth:{
      username:'admin',
      password:'6d21b4833368d61c31275092886a4d9add8f7801abd7892d017b3f19a37b30c8'
    },
  });

export default function Email(
    type = 'put',
    params={},
    body = {
      auth_id:localStorage.getItem('user_id'),
    },
    callback = (response) => {console.log('----->>>>',response);return response} ){

      myApi[type](
          '/emails/15',{
              // params:params,
              data:body
          }
    ).then(callback).catch(
      (err)=> console.log(err));
}

