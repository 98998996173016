import React from 'react';
import {
    FormGroup,
} from 'reactstrap';

export default class NeoLabel extends React.Component{
    render(){
        return(
        <FormGroup tag={"fieldset"}>
            <legend>
                <span className={this.props.class?this.props.class:''}>
                    {this.props.children||'** COMPOSANTE VIDE **'}
                </span>
            </legend>
        </FormGroup>);
    }
}
