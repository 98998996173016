import React, { useState} from 'react';
import { 
    Alert,
    Row,
    Col,
    Card,
    CardBody,
    CardTitle
} from 'reactstrap';

const PRIMARY = 'primary';
const NONE = 'none';
const BLOCK = 'block';
  
const logo = require('../assets/img/brand/neolegal.png');

function Take() {
    const [alertColor] = useState(PRIMARY);
    const [alert] = useState('');

  return (
    <div className="main-content login" >
        <div className="header bg-gradient-info py-5 py-lg-5" style={{height:'100vh'}}>
        {/* <Container > */}
            
            <div className="header-body text-center " style={{paddingBottom:'30px'}}>
                <img src={logo} width="300px;" alt="Neolegal"></img>
            </div>
            <Row>
                <Col 
                    lg={{ size: 4, offset: 4 }}
                    md={{ size: 6, offset: 3 }}
                    // sm={{ size: 12 }}
                >
                    <Card>
                        <CardBody>
                            <div style={{display:!!alert?BLOCK:NONE}}>
                                <Alert color={alertColor}>
                                    {alert}
                                </Alert>
                            </div>
                            
                            <CardTitle style={{textAlign:'center'}}><h2>Expiration</h2></CardTitle>
                            Votre session est expirée, veuillez rejoindre le service client si vous avez des questions.
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            
        {/* </Container> */}
        </div>
      </div>
  )
}

export default Take;