import { 
  LOGIN,
  TOGGLE,
  LAWYER_LIST,
  SET_VISIBLE,
  CHANGE_FIELD,
  INIT_DATA,
  SET_VISIBLE_BY_NEOLEGAL_EXCLUSIVE 
} from '../_actions/users-actions';

  
import _ from 'lodash';


const elawyer = "elawyer";


export default function usersReducer(
  state = '',
  {type,params}
){

  switch(type){
    case INIT_DATA:
      return {
        ...state,
        ...params
      }
    case CHANGE_FIELD:
      // params contains name and value
      return {...state,fieldChange:{name:params.name,value:params.value}}
    case LAWYER_LIST:
      
      if(localStorage.getItem('type')===elawyer){

        let token = window.parseJwt(localStorage.getItem('token'));
        
        let uID=token.sub;
          
        return {
          ...state,
          work:params.work,
          userList:[_.find(params.lawyers,{"auth_id":uID})],
          visible:[uID],
          categories:params.categories
        }
      }
      else{
        return {
          ...state,
          userList:params.lawyers,
          work:params.work,
          categories:params.categories,
        }
      }
    case TOGGLE:
      return {...state,toggle:!state.toggle}
    case SET_VISIBLE:
      return {
        ...state,
        visible:params.visible
      }
    case SET_VISIBLE_BY_NEOLEGAL_EXCLUSIVE:
      
      return {
        ...state,
        neolegalExclusive:params.neolegalExclusive
      }
    case LOGIN:
      
      if(params.status === 200){

        // localStorage.setItem('guid',params.token.split(/\./)[0])
        localStorage.setItem('token',params.token);
        // localStorage.setItem('user_id',params.id_users);
        // localStorage.setItem('email',params.email);
        // localStorage.setItem('firstName',params.userName);
        // localStorage.setItem('admin',params.admin||false);
        localStorage.setItem('id_zendesk',params.id_zendesk||false);
        // localStorage.setItem('type',params.type||'sales');
        localStorage.setItem('ampm',params.ampm||'false');
        localStorage.setItem('dispo',params.dispo||params.admin||'false');
        delete params.status;

        let decodedToken = window.parseJwt(localStorage.getItem('token'))||{scope:{}}
        
        return {
          ...state,
          "status": !!params.token,
          "result": {
              "userId": decodedToken.sub,
              "email": decodedToken.scope.login,
              language: decodedToken.scope.language || "fr",
              "token": params.token,
              "admin":decodedToken.scope.admin,
              "ampm":(('ampm' in decodedToken.scope))?decodedToken.scope.ampm:localStorage.getItem('ampm') === 'true',
              "dispo":decodedToken.scope.dispo,
              "id_zendesk":(('id_zendesk' in decodedToken.scope))?decodedToken.scope.id_zendesk:localStorage.getItem('id_zendesk'),
              "type":(('type' in decodedToken.scope))?decodedToken.scope.type:localStorage.getItem('type')
          }
        }
      }
      else{
        // localStorage.clear();
        return state;
      }
    default:
      return state;
  }
}