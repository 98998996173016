import React, { Component } from 'react';
import {
    BrowserRouter as Router,
    Route,
    Switch,
    Redirect,
} from "react-router-dom";

import {connect} from 'react-redux';
import Auth from './layouts/Auth';
import Booking from './component/Booking';
import BookingTicket from './component/BookingTicket';

// import Lawyer from './component/Lawyer';
import Admin from './component/Admin';
import Groups from './component/Groups';
import Schedule from './component/Schedule';
import Lead from './component/Lead';

import ExternFacturation from './component/ExternFacturation';

import {FacturationList} from './component/adminDashboard/FacturationList';

import {FacturationListAdmin} from './component/adminDashboard/FacturationListAdmin';
import ExternDashboard from './component/ExternDashboard';


// import UserPanel from './component/UserPanel';
import ScheduleTimer from './component/ScheduleTimer';
import NeoForms from './component/NeoForms';

import { ProtectedRoute } from './component/protected';
import NewLawyer from './component/NewLawyer';
import Take from './component/Take';

import Expire from './component/Expire';

class SilkRoad extends Component {

    constructor(props){
        super(props)

        this.isAuth = this.isAuth.bind(this);
    }

    push(){
        // console.log('anone')
        // console.log(this.props)
    }

    render(){
        return (
            <Router>
                <Switch>
                    
                    <Route 
                        exact
                        path={"/logout"}
                        render={ () => {
                            localStorage.clear();
                            return <Redirect to={{pathname:"/login"+window.location.search}} />
                        }} />
                    <Route 
                        exact
                        path="/login"
                        render={() => {
                            if(this.isAuth()){
                                if(window.location.search&&window.location.href.match(/&return=/)){
                                    let split = window.location.search.split(/&return=/);
                                    window.location = split[1]+split[0]
                                }
                                else if(window.location.search&&window.location.href.match(/\?return=/)){
                                    let split = window.location.search.split(/\?return=/);
                                    window.location = split[1]+split[0]
                                }
                                else{
                                    return <Redirect to={{pathname:"/"}} />
                                }
                            }
                            return <Auth />
                        }}/>
                        
                    <Route 
                        exact
                        path={"/take/:JWT"}
                        render={ (route) => {
                            localStorage.setItem('token-client',route.match.params.JWT)
                            return <Redirect to={{pathname:'/take'}} />
                    }} />
                    <Route 
                        exact
                        path={"/take"}
                        render={ () => {
                            
                            return <Take />
                    }} />
                    <Route 
                        exact
                        path={"/expire"}
                        render={ () => {
                            
                            return <Expire />
                    }} />
                    
                    <ProtectedRoute exact path="/lead"
                        zendesk={(this.props.users)?this.props.users.result.id_zendesk:''}  
                        admin={(this.props.users)?this.props.users.result.admin:''} 
                        component={Lead}
                    />

                    <ProtectedRoute exact path="/calendar"
                        zendesk={(this.props.users)?this.props.users.result.id_zendesk:''}  
                        admin={(this.props.users)?this.props.users.result.admin:''} 
                        component={Admin}
                    />

                    {/* <ProtectedRoute exact path="/lawyer/:id"
                        component={Lawyer} 
                    /> */}
                    
                    {/* <ProtectedRoute exact path="/"
                        zendesk={(this.props.users)?this.props.users.result.id_zendesk:''}
                        admin={(this.props.users)?this.props.users.result.admin:''} 
                        component={Schedule} 
                    /> */}
                    <ProtectedRoute exact path="/schedule"
                        zendesk={(this.props.users)?this.props.users.result.id_zendesk:''}
                        admin={(this.props.users)?this.props.users.result.admin:''} 
                        component={Schedule} 
                        neolegalExclusive={this.props.neolegalExclusive}
                    />

                    {/* <ProtectedRoute exact path="/scheduletimer"
                        zendesk={(this.props.users)?this.props.users.result.id_zendesk:''}
                        admin={(this.props.users)?this.props.users.result.admin:''} 
                        component={ScheduleTimer} 
                    /> */}

                    <ProtectedRoute exact path="/user"
                        admin={(this.props.users)?this.props.users.result.admin:''} 
                        component={NewLawyer} 
                    />

                    <Route  exact path="/public/user/:token"
                        admin={(this.props.users)?this.props.users.result.admin:''} 
                        component={NewLawyer} 
                    />

                    <ProtectedRoute exact path="/admin"
                        component={Groups} 
                    />

                    {/* <ProtectedRoute exact path="/formulaires"
                        admin={(this.props.users)?this.props.users.result.admin:''} 
                        zendesk={(this.props.users)?this.props.users.result.id_zendesk:''} 
                        component={NeoForms} 
                    />

                    <ProtectedRoute exact path="/formulaires/user"
                        userForm={true}
                        admin={(this.props.users)?this.props.users.result.admin:''} 
                        zendesk={(this.props.users)?this.props.users.result.id_zendesk:''} 
                        component={NeoForms} 
                    /> */}
                    
                    <ProtectedRoute 
                        path="/externe"
                        admin={(this.props.users)?this.props.users.result.admin:''} 
                        component={ExternDashboard} 
                    />

                    <ProtectedRoute 
                        facture={{...this.props}}
                        path="/facture/:facture"
                        admin={(this.props.users)?this.props.users.result.admin:''} 
                        component={ExternFacturation} 
                    />
                                        
                    {/* <ProtectedRoute 
                        path="/facture"
                        admin={(this.props.users)?this.props.users.result.admin:''} 
                        component={FacturationList} 
                    /> */}

                    <Route path={"/factures"}>
                        {(this.props.users&&this.props.users.result.admin)?
                            <ProtectedRoute 
                                path="/factures"
                                admin={(this.props.users)?this.props.users.result.admin:''} 
                                component={FacturationListAdmin} 
                            />:
                            <ProtectedRoute 
                                path="/factures"
                                admin={(this.props.users)?this.props.users.result.admin:''} 
                                component={FacturationList} 
                            />
                        }
                    </Route>
                    
                    <ProtectedRoute 
                        path="/booking-ticket"
                        zendesk={(this.props.users)?this.props.users.result.id_zendesk:''} 
                        admin={(this.props.users)?this.props.users.result.admin:''} 
                        component={BookingTicket} 
                    />
                    <ProtectedRoute 
                        path="/booking"
                        zendesk={(this.props.users)?this.props.users.result.id_zendesk:''} 
                        admin={(this.props.users)?this.props.users.result.admin:''} 
                        component={Booking} 
                    />

                    <ProtectedRoute 
                        path="/"
                        admin={(this.props.users)?this.props.users.result.admin:''} 
                        component={Groups} 
                    />
                </Switch>
            </Router>
        )
    }

    isAuth(path){
        if(this.props.users.result&&this.props.users.result.token&&localStorage.getItem('token'))
            return true;
        else{
            // this.setState('returnUrl',path)
            return false;
        }
    }
}


const mapStateToProps = state => ({
    users:state.users,
    neolegalExclusive:state.users.neolegalExclusive
  });

export default connect(mapStateToProps)(SilkRoad)