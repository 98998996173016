import React, { useState,useRef} from 'react';
import { privateAxios as axios } from "../../utils/axios";

import InputMask from 'react-input-mask';

import { 
    Form,
    FormGroup,
    Label,
    Input,
    Alert,
    Container,
    Button
} from 'reactstrap';
import Dropzone from '../utils/Dropzone';
const LAWYER = "lawyer";
const ELAWYER = "elawyer";
const NOTARY = 'notary'
const SALES = "sales";
const NEOPHONE = '(855) 996-9695';
const DANGER = 'danger';
const PRIMARY = 'primary';
const NONE = 'none';
const BLOCK = 'block';
  
function UserPanel() {
    const [lastname, setLastname] = useState('');
    const [firstname, setFirstname] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [cell, setCell] = useState(NEOPHONE);
    const [role, setRole] = useState(LAWYER);
    const [alertColor,setAlertColor] = useState(PRIMARY);
    const [alert, setAlert] = useState('');
    const [sign,setSign] = useState('');

    var signaturePad = useRef(null);
    
    function handleSubmit(){
        axios
            .post("/user", 
                {
                    auth_id:localStorage.getItem('user_id'),
                    lastname:lastname,
                    firstname:firstname,
                    sign:signaturePad.toDataURL(),
                    password:password,
                    email:email,
                    cell:cell,
                    type:role
                }
            )
            .then(
                (status) =>{
                    console.log(sign);
                    
                    setAlertColor(PRIMARY);
                    setAlert(firstname+' '+lastname +' a bel et bien été ajouté')
                    setLastname('')
                    setFirstname('')
                    setEmail('')
                    setPassword('')
                    setCell(NEOPHONE)
                    setRole('')
                },
                (err) =>{
                    console.log(err);
                    
                    setAlertColor(DANGER);
                    setAlert('Erreur du serveur')
                },
            )
        
    }

  return (

      <Container >
        <div style={{display:!!alert?BLOCK:NONE}}>
            <Alert color={alertColor}>
                {alert}
            </Alert>
        </div>
        <Form>
            <FormGroup>
                <Label for="firstname">Prénom</Label>
                <Input type="text" value={firstname} name="firstname" id="firstname"  onChange={ (e) => setFirstname(e.target.value)} />
            </FormGroup>
            <FormGroup>
                <Label for="lastname">Nom</Label>
                <Input type="text" value={lastname} name="lastname" id="lastname" onChange={ (e) => setLastname(e.target.value)}  />
            </FormGroup>
            <FormGroup>
                <Label>Téléphone</Label>
                <InputMask 
                    className={"form-control"}
                    value={cell}
                    id="cell"
                    mask={"(999) 999-9999"} 
                    maskChar=" " 
                    onChange={(e) => setCell(e.target.value)} 
                />
            </FormGroup >
            <FormGroup>
                <Label for="email">Email</Label>
                <Input autoComplete="new-username" type="email" value={email} name="email" id="email" onChange={ (e) => setEmail(e.target.value)}  />
            </FormGroup>
            <FormGroup>
                <Label for="password">Mot de passe (si vide, ce sera celui par défaut)</Label>
                <Input autoComplete="new-password" type="password" value={password} name="password" id="password" onChange={ (e) => setPassword(e.target.value)}  />
            </FormGroup>
            <FormGroup>
                <Label for="type">Rôle</Label>
                <Input type="select" name="type" id="type" value={role} onChange={ (e) => setRole(e.target.value) } >
                    <option value={LAWYER} >avocat</option>
                    <option value={ELAWYER} >avocat externe</option>
                    <option value={SALES} >ventes</option>
                    
                    <option value={NOTARY} >Notaire</option>
                    {/* 'admin','supervisor','lawyer','notary','agent','lsupervisor','customer','legalintern','sales','elawyer','elawyerf' */}
                </Input>
            </FormGroup>
            <FormGroup>
                <Label for="file">Signature</Label>
                <Dropzone
                    maxSize={500000}
                    accept={'image/*'} 
                    handleFile={setSign}
                />
                {/* <SignaturePad
                    ref={ref => signaturePad = ref}
                    options={{
                        width: 300,
                        clear:true
                    }}
                /> */}
            </FormGroup>
            <FormGroup>
                <Button 
                    className={'full-width m-t-10'}
                    size="lg"
                    color={"primary"} 
                    type={"button"} 
                    onClick={ 
                        handleSubmit
                    }>Créer</Button>
            </FormGroup>
        </Form>
      </Container>
  )
}

export default UserPanel;