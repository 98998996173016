import axios from "axios";
import React from 'react';
import {
    Redirect
} from "react-router-dom";

//non authenticaded fonction like login and logout
const PUBLIC_TIMEOUT = 10000;
const PRIVATE_TIMEOUT = 600000;


const isHandlerEnabled = () => {
    return (process.env.REACT_APP_ALLOW_LOG && process.env.REACT_APP_ALLOW_LOG!=='false')
}

const successHandler = (response) => {
    return response
}

const errorHandler = (error) => {
    if (isHandlerEnabled()) {
        console.log('Erreur provenant du serveur ->',{ ...error })
    }

    if(error.response)
        return { status:error.response.status };

    return {...error}
}

const privateErrorHandler = (error) => {
    
    if (isHandlerEnabled()) {
        console.log('Erreur provenant du serveur ->',{ ...error })
    }

    if(error.response && error.response.status===401){
        // window.href = ""
        localStorage.clear();
        // let history = useHistory();
        // debugger;
        // return <Redirect to={"/login"+window.location.search} />;
        
        // SilkRoad.push("/logout"+window.location.search);
        // setTimeout(function(){
        //     if(localStorage.getItem('token')){
        //         localStorage.clear();
        //         window.location.reload();
        //     }
                
        // },2000)

        
        // window.location.href=`${process.env.PUBLIC_URL}/login`+window.location.search;
        
        // window.location.href=`${process.env.PUBLIC_URL}/login?return=`+window.location.pathname;

        if(window.location.href.match(/&return=/))
            return {redirect:true,to:"/login"+window.location.search}
        else
            return {redirect:true,to:"/login?return="+window.location.pathname + window.location.search}
        
    }

    if(error.response)
        return { status:error.response.status };

    return {...error}
}

const publicAxios = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout:PUBLIC_TIMEOUT,
    headers:{
        'Content-Type': 'application/json',
        // 'Access-Control-Allow-Origin': '*',
        // 'Access-Control-Max-Age':600,
        // 'x-jwt-token':localStorage.getItem('token'),
    },

    auth:{
        username:'admin',
        password:'6d21b4833368d61c31275092886a4d9add8f7801abd7892d017b3f19a37b30c8'
    },
    // withCredentials:true
})

const config = {
    baseURL: process.env.REACT_APP_API_URL,
    timeout:PRIVATE_TIMEOUT,
    // withCredentials: true,
    headers: { 
        'Content-Type': 'application/json',
        // 'Authorization':'Bearer '+localStorage.getItem('token'),
        // 'Access-Control-Allow-Origin': '',
        // 'Access-Control-Max-Age':600
    },
}

const adminConfig = {
    baseURL:process.env.REACT_APP_BE_API_URL,
    timeout:PRIVATE_TIMEOUT,
    headers: { 
        'Content-Type': 'application/json'
    },
}


const privateAxios = axios.create(config);
const adminAxios = axios.create(adminConfig);

publicAxios.interceptors.response.use(
    response => successHandler(response),
    error => errorHandler(error)
)

privateAxios.interceptors.request.use(function (config) {
    // const token = store.getState().session.token;
    config.headers.Authorization = 'Bearer '+localStorage.getItem('token');
    config.headers['Content-Type'] = 'application/json'
    return config;
});

privateAxios.interceptors.response.use(
    response => successHandler(response),
    error => privateErrorHandler(error)
)

adminAxios.interceptors.request.use(function (config) {
    // const token = store.getState().session.token;
    config.headers.Authorization = 'Bearer '+localStorage.getItem('token');
    config.headers['Content-Type'] = 'application/json'
    return config;
});

adminAxios.interceptors.response.use(
    response => successHandler(response),
    error => privateErrorHandler(error)
);

export {
    publicAxios,
    privateAxios,
    adminAxios,
    config
}