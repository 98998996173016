import React, { Component } from 'react';

import Email from '../_snapi/user';

import {
    Form,
    FormGroup,
    Label,
    Button,
} from 'reactstrap';

import Select from 'react-select';

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

import { connect } from 'react-redux';

import rootsActions from '../_actions/root-actions';

import _ from 'lodash';
// import axios from "axios";

import moment  from 'moment-timezone';

// import { BeatLoader } from 'react-spinners';

import { 
    FIELD_LIST as F_,
    TEXT,
    MULTILIGNE,
    LISTE_DEROULANTE,
    CASE_A_COCHER,
    DATE,
    DECIMAL,
    NUMBER,
    EMAIL,
    TIME,
    PHONE,
} from '../data/zendeskFields'

import {
    NEO_CALENDAR,   
    CALENDAR_EVENT_TIME,    
    CALENDAR_EVENT_TIME_ZONE,   
    CALENDAR_EVENT_DATE,    
    CALENDAR_EVENT_DURATION,
    CALENDAR_EVENT_LAWYER,
    LAWYER_IN_CHARGE
}
from '../data/zendeskFields';
// import { ReactComponent } from '*.svg';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faAtlas
 } from '@fortawesome/free-solid-svg-icons';

import PhoneInput from './Inputs/PhoneInput';
import SelectInput from './Inputs/SelectInput';
import TextInput from './Inputs/TextInput';
import DateInput from './Inputs/DateInput';
import CheckInput from './Inputs/CheckInput';
import NumberInput from './Inputs/NumberInput';

import {
    USER_FORM
} from '../_const';

const UserForm = [
    {...F_['Email'],mandatory:false},
];

const PC = [
    {...F_['Sujet'],mandatory:true},
    {...F_['Description'],mandatory:false},
    {...F_['Priorité'],mandatory:false},
    {...F_['Partie Adverse'],mandatory:true},
    {...F_['Satisfaction Survey Sent FR'],mandatory:false},
]


const penal = [
    {...F_['Contravention Numéro'],mandatory:false},
    {...F_['Lien SharePoint'],mandatory:false},
    {...F_['SN Ticket Data'],mandatory:false},
    {...F_['Note Penal'],mandatory:false},
    {...F_['Statut Dossier'],mandatory:false},
    {...F_['neo_calendar'],mandatory:false},
    {...F_['courriel'],mandatory:false},
];


const everyoneForm = [
    {...F_['Sujet'],mandatory:true},
    {...F_['Description'],mandatory:false},
    {...F_['Priorité'],mandatory:false},
    {...F_['Partie Adverse'],mandatory:true},
    {...F_['Satisfaction Survey Sent FR'],mandatory:false},
    {...F_['Calendar name'],mandatory:false},
    {...F_['Civil : Date de cour dossier Civil'],mandatory:false},
    {...F_['Palais de Justice (Ne pas oublier les accents)'],mandatory:false},
    {...F_['Contravention Numéro'],mandatory:false},
    {...F_['Lien SharePoint'],mandatory:false},
    {...F_['SN Ticket Data'],mandatory:false},
    {...F_['Note Penal'],mandatory:false},
    {...F_['Statut Dossier'],mandatory:false},
    {...F_['neo_calendar'],mandatory:false},
    {...F_['courriel'],mandatory:false},
    {...F_['téléphone'],mandatory:false},
    {...F_['temps'],mandatory:false},
    // F_['téléphone'],mandatory:false},
    // F_['Description'],
    // F_['temps'],
    // F_['Sujet'],
    // F_['Priorité'],
    // F_['Vente par'],
    // F_['courriel'],
    // F_['FollowUp'],
    // F_['Calendar event description'],
    // F_['Date Prescription'],

    
    // F_['Statut'],
    // F_['Type'],
    // F_['Groupe'],
    // F_['Assigné'],
    // F_['Cours municipale'],
    // F_['Avocat en Charge'],
];

const extraFields = [
    {...F_['Calendar event date'] },
    {...F_['Calendar event time'] },
    {...F_['Calendar event timezone'] },
    {...F_['Calendar event duration (in minutes)'] },
    {...F_['Calendar event link'] },
    {...F_['Calendar event subject'] },
    {...F_['Calendar event description'] },
    {...F_['Calendar event location'] },
    {...F_['Calendar event invite requester'] },
    {...F_['Calendar event invite CCs'] },
    {...F_['Next Step'] },
    {...F_['FollowUp'] },
    {...F_['Produit'] },
    {...F_['Date Prescription'] },
    {...F_['Vente par'] },
    {...F_['Prix Vente'] },
    {...F_['Client additionnel'] },
    {...F_['Pénal : Date de cour dossier Pénal'] },
    {...F_['Sondage Med Gratuite'] },
    {...F_['Number of tasks'] },
    {...F_['Number of completed tasks'] },
    {...F_['Remaining tasks'] },
    {...F_['Parent Ticket ID'] },
    {...F_['Linked task ID'] },
    {...F_['Task list ID'] },
    {...F_['Abonnement'] },
    {...F_['Abonnement Annulé'] },
    {...F_['Historique Dossier'] },
    {...F_['Coordonnées Négociation'] },
];

class NeoForm extends Component{
    
    constructor(props){

        super(props);

        this.state = {
            x:'y',
            extraFields:[],
            allVisible:true,
            currentProduct:(this.props.location.pathname === '/formulaires/user')?USER_FORM:'everyoneForm'
        }

        this.formData = {};

        
        this.everyoneForm = [...everyoneForm];
        this.PC =  [...PC];
        this.penal = [...penal];
        this.UserForm = [...UserForm]
        this.updateForm = this.updateForm.bind(this);

        this.handleDateChange = this.handleDateChange.bind(this);
        this.handleSelect = this.handleSelect.bind(this)
        this.onWeekChange = this.onWeekChange.bind(this);
        this.genForm = this.genForm.bind(this);
        this.renderByType = this.renderByType.bind(this);
        this.initForm = this.initForm.bind(this);
        this.zendeskFieldsCount = 0;

    }

    //dear future friend, react 17 will make some problem with this method
    //you have been warned :)
    // componentWillUpdate(nextProps, nextState){
    //     // if(localStorage.getItem(''))
    //     console.log('componentWillUpdate')
    // }
    componentDidUpdate(prevProps){
        if(this.props.fieldChange && prevProps.fieldChange) {
            /* eslint-disable */
            if(this.props.fieldChange.name !== prevProps.fieldChange.name || this.props.fieldChange.value != prevProps.fieldChange.value ){
                this.formData[this.props.fieldChange.name] = this.props.fieldChange.value;
                this.forceUpdate();
            }
            /* eslint-enable */
                
        }
    }


    componentDidMount(){
        this.props.clientInit('user');
        for(let c in this[this.state.currentProduct]){
            if(this[this.state.currentProduct][c].zendesk_id){
                this.zendeskFieldsCount++;
                this.getTicketFieldCallback(this[this.state.currentProduct][c].zendesk_id,this[this.state.currentProduct][c].title,this.initForm,this);   
            }
                
        
        }
            
    }

    initForm(zendesk,value,key){
        this.formData[key] = {value:value,zendesk:zendesk}
        
        if(!--this.zendeskFieldsCount){
            this.forceUpdate();
        }
            
    }
    
    getTicketFieldCallback(key,field,callback,self){
        let event = new CustomEvent('getTicketFieldCallback', 
            {detail:{
                field:field,
                key:key,
                callback:callback,
                self:self}}
        );
        window.dispatchEvent(event);

    }

    getCustomField(){

    }

    handleSelect(e){
        this.setState({
            extraFields:e
            }
            // () => {}    
        );
    }

    setForm(key,value,zendesk){
        this.formData[key] = {value:value,zendesk:zendesk}
        clearTimeout(this.timeout)
        this.timeout = setTimeout(() => this.updateForm(this.formData),3000)
    }

    updateForm(form){
        //axios function
        for(let f in form){
            if(form[f].zendesk)
                this.setTicket(form[f].zendesk,form[f].value)
        }
    }

    onWeekChange(e){
        
        this.setState({
            startDate:moment(e.start),
            endDate:moment(e.end),
            selectedWeek:e.value,
            selectedYear:e.year,
        },() => {
            this.getBooks();
        });
    }

    getWithCallback(callback,self){
        let event = new CustomEvent('getWithCallback', 
            {detail:{callback:callback,self:self}}
        );
        
        window.dispatchEvent(event);

    }

    setTicket(field,value){
        Email('put',{id:1},{'email':value}, () => {
            console.log('yolo')
        });

        let event = new CustomEvent('setTicket', 
            { detail:{
                field:field,
                value:value
            }}
        );
        
        window.dispatchEvent(event);
    }

    updateNeoCalendar(date,lawyer,type,name,duration){
        // if(window.Neolegal.nextAppointment){
            let m;
            if(window.Neolegal.nextAppointment)
                m = (moment().isAfter(moment(window.Neolegal.nextAppointment))||moment(window.Neolegal.nextAppointment).isAfter(moment(date)))
            else
                m = true;

            if(m){
                
                window.Neolegal.nextAppointment = date;
                this.setTicket(NEO_CALENDAR,date);
                this.setTicket(CALENDAR_EVENT_TIME,moment(date).format('LT'));
                this.setTicket(CALENDAR_EVENT_TIME_ZONE,moment.tz.guess());
                this.setTicket(CALENDAR_EVENT_DATE,moment(date).toDate());
                this.setTicket(CALENDAR_EVENT_DURATION,duration);
                this.setTicket(CALENDAR_EVENT_LAWYER,name);
                
            }

            if(!window.Neolegal.lawyerInCharge&&lawyer&&type.type!==1){
                
                this.setTicket(LAWYER_IN_CHARGE,lawyer);
            }
        // }

    }

    handleDateChange(e){
        return false;
    }

    func(fun){
        return fun();
    }

    genForm(form = [],key=999){
        let arr = [], formElt;

        for(let f in this.formData){
            if(_.find(form,{title:f})){
                if(this.formData[f].value){
                    form[_.findIndex(form,{title:f})].filled = true;
                    form[_.findIndex(form,{title:f})].mandatory = false;

                }
            }
        }

        // form = _.orderBy(form,['filled'],['asc']);
        let mandatories = _.filter(form,{mandatory:true,filled:false});
        let filled = _.filter(form,{filled:true})
        let others = _.filter(form,{mandatory:false,filled:false});

        form = [...mandatories,...others,...filled]

        for (let e in form){
            formElt = this.renderByType(form[e]);
            arr.push(formElt)
        }

        return (<div key={key}>{arr}</div>);
    }

    renderByType(value){
        // console.log('renderByType',value)
        var defaultValue = '';
        if(this.formData[value.title] && this.formData[value.title].value ){
            defaultValue = this.formData[value.title].value;
            // console.log('defaultValue', defaultValue)
        }

        switch(value.type){
            case TEXT:
                return (<TextInput 
                    defaultValue={defaultValue}
                    mandatory={(value.mandatory)}
                    allvisible={this.state.allVisible} 
                    type={"text"} 
                    key={value.id + value.zendesk_id}
                    title={value.title}
                    zendesk={value.zendesk_id}
                    id={value.id} setForm={this.setForm} self={this} />);
            case MULTILIGNE:
                return (<TextInput 
                        defaultValue={defaultValue}
                    mandatory={(value.mandatory)}
                    allvisible={this.state.allVisible} 
                    type={"textarea"} 
                    key={value.id}
                    title={value.title}
                    zendesk={value.zendesk_id}
                    id={value.id} setForm={this.setForm} self={this} />)
            case LISTE_DEROULANTE:
                return (<SelectInput
                        defaultValue={defaultValue} 
                    mandatory={(value.mandatory)}
                    allvisible={this.state.allVisible} 
                    key={value.id}
                    title={value.title}
                    setForm={this.setForm}
                    self={this} 
                    zendesk={value.zendesk_id||''} 
                    type="select" id={value.id} list={this.genOptions(value.list)} />)
            case CASE_A_COCHER:
                return(<CheckInput
                    defaultValue={defaultValue}
                    key={value.id}
                    title={value.title}
                    zendesk={value.zendesk_id}
                    setForm={this.setForm}
                    self={this} type="checkbox"  id={value.id} />);
            case DATE:
                return(<DateInput 
                        defaultValue={defaultValue}
                    mandatory={(value.mandatory)}
                    allvisible={this.state.allVisible} 
                    key={value.id}
                    title={value.title}
                    zendesk={value.zendesk_id}
                    setForm={this.setForm}
                    self={this} type="date"  id={value.id} />);
            case TIME:
                return(<DateInput 
                        defaultValue={defaultValue}
                        key={value.id} 
                        title={value.title} 
                        zendesk={value.zendesk_id}
                        setForm={this.setForm} 
                        self={this} 
                        allvisible={this.state.allVisible} 
                        type="time"  
                        id={value.id} />);
            case DECIMAL:
            case NUMBER:
                return (<NumberInput 
                        defaultValue={defaultValue}
                    mandatory={value.mandatory}
                    allvisible={this.state.allVisible} 
                    key={value.id}
                    title={value.title}
                    zendesk={value.zendesk_id}
                    setForm={this.setForm}
                    self={this} type="number"  id={value.id} />)
            case EMAIL:
                return (<TextInput 
                        defaultValue={defaultValue}
                    mandatory={(value.mandatory)}
                    allvisible={this.state.allVisible} 
                    type={"email"} 
                    key={value.id}
                    title={value.title}
                    zendesk={value.zendesk_id}
                    id={value.id} setForm={this.setForm} self={this} />);
            case PHONE:
                return(
                    <div key={value.id}>
                        <PhoneInput
                            defaultValue={defaultValue}
                            mandatory={(value.mandatory)}
                            allvisible={this.state.allVisible}
                            phoneKey={value.id}
                            title={value.title}
                            setForm={ this.setForm} 
                            self={this}
                            id={value.id}
                            name={value.id}
                        />
                    </div>)
            default:
        return (<span style={{color:'red'}}>{JSON.stringify(value)}</span>);
        }
    }

    genOptions(obj){
        let list = [];
        for(let o in obj){
            list.push(<option key={obj[o].value+'_'+o} value={obj[o].tag} defaultValue={obj[o].default}>{obj[o].value}</option>)
        }
        return list;
    }

    genProducts(){
        let list = [
            {value:'PC',label:'PC'},{value:'penal',label:'penal'},{value:'everyoneForm',label:'civil'}
        ];
        return list;
    }

    render(){
        var form = this.genForm(this[this.state.currentProduct],888888);
        var extraForms = this.genForm(this.state.extraFields,999999);
        var products = this.genProducts();

        return(      
            <div>
                <Button 
                    type="button"
                    onClick={() => {
                        this.setState({
                            allVisible:!this.state.allVisible
                        })
                    }}
                    
                    className="badge"
                    style={{
                        position:'fixed',
                        top:'0px',
                        right:'0px',
                        // opacity:'1',
                        zIndex:'999999',
                        paddingTop:'0px !important',
                        paddingRight:'0px !important',
                        paddingLeft:'0px !important',
                        paddingBottom:'0px !important',
                        // width:'100%',
                        // marginTop:"5px"
                    }}
                    color={"primary"}
                ><FontAwesomeIcon icon={faAtlas} /></Button>
                <Form id="products" >
                    <FormGroup>
                        <Label><strong>Produit en cours</strong></Label>
                        {/* <Input 
                            type="select" 
                            onChange={ (e) => { this.setState({currentProduct:e.target.value}) } }
                        >
                            {products}
                        </Input> */}
                        <Select
                            id="product"
                            onChange={ (e) => { this.setState({currentProduct:e.value}) } }
                            options={ products }
                            name="product"
                            isClearable
                            isSearchable
                        />
                    </FormGroup>
                </Form>
                {/* {this.state.x} */}
                <Form 
                    id="neoForm" 
                    // onChange={this.onChange}
                >
                    {/* <legend>Formulaires</legend> */}
                        {form}
                    <hr></hr>
                    <legend>Extra champs</legend>
                        {extraForms}
                    <FormGroup>
                        <Select
                            id="extraField"
                            onChange={(e) => this.handleSelect(e)}
                            options={ extraFields.map((e) => { return {
                                    value:e.id,
                                    id:e.id,
                                    title:e.title,
                                    label:e.title,
                                    type:e.type,
                                    zendesk:e.zendesk_id,
                                    mandatory:false
                                    }
                                })
                            }
                            name="extraField"
                            isClearable
                            isSearchable
                            isMulti
                        />
                    </FormGroup>
                </Form>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    Zendesk:state.Zendesk,
    weekList:state.time.weekList,
    fieldChange:state.users.fieldChange
  });
  
const mapActionsToProps = {
    clientInit:rootsActions.clientActions.load
}

export default connect(mapStateToProps,mapActionsToProps)(NeoForm);
// export default Booking;