
import _ from 'lodash'
import { 
    TARIF_FETCH,
    TARIF_ADD_NEGO,
    TARIF_ADD_GLOBAL,
} from '../_actions/tarif-actions.js';
  
export default function tarifReducer(
    state = '',
    {type,params}
){
    switch(type){
        case TARIF_FETCH:
            return {
                ...state,
                ...params
            }
        
        case TARIF_ADD_NEGO:
            let nego = _.findIndex(state.negotiated,{type_id:params.type_id});

            if(nego<0){
                return {
                    ...state,
                    negotiated:[...state.negotiated,params]
                }
            }
            
            state.negotiated[nego] = params;
            
            return {
                ...state,
                negotiated:[...state.negotiated,params]
            }
            
        case TARIF_ADD_GLOBAL:
            
            let global = _.findIndex(state.global,{type_id:params.type_id});

            if(global<0){
                return {
                    ...state,
                    global:[...state.global,params]
                }
            }
            
            state.global[global] = params;

            return {
                ...state,
                global:[...state.global]
            }
        default:
            return state;
    }
}