import React from 'react'
import { 
    INVOICE_FETCH,
    INVOICE_ADD_MANUAL,
    INVOICE_LIST_FETCH,
    INVOICE_LINE_EDIT_MANUAL,
    INVOICE_LINE_EDIT_AUTOMATIC,
    INVOICE_TOGGLE_PENDING,
    INVOICE_LINE_EDIT_INDEX_MANUAL
} from '../_actions/invoice-actions.js';

import { faCheckCircle, faTimesCircle,faClock } from '@fortawesome/free-solid-svg-icons';

import _ from "lodash";
import TableIcon from '../component/utils/TableIcon';

const YELLOW_PENDING = '#E6D200';
const GREEN_APPROVED = 'green';
const RED_DENIED = 'red';

const APPROVED = 1;
const REFUSED = -1;
const PENDING = 0;

export default function invoiceReducer(
    state = '',
    {type,params}
){
    
    switch(type){
        case INVOICE_FETCH:
            return {
                ...state,
                ...params
            }
        case INVOICE_ADD_MANUAL:
            return {
                ...state,
                manual:[...state.manual,params]
            }
        case INVOICE_LINE_EDIT_MANUAL:
            
            let indexM = _.findIndex(state.manual,{id:params.id});

            let manual = state.manual
            
            manual[indexM] = {...params};

            return {
                ...state,
                manual:[...manual]
            }
            
        case INVOICE_LINE_EDIT_INDEX_MANUAL:
            
            if(!state || !state.manual||!params||params.old_id)
                return {...state}

            // console.log('---->',params)
            // debugger;
            let manual2 = state.manual;
            let indexM2 = _.findIndex(manual2,{id:params.old_id});


            if(indexM2<0)
                return {...state};

            manual2[indexM2].id = params.id

            return {
                ...state,
                manual:[...manual2]
            }
        case INVOICE_LINE_EDIT_AUTOMATIC:
            // debugger;
            
            let automatic = state.automatic;

            
            let indexA = _.findIndex(state.automatic,{id:params.id})
            state.automatic[indexA] = {...params};

            return {
                ...state,
                automatic:[...automatic]
            }
        case INVOICE_LIST_FETCH:
            return {
                ...state,
                list:[...params]
            };

        case INVOICE_TOGGLE_PENDING:
            
            let {collection,id} = params;

            if(!collection)
                return state;

            let arr = state[collection];
            
            let index = _.findIndex(arr,{id:id})
            
            if(index<0)
                return state;

            let status = parseInt(arr[index].approved);
            let icon = faClock;
            let color = YELLOW_PENDING;

            if(status === PENDING){
                status = APPROVED;
                
                color = GREEN_APPROVED;
                icon = faCheckCircle;
            }
            else if(status === APPROVED){
                status = REFUSED;
                
                color = RED_DENIED;
                icon = faTimesCircle;
            }else if(status === REFUSED){
                status = PENDING;
            }else{
                status = PENDING;
            }

            let {approvedIcon} = arr[index];
            
            approvedIcon = <TableIcon
                {...approvedIcon.props}
                color={color}
                icon={icon}
            />

            
            arr[index] = {
                ...arr[index],
                approved:status.toString(),
                approvedIcon:approvedIcon 
                // icon={icon}
                // color={color}
                // callback={() => {
                //   callback({
                //     collection:'list',
                //     id:e.id
                //   })
                  // let id = "/facture/1"
                  
                  // this.setState({redirection:id})
            //     }}
            //   />
            };
            let obj = {...state};

            obj[collection] = [...arr];

            return {...obj};

        // case INVOICE_ADD_NEGO:
        //     return {
        //         ...state,
        //         negotiated:[...state.negotiated,params]
        //     }
            
        // case INVOICE_ADD_GLOBAL:
        //     return {
        //         ...state,
        //         global:[...state.global,params]
        //     }
        default:
            return state;
    }
}