
import React from 'react';
import ValidationIcon from './ValidationIcon';

import {
    FormGroup,
    Label,
    Input
} from 'reactstrap';

import {
    none,
    block
} from '../../_const';

export default class NumberInput extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            display:(!this.props.mandatory)?none:block,
            mandatory:this.props.mandatory,
            number:parseInt(this.props.default)||0
        }
    }

    componentDidUpdate(prevProps){
        if(this.props.allvisible !== prevProps.allvisible && !this.state.mandatory) {
            this.setState({display:(this.props.allvisible)?none:block})
        }
    }

    render(){
        return (<FormGroup neokey={this.props.neokey} >
            <Label
                onClick={(e) => this.setState({display:(!this.state.mandatory&&this.state.display===block)?none:block})}
            >{this.props.title}<ValidationIcon valid={!!this.state.number} /></Label>
            <span style={{display:(this.state.display===none)?block:none}}>{this.state.number}</span>
            <Input
                style={{display:this.state.display}}    
                onChange={(e) => {
                    this.setState({
                        mandatory:false,
                        number:e.target.value
                    },() =>{
                            this.props.self.setForm(this.props.title,this.state.text,this.props.zendesk);
                        }
                    )}
                }
                invalid={this.state.mandatory}
                zendesk={this.props.zendesk||''}
                type={this.props.type}
                value={this.state.number}
                id={this.props.id} />
        </FormGroup>)
    }
}