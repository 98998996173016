
export const INVOICE_FETCH = 'INVOICE_FETCH';
export const INVOICE_LIST_FETCH = 'INVOICE_LIST_FETCH';
export const INVOICE_ADD_MANUAL = 'INVOICE_ADD_MANUAL';
export const INVOICE_LINE_EDIT_MANUAL = 'INVOICE_LINE_EDIT_MANUAL';
export const INVOICE_LINE_EDIT_AUTOMATIC = 'INVOICE_LINE_EDIT_AUTOMATIC';
export const INVOICE_LINE_EDIT_INDEX_MANUAL = "INVOICE_LINE_EDIT_INDEX_MANUAL";
export const INVOICE_TOGGLE_PENDING = 'INVOICE_TOGGLE_PENDING';

export function invoiceFetch(params){
    return {
        params:params,
        type:INVOICE_FETCH
    };
}

export function invoiceListFetch({invoiceList}){
    return {
        params:invoiceList,
        type:INVOICE_LIST_FETCH
    };
}

export function invoiceAddManual(params){
    return {
        params:params,
        type:INVOICE_ADD_MANUAL
    };
}

export function invoiceListEditManual(obj){
    return {
        params:obj,
        type:INVOICE_LINE_EDIT_MANUAL
    };
}

export function invoiceListEditIndexManual(obj){
    return {
        params:obj,
        type:INVOICE_LINE_EDIT_INDEX_MANUAL
    };
}


export function invoiceListEditAutomatic(obj){
    return {
        params:obj,
        type:INVOICE_LINE_EDIT_AUTOMATIC
    };
}

export function invoiceTogglePending(obj){
    return {
        params:obj,
        type:INVOICE_TOGGLE_PENDING
    };
}

// export function invoiceAddNego(params){
//     return {
//         params:params,
//         type:INVOICE_ADD_NEGO
//     };
// }

// export function invoiceAddGlobal(params){
//     return {
//         params:params,
//         type:INVOICE_ADD_GLOBAL
//     };
// }