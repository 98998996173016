const SET_WEEK = "SET_WEEK";

export function setWeek(params){
    return {
        type:SET_WEEK,
        params:params
    }
}

export {
    SET_WEEK
}