import Email from '../../_snapi/user';
import {changeValue} from '../../_actions/users-actions';

const TICKET = 'ticket';
const ZAFClient = window.ZAFClient

function stripHtml(html) {
  var tmp = document.createElement("DIV");
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerText || " ";
}
//global object 
window.Neolegal = {
  subject: '',
  description: '',
  organizer_id: '',
  service: '',
  ticket_id: '',
}

const client = {
  init: (target = TICKET) => {

    const i = ZAFClient.init();
    if (!i) {
      console.log('NO I :(')
      return false;
    }

    i.invoke('resize', { width: '100%', height: '350px' });
    i.get(target).then(function (data) {
      if(target === TICKET){

        window.Neolegal.ticket = data.ticket;
  
        window.Neolegal.commentType = data.ticket.comment.type;
        window.Neolegal.ticket_id = data["ticket"].id;
        window.Neolegal.ticketStatus = data["ticket"].status;
  
        window.Neolegal.subject = data["ticket"].subject;
        window.Neolegal.description = data["ticket"].description;
        window.Neolegal.client = data["ticket"].requester;

      }
      else{
        
        window.Neolegal.email = data.user.email;
      }
    });

    i.on('app.registered', function (e) {
      if(target === TICKET){
        i.get('ticket.customField:custom_field_360028127774').then(function (data) {
          window.Neolegal.nextAppointment = data["ticket.customField:custom_field_360028127774"];
        });

        i.get('ticket.customField:custom_field_80870188').then(function (data) {
          window.Neolegal.service = data["ticket.customField:custom_field_80870188"];
        });

        i.get('ticket.customField:custom_field_360023465514').then(function (data) {
          window.Neolegal.lawyerInCharge = (data["ticket.customField:custom_field_360023465514"] === "aucun") ? null : data["ticket.customField:custom_field_360023465514"];
        });

        i.get(target).then(function (data) {
          window.Neolegal.ticket = data.ticket;

          window.Neolegal.ticket_id = data["ticket"].id;
          window.Neolegal.commentType = data.ticket.comment.type;
          window.Neolegal.subject = data["ticket"].subject;
          window.Neolegal.description = data["ticket"].description;
          window.Neolegal.client = data["ticket"].requester;
        });

        i.on('comment.type.changed', function (e) {
          window.Neolegal.commentType = e;
          // if(e !== 'internalNote')
          // if(e){
          //   window.Neolegal.commentType
          // }
        })

        // i.on('ticket.requester.email',function(e){
        //   console.log(e)
        //   window.Neolegal.newEmail = e;
        // });

        i.on('comment.text.changed', function (e) {
          window.Neolegal.currentComment = stripHtml(e)
        });

        window.addEventListener('setTicket', function (e) {
          i.set(e.detail.field, e.detail.value).then(function (data) {
          });
        });

        window.addEventListener('getField', function (e) {
          // 'ticket.customField:custom_field_80870188','02_parlez_à_un_avocat"

          i.get(e.detail.field).then(function (data) {
            window.Neolegal[e.detail.neoName] = data[e.detail.field];
          });
        });
      }
      else{
        
        i.on('user.email.changed',function(data){
          Email('put',{id:1},{'email':data}, () => {

            let obj = changeValue({name:'email',value:data});
            window.store.dispatch(obj);
          });

        })
      }
    });

    window.addEventListener('getCurrentComment', function (e) {
      // 'ticket.customField:custom_field_80870188','02_parlez_à_un_avocat"
      i.get(e.detail.field).then(function (data) {
        window.Neolegal[e.detail.neoName] = stripHtml(data[e.detail.field].text);
      });
    });

    window.addEventListener('getTicketFieldCallback', function (e) {
      
      i.get(e.detail.key).then(function (data) {
        window.Neolegal[e.detail.key] = data[e.detail.key];
        e.detail.callback.call(e.detail.self, e.detail.key, data[e.detail.key], e.detail.field)
      });
    });


    window.addEventListener('getWithCallback', function (e) {

      i.get(target).then(function (data) {

        window.Neolegal.ticket = data.ticket;

        window.Neolegal.ticket_id = data["ticket"].id;
        // window.Neolegal.organizer_id = data["ticket"].assignee.user.id;
        window.Neolegal.subject = data["ticket"].subject;
        window.Neolegal.description = data["ticket"].description;
        window.Neolegal.client = data["ticket"].requester;
        // Neolegal.service = data.ticket.requester.service;

        e.detail.callback.call(e.detail.self, e.detail.params)
      });
    });
    // }
    return i;
  }
}

export default client;