import React, { Component } from 'react';

import 'tui-calendar/dist/tui-calendar.css';

// If you use the default popups, use this.
// import 'tui-date-picker/dist/tui-date-picker.css';
// import 'tui-time-picker/dist/tui-time-picker.css';
import DateTimePicker from 'react-datetime-picker';

import _ from 'lodash';
import {
    
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
    Row,
    Col,
    FormGroup,
    Label,
    Input,
} from 'reactstrap';


import { connect } from 'react-redux';

import rootsActions from '../_actions/root-actions';

import {privateAxios as axios} from '../utils/axios';

import moment  from 'moment-timezone';

const leaveReason = [

    // congé, maladie,absence autorisée, formation , autre
    // {value:0,label:'Raisons personnelles'},
    // {value:1,label:'Grippe, gastro etc'},
    // {value:2,label:'Maladie grave'},
    // {value:3,label:'Raisons familliales'},
    // {value:4,label:'Grossesse'},
    // {value:5,label:'Congé maternité/paternité'},
    // {value:6,label:'Barreau'},
    {value:0,label:'Congé'},
    {value:1,label:'Maladie'},
    {value:2,label:'Absence autorisée'},
    // {value:3,label:'Problème technique (panne, traffic...)'},
    {value:3,label:'Autre, spécifiez.'},

    // {value:10,label:'Formation'},
    // {value:8,label:'Voyage'},
    // {value:11,label:'Vacances'},
    // {value:12,label:'Inconnu'},
]

class LeaveModal extends Component{
    
    constructor(props){
        super(props);

        // if(config && config.headers.Authorization === 'Bearer null'){
        //     window.location.reload();
        // }
        this.state ={

            weekId:moment().isoWeek(),
            // wData:[],
            
            // focusedInput: props.autoFocusEndDate ? END_DATE : START_DATE,
            calendarUsers:[],
            today:moment().startOf('day').utc(),
            weekMonday:moment().startOf('isoWeek').startOf('day').add(2,'hours').utc(),
            // userList:[
            //     {auth_id: 0,firstname:"Tous",lastname:" ",color:"#ccc"}
            // ],
            modal:false,
            startDate:moment().startOf('day').toDate(),
            endDate:moment().endOf('day').toDate(),
            // DST:moment(moment.tz().format()).isDST()
            startTime:'',
            endTime:'',
            editedLawyer:0,
            leaveExplaination:'',
            leaveReason:0
        }

        this.setVacations = this.setVacations.bind(this);
        this.onDatesChange = this.onDatesChange.bind(this);
    }

    setVacations(){
        
        let uID = this.props["userId"];

        if(!uID){
          let token = window.parseJwt(localStorage.getItem('token'));
          
          uID=token.sub
          
        }

        // console.log(moment.tz(this.state.startDate.toISOString(),"America/Toronto").format('YYYY-MM-DDTHH:mm:ss+00:00'))
        let leave = _.find(leaveReason,{value:parseInt(this.state.leaveReason)})
        if(this.state.editedLawyer<1)
        return false;
        axios.post(
            'availabilities/leave',{
                // title:this.state.leaveReason,
                // description:this.state.leaveExplaination,
                user:this.state.editedLawyer,
                // startDate:moment(this.state.startDate.toISOString()).utc().format("YYYY-MM-DDTHH:mm:ss+00:00"),
                // endDate:moment(this.state.endDate.toISOString()).utc().format("YYYY-MM-DDTHH:mm:ss+00:00"),
                auth_id:uID,
                taken: {
                    "title": leave.label,
                    "description": this.state.leaveExplaination,
                    "service_id": 1000+parseInt(this.state.leaveReason),
                    "service": leave.label,
                    "start": moment(this.state.startDate.toISOString()).utc().format("YYYY-MM-DDTHH:mm:ss+00:00"),
                    "end": moment(this.state.endDate.toISOString()).utc().format("YYYY-MM-DDTHH:mm:ss+00:00"),
                    "TimeZone": moment.tz.guess(),
                    "where": "montreal",
                    "organizer": {
                        "id_zendesk": localStorage.getItem('id_zendesk'),
                        "id": uID,
                        "role": "admin"
                    },
                    "app_subject": leave.label,
                    "app_description": this.state.leaveExplaination,
                    "leave":true
                }
            }
        )
        .then((e) => {
            this.props.dispatch({type:rootsActions.usersActions.TOGGLE});
        });
    }

    toggle() {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    }

    onDatesChange({ startDate, endDate }) {
        this.setState({ startDate, endDate });
    }

    // onFocusChange(focusedInput) {
    //     this.setState({focusedInput: !focusedInput ? START_DATE : focusedInput});
    // }

    render(){    
        return(
        <>
        
        <Modal 
            isOpen={this.props.toggle}
            toggle={() => this.props.dispatch({type:rootsActions.usersActions.TOGGLE})}
            // size="lg" 
            // style={{maxWidth: '800px', width: '80%'}}
        >
                <ModalHeader 
                    toggle={() => this.props.dispatch({type:rootsActions.usersActions.TOGGLE})}>
                         Choix de congés 
                </ModalHeader>
                <ModalBody>    
                    <Row>
                        <Col md={12}>
                            <Label> Début </Label><br/>      
                            <DateTimePicker 
                                onChange = {
                                    date => this.setState({ startDate: date })
                                }
                                disableClock={true}
                                value={this.state.startDate}
                            />
                        </Col>
                        <Col md={12}>
                            <Label> Fin </Label><br/>
                            <DateTimePicker 
                                onChange = {
                                    date => this.setState({ endDate: date })
                                }
                                disableClock={true}
                                value={this.state.endDate}
                            />   
                        </Col>
                    </Row>
                    <FormGroup>
                        <Label for="addLawyer">Avocat</Label>
                        <Input 
                            type="select" 
                            name="addLawyer" 
                            id="addLawyer" 
                            ref="addLawyer" 
                            value={this.state.editedLawyer}
                            onChange={(e) => this.setState({editedLawyer:e.currentTarget.value})}

                        >
                            { _.map(this.props.userList, (e) => {
                                    return (<option 
                                        key={'-'+e.auth_id}
                                        value={e.auth_id}>
                                            {e.firstname + ' ' + e.lastname }
                                    </option>);
                                })
                            }
                        </Input>
                    </FormGroup>
                    <FormGroup>
                        <Label for="leaveReason">Motif de l'absence</Label>
                        <Input 
                            type="select" 
                            name="leaveReason" 
                            id="leaveReason" 
                            ref="leaveReason" 
                            value={this.state.leaveReason}
                            onChange={(e) => this.setState({leaveReason:e.currentTarget.value})}
                        >
                            { _.map(leaveReason, (e) => {
                                    return (<option 
                                        key={'_-'+e.value}
                                        value={e.value}>
                                            {e.label}
                                    </option>);
                                })
                            }
                        </Input>
                    </FormGroup>
                    <FormGroup>
                        <Label for="leaveExplaination">Motif de l'absence</Label>
                        <Input 
                            type="textarea" 
                            name="leaveExplaination" 
                            id="leaveExplaination" 
                            ref="leaveExplaination" 
                            value={this.state.leaveExplaination}
                            onChange={(e) => this.setState({leaveExplaination:e.currentTarget.value})}
                        >
                        </Input>
                    </FormGroup>
                </ModalBody>
                <ModalFooter>
                    {/* <Button color="warning" onClick={() => this.emptyCalendar()}> Retirer</Button>{' '} */}
                    <Button color="success" onClick={() => this.setVacations()}>Accorder le congé</Button>{' '} 
                    <Button color="secondary" onClick={() => this.props.dispatch({type:rootsActions.usersActions.TOGGLE})}>Annuler</Button>
                </ModalFooter>
            </Modal>
        </>
        );
    }
}


const mapStateToProps = state => ({
    toggle:state.users.toggle,
    userList:state.users.userList,
    admin:state.users.result.admin,
    userId:state.users.result.userId
});
  
// const mapActionsToProps = {
//     toggleModal:rootsActions.clientActions.toggleModal
// }

export default connect(
    mapStateToProps,
    null
    // mapActionsToProps
)(LeaveModal);