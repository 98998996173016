
import moment  from 'moment-timezone';
const DATE_FORMAT = "YYYY-MM-DDTHH:mm:ss+00:00";
const DEFAULT_TZ = "America/Toronto";

export default class History{
    constructor({
        action,
        start,
        user,
        type,
        timezone = DEFAULT_TZ
    }){
        this.action = action;
        this.start = start;
        this.user = parseInt(user);
        this.type = type;

        this.end = moment(start)
            .add(1,'h').utc()
            .format(DATE_FORMAT);

        this.timezone = timezone;
    }

    static val(){ return this;} 
}