import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment  from 'moment-timezone';
import 'moment/locale/fr-ca';

import _ from 'lodash';
import {
    UncontrolledTooltip
} from 'reactstrap';

const DATE_FORMAT = "YYYY-MM-DDTHH:mm:ss+00:00";
const LAWYERS = 1;
// const DISPO = 'dispo';
const SIGN = 2;
const B2B = 3;
// const SALES = 2;
const SIGN_REGEXP = /\$sign\$/
const B2B_REGEXP = /\$b2b\$/

class Day extends Component{
    constructor(props){
        super(props);

        this.day = this.day.bind(this);
    }

    initials(obj){

        if(obj && obj.auth_id === 0){
            return 'Tous et toutes'
        }

        if(!obj||!obj.firstname||!obj.lastname)
            return 'Undefined'

        if(obj.sign)
            return ('🖋️'+obj.firstname[0] +'.'+obj.lastname[0].replace(/\s/g,'')).toUpperCase();

        if(obj.b2b)
            return ('💼'+obj.firstname[0] +'.'+obj.lastname[0].replace(/\s/g,'')).toUpperCase();
                
        return (obj.firstname[0] +'.'+obj.lastname[0].replace(/\s/g,'')).toUpperCase()
    }
    getSign(obj){
        if(obj.sign)
            return '🖋️';
        else if(obj.b2b)
            return '💼'
        else
            return '';
    }
    day(day,start,end,weekDayId,type){
        var divs=[];
        
        var toPush = [];
        let isVisible = !!this.props.visible.find(e => e.value === 0);
        
        if(!!this.props.isDST&& day.length){
            day = _.each(day, (e) => {
                return _.each(e.dispo, (f) => {
                    f.start = moment(f.start).subtract(1,'h').utc().format(DATE_FORMAT);
                    f.end =  moment(f.end).subtract(1,'h').utc().format(DATE_FORMAT);
                    return f;
                })
            });
            
        }
        
        for(;start <end; start++){
            for(let d in day){
                if(day[d]&&day[d].dispo&&day[d].dispo.length){
                    for(let e in day[d].dispo){
                        // si le meme type que le type courant ou si vide et que cest le type lawyers
                        if(day[d].dispo[e].type === this.props.calendarType || (!day[d].dispo[e].type&&this.props.calendarType === LAWYERS)){
                            // debugger;
                            // console.log(day[d].dispo[e].start,'--->',moment.utc(day[d].dispo[e].start).local().hour())
                            if((moment(day[d].dispo[e].start).utc().local().hour()) <= start && start < (moment(day[d].dispo[e].end).utc().local().hour())){
                                toPush.push(day[d].user+'');
                            }
                        }
                        else if(day[d].dispo[e].type === SIGN){
                            if((moment(day[d].dispo[e].start).utc().local().hour()) <= start && start < (moment(day[d].dispo[e].end).utc().local().hour())){
                                toPush.push(day[d].user+'$sign$');
                            }
                        }
                        else if(day[d].dispo[e].type === B2B){
                            if((moment(day[d].dispo[e].start).utc().local().hour()) <= start && start < (moment(day[d].dispo[e].end).utc().local().hour())){
                                toPush.push(day[d].user+'$b2b$');
                            }
                        }
                    }
                }
            }

            let arr = [],currentLawyer;
            if(toPush.length){
                for(let t in toPush){
                    /* eslint-disable */
                    if(isVisible||this.props.visible.find(e => parseInt(e.value) === parseInt(toPush[t]))){
                        
                        if(toPush[t].toString().match(SIGN_REGEXP)){
                            let id = toPush[t].split(SIGN_REGEXP);

                            currentLawyer = {..._.find(this.props.userList, {
                                'auth_id':id[0]
                            })};
                            currentLawyer.sign = true;
                            
                            toPush[t] = id[0];
                        }
                        else if(toPush[t].toString().match(B2B_REGEXP)){
                            let id = toPush[t].split(B2B_REGEXP);

                            currentLawyer = {..._.find(this.props.userList, {
                                'auth_id':id[0]
                            })};
                            currentLawyer.b2b = true;
                            
                            toPush[t] = id[0];
                        }
                        else{
                            currentLawyer = _.find(this.props.userList, {
                                'auth_id':toPush[t].toString()
                            });
                        }
                    /* eslint-enable */ 
                    if(!currentLawyer){
                        continue;
                    }

                        arr.push(
                            <div 
                                key={weekDayId+'_'+start+'_'+toPush[t]+t}
                                onClick={(e) => this.props.deletePeriod(e)}
                                className={"calendarItem"}
                                style={{backgroundColor:(currentLawyer)?currentLawyer.color:'#441188'}}
                                id={currentLawyer.firstname + currentLawyer.lastname.replace(/\s/g,'')+ '_' + weekDayId+'_'+start+'_'+toPush[t] }
                            >
                                {
                                    (currentLawyer)?this.initials(currentLawyer):'?.?'
                                }
                                <UncontrolledTooltip
                                    placement={'top'}
                                    target={currentLawyer.firstname + currentLawyer.lastname.replace(/\s/g,'')+ '_' +weekDayId+'_'+start+'_'+toPush[t]}
                                >{(currentLawyer)?this.getSign(currentLawyer)+currentLawyer.firstname + ' ' + currentLawyer.lastname:'Mystère...'}</UncontrolledTooltip>
                            </div>
                        )
                    }
                }
                
                if(arr.length&&this.props.dispo)
                    arr.push(<div key={weekDayId+'-'+start+'a'} className="calendarItem btn-neo-blue add-button" id={weekDayId+'-'+start} onClick={(e) => {this.props.setPeriod(e,this.props.addPeriod)}}> + </div>);

                divs.push(<div key={weekDayId+'-'+start+'d'} className="d-flex flex-fill" >{arr}</div>);
            }
            
            if(!arr.length){
                divs.push(
                    <div
                        key={weekDayId+'-'+start} 
                        className="d-flex flex-fill" 
                        style={{flexBasis:0}}>
                        <div 
                            onClick={(e) => {
                                // console.log('cybele',this.props)
                                if(!this.props.dispo){
                                    return false;
                                }

                                this.props.setPeriod(e,this.props.addPeriod)}
                            }
                            id={weekDayId+'-'+start}
                            className="calendarItem">+
                        </div>
                    </div>)
            }
                
            toPush = [];
        }

        return(
            
            <td style={{width:'calc(100% / 7)'}}>
                {divs}
            </td>
        )
    }

    render(){
        return this.day(
            this.props.day,
            this.props.start,
            this.props.end,
            this.props.weekDayId,
            this.props.type
        );
    }
}

const mapStateToProps = state => ({
    toggle:state.users.toggle,
    // userList:state.users.lawyers,
    weekList:state.time.weekList,
    weekId:state.time.currentWeek,
    year:state.time.currentYear,
    weekMonday:state.time.currentMonday,
    isDST:state.time.isDST,
    dispo:state.users.result.dispo,
    admin:state.users.result.admin,
});

export default connect(mapStateToProps)(Day);